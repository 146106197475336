var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{class:[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'be-loading',
					{ 'be-loading-active': _vm.isBusy } ]},[_c('div',{staticClass:"card-heade p-2"},[_c('div',{staticClass:"btn-group float-left"},[_c('router-link',{staticClass:"btn btn-space btn-danger btn-rounded",attrs:{"to":"/customers"}},[_c('span',{staticClass:"icon icon-left mdi mdi-arrow-left text-white"}),_vm._v(" Back ")])],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"customer-name"},[_c('h1',[_c('b',[_vm._v(_vm._s(_vm.customer.displayName))])]),_c('p',[_vm._v("Customers Name")])])])]),_c('div',{class:[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'be-loading',
					{ 'be-loading-active': _vm.isBusy } ]},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Email Address")]),_c('h4',[_vm._v(_vm._s(_vm.customer.emailAddress))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Delivery Address")]),_c('h4',[_vm._v(_vm._s(_vm.customer.deliveryAddress))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Phone Number")]),_c('h4',[_vm._v(_vm._s(_vm.customer.phoneNumber))])])]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("State")]),_c('h4',[_vm._v(_vm._s(_vm.customer.state ? _vm.customer.state : "N/A"))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Local Government Area")]),_c('h4',[_vm._v(_vm._s(_vm.customer.lga ? _vm.customer.lga : "N/A"))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("ID")]),_c('h4',[_vm._v(_vm._s(_vm.customer.id))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Created")]),_c('h4',[_vm._v(_vm._s(_vm.formatDate(_vm.customer.created)))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Last Updated")]),_c('h4',[_vm._v(_vm._s(_vm.formatDate(_vm.customer.updated)))])])])]),_c('div',{staticClass:"be-spinner"},[_c('svg',{attrs:{"width":"40px","height":"40px","viewBox":"0 0 66 66"}},[_c('circle',{staticClass:"circle",attrs:{"fill":"none","stroke-width":"4","stroke-linecap":"round","cx":"33","cy":"33","r":"30"}})])])]),_c('div',{class:[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'be-loading',
					{ 'be-loading-active': _vm.isBusy } ]},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Discounts")]),_c('h4',[_vm._v(" "+_vm._s(_vm.customer.discount ? ((_vm.customer.discount.name) + " - " + (_vm.customer.discount.type != "Percent" ? "₦" : "") + " " + (_vm.formatMoney(_vm.customer.discount.value)) + (_vm.customer.discount.type == "Percent" ? "%" : "")) : "Not Available")+" ")])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Branch")]),_c('h4',[_vm._v(_vm._s(_vm.customer.branch ? _vm.customer.branch.name : "N/A"))])]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("Group")]),_c('h4',[_vm._v(" "+_vm._s(_vm.customer.groups.length ? _vm.customer.groups.map(function (e) { return e.name; }).join(", ") : "Not Available")+" ")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-heade p-2"},[_c('div',{staticClass:"btn-group float-left"},[_c('h2',[_vm._v(" Customer Details ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-heade p-2"},[_c('div',{staticClass:"btn-group float-left"},[_c('h2',[_vm._v(" Additional Information ")])])])}]

export { render, staticRenderFns }