<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="tabs-section">
				<div class="tab">
					<button class="tablinks" @click.prevent="openTab($event, 'All')" id="defaultOpen">All Orders</button>
					<button class="tablinks" @click.prevent="openTab($event, 'Cancelled')">Cancellation Requests</button>
				</div>

				<div id="All" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
							<button class="btn btn-secondary btn-rounded btn-space" @click="viewFilter">
								<span class="icon icon-left mdi mdi-tune text-primary"></span> Filters
							</button>
						</div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<div class="row be-datatable-body">
									<div class="col-sm-12 table-responsive">
										<table
											class="table table-fw-widget dataTable no-footer text-nowrap"
										>
											<thead>
												<tr>
													<th style="width:10%;">Order Date</th>
													<th style="width:10%;">Order Number</th>
													<th style="width:10%;">Channel</th>
													<th style="width:10%;">Distributor</th>
													<th style="width:5%;">Total Amount</th>
													<th style="width:10%;">Payment Status</th>
													<th style="width:5%;">Order Status</th>
													<th style="width:10%;"></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="o in orders" :key="o.id">
													<td>
														{{
															o.orderDate
																? formatDate(o.orderDate)
																: formatDate(o.created)
														}}
													</td>
													<td>
														<a href="#" @click.prevent="details(o.id)">{{
															o.orderNumber
														}}</a>
													</td>
													<td>{{ o.source }}</td>
													<td>{{ o.seller.name }}</td>
													<td>&#8358; {{ formatMoney(o.total) }}</td>
													<td>
														{{ o.paymentStatus }}
													</td>
													<td>
														<a
															title="Cancel Order"
															v-if="o.status == 'Pending'"
															href="#"
															@click.prevent="viewDeleteOrder(o)"
														>
															<span
																:class="[
																	'badge',
																	'badge-pill',
																	{
																		'badge-primary':
																			o.status == 'Pending',
																	},
																	{
																		'badge-success':
																			o.status == 'Fulfilled',
																	},
																	{
																		'badge-danger':
																			o.status == 'Canceled',
																	},
																]"
																>{{ o.status }}</span
															>
														</a>
														<span
															v-else
															:class="[
																'badge',
																'badge-pill',
																{ 'badge-primary': o.status == 'Pending' },
																{
																	'badge-success':
																		o.status == 'Fulfilled',
																},
																{ 'badge-danger': o.status == 'Canceled' },
															]"
															>{{ o.status }}</span
														>
													</td>

													<td>
														<button
															class="btn btn-outline-primary btn-sm dropdown-toggle"
															type="button"
															id="dropdownMenu2"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															Actions
														</button>
														<div
															class="dropdown-menu"
															aria-labelledby="dropdownMenu2"
														>
															<a
																class="dropdown-item"
																href="#"
																@click.prevent="details(o.id)"
															>
																View Details
															</a>

															<div
																v-if="
																		(o.status == 'Pending' ||
																			o.status == 'recallDelivery' ||
																			o.status == 'Confirm' || o.status == 'Fulfilled')
																"
																class="dropdown-divider"
															></div>
															<a
																v-if="
																	o.paymentStatus != 'PAID' &&
																			(o.status == 'Pending' ||
																				o.status == 'recallDelivery' ||
																				o.status == 'Confirm' || o.status == 'Fulfilled')
																"
																class="dropdown-item"
																@click.prevent="togglePaymentModal(o)"
																href="#"
															>
																Pay Total Amount
																<!-- <button
																	:disabled="isBusyFull"
																	class="btn btn-success btn-xl btn-block"
																	@click.prevent="payTotalAmount(o)"
																>
																	<span v-if="isBusyFull">Busy...</span>
																	<span v-else>Pay Total Amount </span>
																</button> -->
															</a>

															<div
																v-if="o.paymentStatus !== 'PAID' && o.paymentStatus !== 'PARTLY PAID' && o.status !== 'Cancelling' && o.status !== 'Cancelled' && o.status !== 'Fulfilled' && o.cancellationStatus !== 'rejected'"
																class="dropdown-divider"
															></div>
															<a
																v-if="o.paymentStatus !== 'PAID' && o.paymentStatus !== 'PARTLY PAID' && o.status !== 'Cancelling' && o.status !== 'Cancelled' && o.status !== 'Fulfilled' && o.cancellationStatus !== 'rejected'"
																class="dropdown-item"
																href="#"
																@click.prevent="viewCancelOrder(o)"
																style="color: red"
															>
																Send Cancel Request
															</a>
														</div>
													</td>
												</tr>
												<tr v-if="!orders.length">
													<td colspan="10">
														<div class="text-center" style="padding-top:50px;">
															<span
																style="font-size: 4.615rem;"
																class="mdi mdi-basket"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom:100px;"
														>
															You have not made any orders.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="pageSize"
											:count="pageCount"
											:page="page"
											:itemsCount="count"
											@changePage="gotoPage"
											@changeLimit="changeLimit"
										/>
									</div>
								</div>
								<div class="row be-datatable-footer">
									<div class="col-sm-5">
										<div class="dataTables_info">
											{{ count }} order{{ count != 1 ? "s" : "" }}
										</div>
									</div>
									<div class="col-sm-7">
										<div class="dataTables_paginate paging_simple_numbers"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div id="Cancelled" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
						</div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<p>Click on "Order Number" to view full Order details</p>
								<div class="row be-datatable-body">
									<div class="col-sm-12 table-responsive" style="min-height: 350px;">
										<table
											class="table table-fw-widget dataTable no-footer text-nowrap"
										>
											<thead>
												<tr>
													<th>Order Number</th>
													<th>Amount</th>
													<th>Cancelletion Status</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="o in cancelledOrders" :key="o.id">
													<td>
														<a href="#" @click.prevent="details(o.id)">{{
																	o.orderNumber
														}}</a>
													</td>
													<td>&#8358; {{ formatMoney(o.total) }}</td>

													<td>
														<span
															:class="[
																'badge',
																'badge-pill',
																{
																	'badge-success':
																		o.cancellationStatus == 'accepted',
																},
																{
																	'badge-danger':
																		o.cancellationStatus == 'rejected',
																},
															]"
															>{{ o.cancellationStatus == "request" ? "pending" : o.cancellationStatus }}</span>
													</td>
													<td>
														<button
															v-if="o.cancellationStatus == 'rejected' || o.cancellationStatus == 'request'"
															class="btn btn-primary btn-sm"
															@click.prevent="viewCancellationReason(o.cancellationReason, o.distributorCancellationReason)"
														>
															View Reason
														</button>
													</td>
												</tr>
												<tr v-if="!cancelledOrders.length">
													<td colspan="10">
														<div class="text-center" style="padding-top: 50px">
															<span
																style="font-size: 4.615rem"
																class="mdi mdi-basket"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom: 100px"
														>
															You have not recieved any orders.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="cancelPageSize"
											:count="cancelPageCount"
											:page="cancelPage"
											:itemsCount="cancelCount"
											@changePage="cancelGotoPage"
											@changeLimit="cancelChangeLimit"
										/>
										<div class="row be-datatable-footer pt-3">
											<div class="col-sm-5">
												<div class="dataTables_info">
													{{ cancelCount }} order{{ cancelCount > 1 ? "s" : "" }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select2 placeholder="Select an option" v-model="filter.status">
									<option value="0">All Orders</option>
									<option value="Pending">Pending</option>
									<option value="Fulfilled">Fulfilled</option>
									<option value="Cancelled">Cancelled</option>
								</select2>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateStart"
								/>
								<!-- <date-picker
                                  v-model="filter.dateStart"
                                  :config="options"
                                  placeholder="Select a date"
                                ></date-picker>-->
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateEnd"
								/>
								<!-- <date-picker v-model="filter.dateEnd" :config="options" placeholder="Select a date"></date-picker> -->
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Seller Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by seller name..."
									v-model="filter.seller"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Order Number</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by order number..."
									v-model="filter.orderNumber"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px;"
							class="btn btn-primary md-close"
							type="button"
							@click="getOrders"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-delete" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div class="text-center">
							<!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
							<h3>Confirmation</h3>
							<p>
								Are you sure you want to cancel this order:
								<strong>{{ deletingOrder.orderNumber }}</strong
								>?
							</p>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							:disabled="isBusyModal"
							class="btn btn-danger"
							type="button"
							@click.prevent="deleteOrder"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Yes, proceed</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-reason-view" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Cancellation Reason</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="p-1">
							<h5>Customer:</h5>
							<p>
								{{cancellationReason}}
							</p>
						</div>
						<div v-if="distributorCancellationReason" class="mt-2 p-1">
							<h5>Response:</h5>
							<p>
								{{distributorCancellationReason}}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-cancel" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div>
							<!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
							<h3 class="text-bold">Cancel Order</h3>
							<div class="row mt-3">
								<div class="col col-md-4">
									<p><label for="cancelR">Reason for cancellation:</label></p>
								</div>
								<div class="col col-md-8">
  									<textarea id="cancelR" class="form-control" name="reason" placeholder="Enter Reason" rows="4" cols="50" v-model="cancelReason"></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="cancelRequestOrder"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Done</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<part-payment-modal
			:isPosting="isBusyModal"
			@makePayment="makePayment"
			@closeModal="closePartPaymentModal"
		></part-payment-modal>
		<payment-status-modal
			:isPosting="isBusyModal"
			:status="orderPaymentStatus"
			:statusMsg="orderPaymentStatusMsg"
			@retryPayment="retryPayment"
		></payment-status-modal>
		<!-- confirm payment Modal -->
		<confirm-payment-modal
			:modalName="modalName"
			:order="orderDetails"
			:transactionFee="txFee"
			:totalFee="totalFee"
			@proceed="payTotalAmount(orderDetails)"
			:isBusy="isBusy"
			@cancel="cancelConfirmModal"
		></confirm-payment-modal>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	// import DatePicker from "vue-bootstrap-datetimepicker";
	import Pagination from "../components/Pagination.vue";
	import PartPaymentModal from "@/components/Order/PartPaymentModal";
	import PaymentStatusModal from "@/components/Order/PaymentStatusModal";
	import ConfirmPaymentModal from "@/components/Order/ConfirmPaymentModal";

	export default {
		name: "ordersSent",
		components: {
			Select2,
			PartPaymentModal,
			PaymentStatusModal,
			ConfirmPaymentModal,
			Pagination,
			// DatePicker
		},
		created() {
			this.getOrders();
		},
		data() {
			return {
				filter: {
					status: "0",
					orderNumber: "",
					seller: "",
					dateStart: moment()
						// .subtract(6, "days")
						.format("1999-01-01"),
					dateEnd: moment().format("YYYY-MM-DD"),
				},
				isBusy: false,
				isBusyFull: false,
				isBusyModal: false,
				orders: [],
				cancelledOrders: [],
				count: 0,
				page: 1,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				cancelCount: 0,
				cancelPage: 1,
				cancelPageSize: 10,
				cancelPageCount: 0,
				cancelHasNext: false,
				cancelHasPrev: false,
				cancelStatus: "",
				options: {
					format: "YYYY-MM-DD",
					icons: {
						time: "mdi mdi-time",
						date: "mdi mdi-calendar",
						up: "mdi mdi-chevron-up",
						down: "mdi mdi-chevron-down",
						previous: "mdi mdi-chevron-left",
						next: "mdi mdi-chevron-right",
						today: "mdi mdi-screenshot",
						clear: "mdi mdi-trash",
						close: "mdi mdi-remove",
					},
				},
				local: {
					dow: 0, // Sunday is the first day of the week
					hourTip: "Select Hour", // tip of select hour
					minuteTip: "Select Minute", // tip of select minute
					secondTip: "Select Second", // tip of select second
					yearSuffix: "", // suffix of head year
					monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
						"_",
					), // months of head
					months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
					weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
					cancelTip: "cancel",
					submitTip: "confirm",
				},
				deletingOrder: {},
				retryOrderPayload: {},
				orderPaymentStatus: null,
				orderPaymentStatusMsg: "",
				maxDate: "",
				orderDetails: {},
				modalName: "",
				cancelOrderId: "",
				cancelReason: "",
				cancellingOrder: null,
				cancellationReason: "",
				distributorCancellationReason: "",
				txFee: 0,
				totalFee:0,
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
			this.openDefaultTab();
			this.getCancelledOrders();
		},
		methods: {
			cancelConfirmModal() {
				$("#mod-confirm-payment").modal("hide");
				this.modalName = "";
				this.orderDetails = {};
			},
			async togglePaymentModal(val) {
				this.modalName = "confirm order payment";
				this.orderDetails = val;
				let balance =  val.total - val.totalAmountPaid;
				let payload ={
					distributorId: val.sellerId,
					amount: balance
				}
				await this.$http
					.put("/Payments/calculatemerchantfee", payload)
						.then(function(res) {
						this.txFee = res.body.fee;
						this.totalFee = res.body.totalAmount;
						this.isBusy = false;
						$("#mod-confirm-payment").modal("show");
					})
					.catch(function() {});
			},
			reset() {
				this.filter = {
					status: "0",
					orderNumber: "",
					seller: "",
					dateStart: moment()
						// .subtract(6, "days")
						.format("1999-01-01"),
					dateEnd: moment().format("YYYY-MM-DD"),
				};
			},
			viewExport() {
				//   $("#mod-export").modal("show");
			},
			viewFilter() {
				$("#mod-filter").modal("show");
			},

			viewCancellationReason(reason, distributorReason) {
				this.cancellationReason = reason;
				this.distributorCancellationReason = distributorReason;

				$("#mod-reason-view").modal("show");
			},

			details(id) {
				this.$router.push("/merchant/orders/" + id);
			},
			async getOrders() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Orders/getList?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.orderNumber != ""
								? "&orderNumber=" + this.filter.orderNumber
								: "") +
							(this.filter.seller != "" ? "&buyer=" + this.filter.seller : "") +
							(this.filter.status != "0" ? "&status=" + this.filter.status : "") +
							(this.filter.dateStart != ""
								? "&dateStart=" +
								  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.dateEnd != ""
								? "&dateEnd=" +
								  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:5")
								: "") +
							"&makerId=" +
							this.user.id,
					)
					.then(function(res) {
						this.orders = res.body.data;
						this.page = res.body.pageNumber;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async getCancelledOrders() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Orders/getMerchantCancelList?page=" +
							this.cancelPage +
							"&pageSize=" +
							this.cancelPageSize +
							"&merchantId=" +
							this.user.id +
							"&cancelStatus=" +
							this.cancelStatus,
					)
					.then(function(res) {
						this.cancelledOrders = res.body.data;
						this.cancelPage = res.body.page;
						this.cancelPageSize = res.body.pageSize;
						this.cancelCount = res.body.count;
						this.cancelHasPrev = res.body.hasPrev;
						this.cancelHasNext = res.body.hasNext;
						this.cancelPageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.isBusy = false;
						this.fetchDate = Date.now();
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			formatDate(date) {
				return moment(date).format("ll");
			},
			prev() {
				this.page -= 1;
				this.getOrders();
			},
			next() {
				this.page += 1;
				this.getOrders();
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			goToPayment(id) {
				this.$router.push("/payments/" + id);
			},
			viewDeleteOrder(order) {
				this.isBusyModal = false;
				this.deletingOrder = order;
				$("#mod-delete").modal("show");
			},

			async deleteOrder() {
				this.isBusyModal = true;
				let data = {
					orderId: this.deletingOrder.id,
					orderNumber: this.deletingOrder.orderNumber,
					wasFulfilled: false,
					notes: "Canceled by customer.",
					dateFulfilled: moment(),
				};

				// await this.$http
				//   .post("/Orders/fulfillment", data)
				//   .then(function(res) {
				//     this.isBusyModal = false;
				//     this.getOrders();
				//   })
				//   .catch(function() {
				//     this.isBusy = false;
				//     this.isBusyModal = false;
				//   });

				$("#mod-delete").modal("hide");
			},

			viewCancelOrder(order) {
				this.isBusyModal = false;
				this.cancellingOrder = order;
				$("#mod-cancel").modal("show");
			},

			async cancelRequestOrder() {
				this.isBusyModal = true;
				let data = {
					 orderId : this.cancellingOrder.id,
					 cancellationStatus : "request",
					 cancellationReason : this.cancelReason
					}

				await this.$http
				  .post("/Orders/updateCancellationStatus", data)
				  .then(function(res) {
				    this.isBusyModal = false;
				    this.getOrders();
					this.cancelReason = "";
				  })
				  .catch(function() {
				    this.isBusy = false;
				    this.isBusyModal = false;
				  });

				$("#mod-cancel").modal("hide");
			},
			gotoPage(page) {
				this.page = page;
				this.getOrders();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getOrders();
			},

			cancelGotoPage(page) {
				this.cancelPage = page;
				this.getCancelledOrders();
			},

			cancelChangeLimit(limit) {
				this.cancelPageSize = limit;
				this.getCancelledOrders();
			},

			//function added to make payment
			closePartPaymentModal() {
				$("#mod-part-payment").modal("hide");
			},

			showPaymentStatusModal() {
				$("#mod-payment-status").modal("show");
			},

			closePaymentStatusModal() {
				$("#mod-payment-status").modal("hide");
			},

			async payTotalAmount(order) {
				// console.log(order);
				// if (order.details.length < 1) {
				//   this.$toast.error("Please select at least one product", {
				//     icon: false,
				//     rtl: false,
				//   });
				//   return this.closePartPaymentModal();
				// }

				this.makePayment(order.total, false);
			},

			async makePayment(amt, isPartPayment) {
				this.isBusy = true;
				if (isPartPayment) {
					if(amt > (this.orderDetails.total - this.orderDetails.totalAmountPaid)){
						return this.$toast.warning("You cannot pay more than outstanding balance", {
								icon: true,
								rtl: false,
							});
					}
					this.isBusyModal = true;
				} else {
					this.isBusyFull = true;
				}

				let outstandingBalance = this.orderDetails.total - this.orderDetails.totalAmountPaid;

				const payload = {
					orderId: this.orderDetails.id,
					amount: isPartPayment ? +amt : +outstandingBalance,
				};
				this.retryOrderPayload = payload;
				await this.$http
					.post("/Payments/makePayment", payload)
					.then(function(res) {
						if (res.ok) {
							this.isBusyModal = false;
							this.isBusyFull = false;
							this.closePartPaymentModal();
							this.isBusy = false;
							$("#mod-confirm-payment").modal("hide");
							// this.$toast.success(res.body.message, {
							// 	icon: true,
							// 	rtl: false,
							// });
							if (
								res.body &&
								res.body.status != null &&
								res.body.status === "Successful"
							) {
								this.orderPaymentStatus = "success";
								this.orderPaymentStatusMsg = res.body.message;
								this.retryOrderPayload = {};
								this.isBusy = false;
								$("#mod-confirm-payment").modal("hide");
								this.showPaymentStatusModal();

								// this.$toast.success(res.body.message, {
								// 	icon: true,
								// 	rtl: false,
								// });
							} else if (
								res.body &&
								res.body.status != null &&
								(res.body.status === "Failed" || res.body.status === "failed")
							) {
								this.orderPaymentStatus = "fail";
								this.orderPaymentStatusMsg = res.body.message;
								this.isBusy = false;
								$("#mod-confirm-payment").modal("hide");
								this.showPaymentStatusModal();
								// this.$toast.success(this.orderPaymentStatusMsg, {
								// 	icon: true,
								// 	rtl: false,
								// });
							} else if (
								res.body &&
								res.body.status != null &&
								res.body.status === "WaitingForOtp"
							) {
								this.orderPaymentStatus = "warning";
								this.orderPaymentStatusMsg = res.body.message
									? res.body.message
									: "Waiting for otp";
								this.isBusy = false;
								$("#mod-confirm-payment").modal("hide");
								this.showPaymentStatusModal();
								// this.$toast.success(this.orderPaymentStatusMsg, {
								// 	icon: true,
								// 	rtl: false,
								// });
							} else if (
								res.body &&
								res.body.status != null &&
								res.body.status === "Insufficient"
							) {
								this.orderPaymentStatus = "warning";
								this.orderPaymentStatusMsg = res.body.message
									? res.body.message
									: "Insufficient Balance";
								this.isBusy = false;
								$("#mod-confirm-payment").modal("hide");
								this.showPaymentStatusModal();
								// this.$toast.success(this.orderPaymentStatusMsg, {
								// 	icon: true,
								// 	rtl: false,
								// });
							}else {
								this.orderPaymentStatus = "fail";
								this.orderPaymentStatusMsg = "something went wrong";
								this.isBusy = false;
								$("#mod-confirm-payment").modal("hide");
								this.showPaymentStatusModal();
								// this.$toast.success(this.orderPaymentStatusMsg, {
								// 	icon: true,
								// 	rtl: false,
								// });
							}
							
						}
					})
					.catch(function() {
						this.isBusy = false;
						this.$toast.error(err, {
							icon: true,
							rtl: false,
						});
						// 	this.isBusyModal = false;
						// 	this.isBusyFull = false;
						// 	this.orderPaymentStatus = "fail";
						// this.closePartPaymentModal();
						// this.showPaymentStatusModal();
					});

				this.isBusyModal = false;
				this.isBusyFull = false;
			},

			async retryPayment() {
				this.isBusyModal = true;
				await this.$http
					.post("/Payments/makePayment", this.retryOrderPayload)
					.then(function(res) {
						if (res.ok) {
							this.closePaymentStatusModal();
							this.isBusyModal = false;
							this.isBusyFull = false;

							if (
								res.body &&
								res.body.status != null &&
								res.body.status === "Successful"
							) {
								this.orderPaymentStatus = "success";
								this.orderPaymentStatusMsg = res.body.message;
								this.retryOrderPayload = {};
								setTimeout(() => this.showPaymentStatusModal(), 1000);
							} else if (
								res.body &&
								res.body.status != null &&
								(res.body.status === "Failed" || res.body.status === "failed")
							) {
								this.orderPaymentStatus = "fail";
								this.orderPaymentStatusMsg = res.body.message;
								setTimeout(() => this.showPaymentStatusModal(), 1000);
							} else if (
								res.body &&
								res.body.status != null &&
								res.body.status === "WaitingForOtp"
							) {
								this.orderPaymentStatus = "warning";
								this.orderPaymentStatusMsg = res.body.message
									? res.body.message
									: "Waiting for otp";
								setTimeout(() => this.showPaymentStatusModal(), 1000);
							} else if (
								res.body &&
								res.body.status != null &&
								res.body.status === "Insufficient"
							) {
								this.orderPaymentStatus = "warning";
								this.orderPaymentStatusMsg = res.body.message
									? res.body.message
									: "Insufficient Balance";
								setTimeout(() => this.showPaymentStatusModal(), 1000);
							} else {
								this.orderPaymentStatus = "fail";
								this.orderPaymentStatusMsg = "something went wrong.";
								setTimeout(() => this.showPaymentStatusModal(), 1000);
							}
							
						}
					})
					.catch(function() {
						this.closePaymentStatusModal();
						this.isBusyModal = false;
						this.orderPaymentStatus = "fail";
						setTimeout(() => this.showPaymentStatusModal(), 1000);
					});

				this.isBusyModal = false;
			},
			openDefaultTab(){
        		document.getElementById("defaultOpen").click();
			},
			openTab(evt, cityName) {
				var i, tabcontent, tablinks;
				tabcontent = document.getElementsByClassName("tabcontent");
				for (i = 0; i < tabcontent.length; i++) {
					tabcontent[i].style.display = "none";
				}
				tablinks = document.getElementsByClassName("tablinks");
				for (i = 0; i < tablinks.length; i++) {
					tablinks[i].className = tablinks[i].className.replace(" active", "");
				}
				document.getElementById(cityName).style.display = "block";
				evt.currentTarget.className += " active";
			}
		},
	};
</script>

<style scoped>
	/* Style the tab */
	.tab {
	overflow: hidden;
	display: flex;
	/* border: 1px solid #ccc; */
	/* background-color: #fff; */
	padding: 15px;
	}

	/* Style the buttons inside the tab */
	.tab button {
	width: 50%;
	background-color: inherit;
	text-align: center;
	float: left;
	border: none;
	border-bottom: 2px solid #ccc;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
	font-size: 17px;
	}

	/* Change background color of buttons on hover */
	.tab button:hover {
	/* background-color: #766cea; */
	color: #766cea !important;
	}

	/* Create an active/current tablink class */
	.tab button.active {
	border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}

	/* Style the tab content */
	.tabcontent {
	display: none;
	padding: 6px 12px;
	/* border: 1px solid #ccc; */
	border-top: none;
	}
</style>

