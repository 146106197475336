<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 15%">Name</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 10%">Deactivate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in users" :key="u.id">
                      <td>
                        <b>
                          <span v-if="u.displayName">
                            {{ u.displayName }}
                            <br />
                          </span>
                        </b>
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-primary': u.status == 'Pending' },
                            { 'badge-success': u.status == 'Active' },
                            { 'badge-danger': u.status == 'Disabled' },
                          ]"
                          >{{ u.status == "Pending" ? "Invitation sent" : u.status }}</span
                        >
                      </td>
                      <td>
                        <SwitchStatus
                          v-if="user.name !== 'Dispatched'"
                          :stage="user"
                          @onComplete="onComplete"
                        />
                        <!-- <button
                          class="btn btn-primary"
                          @click="toggleStatus(status.active)"
                        >
                          click
                        </button> -->
                      </td>
                    </tr>
                    <tr v-if="!users.length">
                      <td colspan="8">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} user{{ count != 1 ? "s" : "" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RenameStageModal @onRenameComplete="onRenameCompleteHandler" :stage="orderStage" />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
// import { helper } from "@/helper";
import SwitchStatus from "../components/SettingsPurchaseOrder/PaymentSetting.vue";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsTeam",
  components: {
    SwitchStatus,
    Pagination,
  },

  created() {
    this.getUsers();
  },

  data() {
    return {
      filter: {
        status: "0",
        role: "0",
        qry: "",
      },
      users: [],
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      v8: {
        selectedOption: false,
      },

      selectedOption: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function() {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        role: "0",
        qry: "",
      };
    },

    viewFilter() {
      this.page = 0;
      $("#mod-filter").modal("show");
    },

    async getUsers() {
      await this.$http
        .get(
          "/Users/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.role != "0" ? "&role=" + this.filter.role : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.users = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getUsers();
    },

    next() {
      this.page += 1;
      this.getUsers();
    },
    onComplete() {
      this.getOrderStages();
    },
    onRenameCompleteHandler() {
      this.orderStage = {};
      this.getOrderStages();
      // eslint-disable-next-line no-undef
      $("#mod-rename-stage").modal("hide");
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getUsers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getUsers();
    },
  },
};
</script>
