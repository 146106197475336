<template>
  <div class="row">
    <div class="col-lg-12">

      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
				<div class="card-header">
          <div>
            <button class="btn btn-success btn-rounded btn-space text-white" @click="openFilter">
              <i class="feather icon-filter text-white"></i> Filter
            </button>
          </div>
           <div>
            <button class="btn btn-rounded btn-space text-white" @click.prevent="fetchMessages" style="background-color: #b6c978">
              <i class="feather icon-pie-chart text-white"></i> Update Message
            </button>
          </div>

          <div>
            <button v-if="isSelectAll" class="btn btn-warning btn-rounded btn-space" @click="deSelectAll">
						  Unselect All
					  </button>
            <button v-else class="btn btn-rounded btn-space" style="background-color: #134d84; color: white" @click="selectAll">
						  Select All
					  </button>
          	<button id="markAsRead" @click="markAsRead" :disabled="selectedIds < 1" class="btn btn-primary btn-rounded btn-space ml-2">
						  Mark As Read
					</button>
          </div>
        </div>

        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer table-striped table-hover-animation">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive" style="min-height: 350px;">
                <table
                  id="myTable" class="table table-fw-widget dataTable no-footer text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>Message Title</th>
                      <th>Sender</th>
                      <th>Date &#38; Time Recieved</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in altMessages" :key="o.id">
                      <td>
                          <input type="checkbox" :checked="o.isChecked" :value="o.id" @change="selectMessage(o.id)"/>
                      </td>
                      <td @click.prevent="openBroadcastPreview(o)" style="cursor: pointer">
                        {{o.title}}
                      </td>
                       <td @click.prevent="openBroadcastPreview(o)" style="cursor: pointer">
                        {{o.sender}}
                      </td>
                       <td @click.prevent="openBroadcastPreview(o)" style="cursor: pointer">
                        {{formatDate(o.date)}}
                      </td>
                       <td @click.prevent="openBroadcastPreview(o)" style="cursor: pointer">
                         	<span
														:class="[
															'badge',
															'badge-pill',
															{
																'badge-success':
																	o.isRead == true,
															},
															{
																'badge-warning':
																	o.isRead == false,
															},
														]"
													>
														{{
															o.isRead ? "Read" : "Unread"
														}}
													</span>
                      </td>
                    </tr>
                    <tr v-if="!Messages.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-basket"
                          ></span>
                        </div>
                        <h3
                          class="text-center"
                          style="padding-bottom: 100px"
                        >
                          You have not recieved any message.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Pagination
                      :limit="pageSize"
                      :count="pageCount"
                      :page="page"
                      :itemsCount="count"
                      @changePage="gotoPage"
                      @changeLimit="changeLimit"
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                  <div>
                      <h4 class="modal-title"><b>Filter</b></h4>
                  </div>
                  <button
                      class="close md-close"
                      type="button"
                      data-dismiss="modal"
                      aria-hidden="true"
                  >
                      <span class="mdi mdi-close"></span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-12 col-sm-4">Sender: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                      :class="['form-control']"
                      type="text"
                      v-model="filter.sender"
                    />
                  </div>
                </div>
                  <div class="form-group row">
                  <label class="col-12 col-sm-4">Read Status: </label>
                  <div class="col-12 col-sm-8 ">
                       <select
                        class="form-control"
                        placeholder="Select an option"
                        v-model="filter.status"
                      >
                        <option value="true">Read</option>
                        <option value="false">Unread</option>
                      </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-sm-4">Start Date: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.startDate"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-sm-4">End Date: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.endDate"
                    />
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  style="width: 100px;"
                  class="btn btn-text btn-space"
                  type="button"
                  data-dismiss="modal"
                  @click="resetFilter"
                >
                  <span class="text-danger"> Reset </span>
                </button>

                <button
                  style="width: 100px;"
                  class="btn btn-primary"
                  type="button"
                  data-dismiss="modal"
                  :disabled="isBusy"
                  @click="fetchMessages"
                >
                  <span v-if="!isBusy">Filter</span>
                  <span v-else>Busy...</span>
                </button>

              </div>
            </div>
          </div>
      </div>

      <BroadcastPreview @closeModal="closeBroadcastPreview" :details="previewDetails"></BroadcastPreview>

    </div>
  </div>
</template>


<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import Pagination from "../../components/Pagination.vue";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";

export default {
  name: "Broadcast",
  components: {
    Pagination,
    BroadcastPreview
  },

  mounted: function() {
      this.fetchMessages();
  },



  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      Messages: [],
      altMessages: [],
      isSelectAll: false,
      selectedIds: [],

      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },
      // myTable: null,
     filter: {
        sender: "",
        status: "",
        startDate: "",
        endDate: ""
      },
    }
  },

  computed: {
      user: function() {
        return this.$store.state.currentUser;
      },
  },

  methods: {
    openDefaultTab(){
      document.getElementById("defaultOpen").click();
    },
    openTab(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },

    openBroadcastPreview(m){
        this.previewDetails = {
            title: m.title,
            message: m.message,
            sender: m.sender,
            date: m.date ? moment(m.date).format("dddd, MMM DD, hh:mma"): moment(new Date()).format("dddd, MMM DD, hh:mma"),
            imagePosition: m.imagePosition,
            infoUrl: m.link,
            imageAttach: m.imageUrl,
        }
        $("#mod-read-broadcast").modal("show");
        this.$http
          .put(`/BroadCastMessages/markAsRead/${m.id}`)
          .then(function (res) {
            this.fetchMessages();
            this.isBusy = false;
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    closeBroadcastPreview(){
        $("#mod-read-broadcast").modal("hide");
         this.previewDetails = {
            title: "",
            sender: "",
            date: "",
            message: "",
            imagePosition: "",
            infoUrl: "",
            imageAttach: "",
         };
    },

    formatDate(date) {
        return moment(date).format('MMM d, YYYY - H:mma')
    },

    openFilter(){
      $("#mod-filter").modal("show");
    },

    closeFilter(){
      $("#mod-filter").modal("hide");
    },

    resetFilter(){
      this.filter = {
        sender: "",
        startDate: "",
        status: "",
        endDate: ""
      }
      this.fetchMessages();
    },

    selectMessage(id){
      if(this.selectedIds.includes(id)){
       let filterSelected = this.selectedIds.filter(item => item != id);
       this.selectedIds = filterSelected;
      }else{
        this.selectedIds.push(id);
      }
    },

    async markAsRead(){
      this.isBusy = true;
      if(this.selectedIds.length < 1){
        return this.$toast.warning("You must select message.", {
              icon: true,
              rtl: false,
            });
      }
      await this.$http
          .put(`/BroadCastMessages/markAsRead?Ids=${this.selectedIds}`)
          .then(function (res) {
            this.altMessages = [];
            this.isSelectAll = false;
            this.fetchMessages();
            
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    fetchMessages(){
      this.altMessages = [];
      this.selectedIds = [];
      this.closeFilter();
      this.isBusy = true;
      this.$http
          .get(`/BroadCastMessages/recipientmessages?PageSize=${this.pageSize}&PageNumber=${this.page}&Sender=${this.filter.sender}&StartDate=${this.filter.startDate}&EndDate=${this.filter.endDate}&IsRead=${this.filter.status}`)
          .then(function (res) {
            this.Messages = res.body.data;
            this.formatMessageArray(res.body.data);
            this.page = res.body.pageNumber;
            this.pageSize = res.body.pageSize;
            this.count = res.body.count;
            this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
            this.hasPrev = res.body.hasPrev;
            this.hasNext = res.body.hasNext;
            this.isBusy = false;

            // this.$toast.success("Distributor created successfully.", {
            //   icon: true,
            //   rtl: false,
            // });
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

  formatMessageArray(arr){
    for(var i = 0; i < arr.length; i++){
      let obj = {
        isChecked: false,
        id: arr[i].id,
        title: arr[i].title,
        sender: arr[i].sender,
        date: arr[i].date,
        message: arr[i].message,
        broadCastTime: arr[i].broadCastTime,
        link: arr[i].link,
        imageUrl: arr[i].imageUrl,
        imagePosition: arr[i].imagePosition,
        isRead: arr[i].isRead,
      }
      this.altMessages.push(obj);
    }
  },

  selectAll(){
    for(var i = 0; i < this.altMessages.length; i++){
      this.altMessages[i].isChecked = true;
      this.selectedIds.push(this.altMessages[i].id);
    }
    this.isSelectAll = true;
  },

  deSelectAll(){
    for(var i = 0; i < this.altMessages.length; i++){
      this.altMessages[i].isChecked = false;
    }
    this.isSelectAll = false;
    this.selectedIds = [];
  },

    prev() {
      this.page -= 1;
      this.fetchMessages();
    },
    next() {
      this.page += 1;
      this.fetchMessages();
    },

    gotoPage(page) {
      this.page = page;
      this.fetchMessages();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.fetchMessages();
    },

  }
};
</script>


<style scoped>
  /* Style the tab */
	.tab {
	overflow: hidden;
	display: flex;
	/* border: 1px solid #ccc; */
	/* background-color: #fff; */
	padding: 15px;
	}

	/* Style the buttons inside the tab */
	.tab button {
	width: 50%;
	background-color: inherit;
	text-align: center;
	float: left;
	border: none;
	border-bottom: 2px solid #ccc;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
	font-size: 17px;
	}

	/* Change background color of buttons on hover */
	.tab button:hover {
	/* background-color: #766cea; */
	color: #766cea !important;
	}

	/* Create an active/current tablink class */
	.tab button.active {
	border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}

	/* Style the tab content */
	.tabcontent {
	display: none;
	padding: 6px 12px;
	/* border: 1px solid #ccc; */
	border-top: none;
	}
</style>
