<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-purchase-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Filter</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >PO Number</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="text"
                placeholder="Filter by PO Number"
                v-model="orderNumber"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Status</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                class="form-control"
                v-model="status"
                placeholder="Select an option"
              >
                <option
                  v-for="option in statusItem"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
          <button style="width: 100px" class="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterPurchase",

  data() {
    return {
      orderNumber: "",
      status: "",
      statusItem: [
        { name: "Submitted", value: "submitted" },
        { name: "Recalled", value: "recalled" },
        { name: "Confirmed", value: "confirmed" },
        { name: "Withdrawn", value: "withdrawn" },
        { name: "Draft", value: "draft" },
      ],
    };
  },

  created() {
    this.getOrderStages();
  },

  methods: {
     reset() {
      this.orderNumber = "";
      this.status = "";
    },
    submit() {
      this.$emit("onFilter", {
        orderNumber: this.orderNumber,
        status: this.status,
      });
    },
    async getOrderStages() {
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList?active=true"
        );

        if (response.ok && response.status === 200) {
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );
        }
      } catch (error) {}
    },
  },
};
</script>
