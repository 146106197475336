<template>
	<main>
		<!-- TOP BAR -->
		<section class="row" id="Top-bar">
			<div class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<div class="btn-group">
							<router-link
								class="btn btn-space btn-danger btn-rounded float-right mb-2"
								:to="'/orders/issue-stock'"
							>
								<span class="icon icon-left mdi mdi-arrow-left text-white"></span>
								Back
							</router-link>
						</div>
						<h4>
							<b>Issue Number: {{ issueStockDetails.issueNumber }}</b>
						</h4>
					</div>
				</div>
			</div>
		</section>
		<!-- ISSUES DESCRIPTION -->
		<section id="Issues description">
			<div class="card-body">
				<div class="form-group row">
					<div class="col-lg-4">
						<label class="col-form-label font-weight-bold">Issued To</label>
						<span class="col-form-label text-sm-left">
							{{ issueStockDetails.issueToName }}
						</span>
					</div>
					<div class="col-lg-4">
						<label class="col-form-label text-sm-left font-weight-bold"
							>Issued By:</label
						>
						<span class="col-form-label text-sm-left">
							{{ issueStockDetails.issuedByName }}
						</span>
					</div>
					<div class="col-lg-4">
						<label class="col-form-label text-sm-left font-weight-bold"
							>Value of Stock:</label
						>
						<span class="col-form-label text-sm-left">
							&#8358; {{ formatMoney(issueStockDetails.totalValue) }}
						</span>
					</div>
				</div>

				<div class="form-group row">
					<div class="col-lg-4">
						<label class="col-form-label text-sm-left font-weight-bold"
							>Entry Date:</label
						>
						<span class="col-form-label text-sm-left">
							{{ formatDate(issueStockDetails.entryDate) }}
						</span>
					</div>
					<div class="col-lg-4">
						<label class="col-form-label text-sm-left font-weight-bold">Status:</label>
						<span class="col-lg-3 col-form-label text-sm-left">
							{{ StockIssuedStatus }}
						</span>
					</div>
				</div>
			</div>
			<div class="be-spinner">
				<svg width="40px" height="40px" viewBox="0 0 66 66">
					<circle
						class="circle"
						fill="none"
						stroke-width="4"
						stroke-linecap="round"
						cx="33"
						cy="33"
						r="30"
					></circle>
				</svg>
			</div>
		</section>
		<!-- ISSUES TABLE -->
		<section class="row" id="issues-table ">
			<div class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header p-1" id="printing">
						<h4><b>Issue Details</b></h4>
					</div>
					<div class="col-sm-12 table-responsive" id="printing">
						<table class="table table-fw-widget dataTable no-footer">
							<thead>
								<tr>
									<th>Description</th>
									<th>Price Per Quantity</th>
									<th>Issued Quantity</th>
									<th>Value</th>
									<th>Location</th>
									<th>Status</th>
									<th>Stock Left</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in issueStockDetails.details" :key="index">
									<td>{{ item.description }}</td>
									<td>&#8358; {{ formatMoney(item.price) }}</td>
									<td>{{ item.quantity }}</td>
									<td>&#8358; {{ formatMoney(item.quantity * item.price) }}</td>
									<td>{{ item.location }}</td>
									<td>
										<span v-if="item.status == 0" class="text-warning">Pending</span>
										<span class="text-success" v-else-if="item.status == 1">Completed</span>
										<span class="text-danger" v-else>Reversed</span>
									</td>
									<td>{{ item.stockLeft }}</td>
									<!-- 
													v-if="hasPermission('Stock_Issue_Record_Returns')"
											" -->
									<td>
										<button
											v-if="issueStockDetails.issueToRole == 'Staff'"
											:disabled="item.status == 1"
											class="
                              btn btn-success btn-sm
                              dropdown-toggle
                            "
											type="button"
											id="dropdownMenu2"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Actions
										</button>
										<div
											class="dropdown-menu px-1"
											aria-labelledby="dropdownMenu2"
										>
											<div v-if="hasPermission('Stock_Issue_Record_Sales')">
												<a
													class="dropdown-item"
													href="#"
													@click.prevent="addSalesAction(item.id)"
												>
													&nbsp;Sales
												</a>
											</div>
											<div v-if="hasPermission('Stock_Issue_Record_Returns')">
												<a
													class="dropdown-item"
													href="#"
													@click.prevent="
														addReturnAction(item.id, item.productId)
													"
												>
													&nbsp;Returns
												</a>
											</div>
										</div>
									</td>
								</tr>
								<tr v-if="issueStockDetails.details.length < 1">
									<td colspan="7">
										<div class="text-center" style="padding-top: 50px">
											<span
												style="font-size: 4.615rem"
												class="mdi mdi-tag-multiple"
											></span>
										</div>
										<h3 class="text-center" style="padding-bottom: 100px">
											You don't have any issued stock.
										</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="be-spinner">
						<svg width="40px" height="40px" viewBox="0 0 66 66">
							<circle
								class="circle"
								fill="none"
								stroke-width="4"
								stroke-linecap="round"
								cx="33"
								cy="33"
								r="30"
							></circle>
						</svg>
					</div>
				</div>
			</div>
		</section>
		<!-- STOCK-REPORT TABLE -->
		<section class="row" id="stock-report-table ">
			<div class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header p-1" id="printing">
						<h4><b>Stock Report</b></h4>
					</div>
					<div class="col-sm-12 table-responsive" id="printing">
						<table class="table table-fw-widget dataTable no-footer">
							<thead>
								<tr>
									<th>Description</th>
									<th>Price Per Quantity</th>
									<th>Quantity</th>
									<th>Action</th>
									<th>Customer</th>
									<th>Order Number</th>
									<th>Location</th>
									<th>Entry Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in issueStockDetails.reports" :key="index">
									<td>{{ item.description }}</td>
									<td>&#8358; {{ formatMoney(item.price) }}</td>
									<td>{{ item.quantity }}</td>
									<td>{{ item.action }}</td>
									<td>{{ item.customer == null ? "N/A" : item.customer }}</td>
									<td>
										{{ item.orderNumber == null ? "N/A" : item.orderNumber }}
									</td>
									<td>{{ item.location == null ? "N/A" : item.location }}</td>
									<td>{{ formatDate(item.entryDate) }}</td>
								</tr>
								<tr v-if="issueStockDetails.reports.length < 1">
									<td colspan="7">
										<div class="text-center" style="padding-top: 50px">
											<span
												style="font-size: 4.615rem"
												class="mdi mdi-tag-multiple"
											></span>
										</div>
										<h3 class="text-center" style="padding-bottom: 100px">
											You don't have any issued stock.
										</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="be-spinner">
						<svg width="40px" height="40px" viewBox="0 0 66 66">
							<circle
								class="circle"
								fill="none"
								stroke-width="4"
								stroke-linecap="round"
								cx="33"
								cy="33"
								r="30"
							></circle>
						</svg>
					</div>
				</div>
			</div>
		</section>
		<!-- SALES AND RETURNS MODAL -->
		<section
			class="modal fade colored-header colored-header-primary"
			id="modal-add-Returns-Sales-FormToggle"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>{{
									!addReturnsSalesFormToggle
										? " Add Sales Actions"
										: "Add Return Action"
								}}</strong>
							</h4>
						</div>
						<button
							class="close md-close"
							type="button"
							@click.prevent="clearFormFields"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<!-- SALES ACTION -->
						<section v-if="!addReturnsSalesFormToggle">
							<div class="form-group d-flex align-items-center my-1">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Select Customer</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<select2
										placeholder="Select"
										v-model="issueStockDetailsFileds.customerID"
									>
										<option
											v-for="cO in userOptions"
											:key="cO.id"
											:value="cO.id"
										>
											{{ cO.value }}
										</option>
									</select2>
									<ul v-if="v1.customerID" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Quantity Sold</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<input
										class="form-control"
										type="text"
										placeholder="Enter quantity"
										v-model.number="issueStockDetailsFileds.qtySold"
									/>
									<ul v-if="v1.qtySold" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Sales Date</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<input
										:class="['form-control', { 'is-invalid': v1.salesdate }]"
										type="date"
										:max="maxDate"
										placeholder="Select a date"
										v-model="issueStockDetailsFileds.salesdate"
									/>
									<ul v-if="v1.salesdate" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Record Payment</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<label
										class="custom-control custom-checkbox custom-control-inline mb-0"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="issueStockDetailsFileds.recordPayment"
										/>
										<span class="custom-control-label">Record Payment</span>
									</label>
									<ul v-if="v1.recordPayment" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div v-if="issueStockDetailsFileds.recordPayment">
								<div class="form-group d-flex align-items-center my-1">
									<label class="col-12 col-sm-5 col-form-label text-sm-right"
										>Select Payment Channnel</label
									>
									<div class="col-10 col-sm-7 col-lg-7">
										<select2
											placeholder="Select a Payment Channnel"
											v-model="issueStockDetailsFileds.paymentChannel"
										>
											<option
												v-for="ch in paymentChannelList"
												:key="ch.id"
												:value="ch.id"
											>
												{{ prepareDetail(ch) }}
											</option>
										</select2>
										<ul
											v-if="v1.paymentChannel"
											class="parsley-errors-list filled"
										>
											<li class="parsley-required">Required.</li>
										</ul>
									</div>
								</div>
								<div class="form-group d-flex align-items-center my-2">
									<label class="col-12 col-sm-5 col-form-label text-sm-right"
										>Amount</label
									>
									<div class="col-10 col-sm-7 col-lg-7">
										<input
											class="form-control"
											type="text"
											placeholder="Enter quantity"
											v-model.number="issueStockDetailsFileds.amount"
										/>
										<ul v-if="v1.amount" class="parsley-errors-list filled">
											<li class="parsley-required">Required.</li>
										</ul>
									</div>
								</div>
								<div class="form-group d-flex align-items-center my-2">
									<label class="col-12 col-sm-5 col-form-label text-sm-right"
										>Select Payment Date</label
									>
									<div class="col-10 col-sm-7 col-lg-7">
										<input
											:class="[
												'form-control',
												{ 'is-invalid': v1.paymentDate },
											]"
											type="date"
											:max="maxDate"
											placeholder="Select a date"
											v-model="issueStockDetailsFileds.paymentDate"
										/>
										<ul
											v-if="v1.paymentDate"
											class="parsley-errors-list filled"
										>
											<li class="parsley-required">Required.</li>
										</ul>
									</div>
								</div>
							</div>
						</section>
						<!-- RETURNS ACTION -->
						<section v-else>
							<div class="form-group d-flex align-items-center my-1">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Select Warehouse</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<select2
										id="warehouse-input"
										placeholder="Select"
										v-model="issueStockDetailsFileds.wareHouse"
										@input="getSpecificSubLocation($event)"
									>
										<option selected disabled value="">Select</option>
										<option
											v-for="warehouse in warehouseList"
											:key="warehouse.id"
											:value="warehouse.id"
										>
											{{ warehouse.value == null ? "N/A" : warehouse.value }}
										</option>
									</select2>
									<ul v-if="v1.wareHouse" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div class="form-group d-flex align-items-center my-1">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Select Storage</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<select2
										id="storageArea"
										placeholder="Select"
										v-model="issueStockDetailsFileds.storageArea"
										@input="getInventory($event)"
									>
										<option selected disabled value="">Select</option>
										<option
											v-for="storageArea in storageAreaList"
											:key="storageArea.id"
											:value="storageArea.id"
										>
											{{
												storageArea.name == null ? "N/A" : storageArea.name
											}}
										</option>
									</select2>
									<ul v-if="v1.storageArea" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<!-- <div class="form-group d-flex align-items-center my-1">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Inventory ID</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<select2
										id="storageArea"
										placeholder="Select"
										v-model="issueStockDetailsFileds.inventoryID"
									>
										<option selected disabled value="">Select</option>
										<option
											v-for="inventory in inventoryList"
											:key="inventory.id"
											:value="inventory.id"
										>
											{{ inventory.value == null ? "N/A" : inventory.value }}
										</option>
									</select2>
									<ul v-if="v1.inventory" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div> -->
							<div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Return Date</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<input
										:class="['form-control', { 'is-invalid': v1.returnDate }]"
										type="date"
										:max="maxDate"
										placeholder="Select a date"
										v-model="issueStockDetailsFileds.returnDate"
									/>
									<ul v-if="v1.returnDate" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
							<div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Quantity to return</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<input
										class="form-control"
										type="text"
										placeholder="Enter quantity to return"
										v-model.number="issueStockDetailsFileds.returnQty"
									/>
									<ul v-if="v1.returnQty" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div>
						</section>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-outline-seconndary btn-no-border text-danger px-1"
							type="button"
							data-dismiss="modal"
							@click.prevent="clearFormFields"
						>
							Cancel
						</button>
						<button
							v-if="!addReturnsSalesFormToggle"
							style="width: 160px"
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="recordSales"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Add</span>
						</button>
						<button
							v-else
							style="width: 160px"
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="recordReturns"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Proceed</span>
						</button>
					</div>
				</div>
			</div>
		</section>
		<!-- Connfirmation Modal -->
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-action-res"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered sm-modal">
				<div class="modal-content p-1">
					<div class="modal-body text-center">
						<p class="text-center">Are you sure you want to <b>{{action}}</b> this transaction?</p>
						<div class="d-flex justify-content-between">
							<button
								class="btn btn-outline-seconndary btn-no-border text-danger px-1"
								type="button"
								@click.prevent="closeDeleteReverseModal"
							>
								No, I don't
							</button>
							<button
								:disabled="isBusyModal"
								class="btn btn-outline-dark btn-no-border px-1"
								type="button"
								@click.prevent="issueStockAction(itemID)"
							>
								<span v-if="isBusy">Busy...</span>
								<span v-else>Yes, I do</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	/* eslint-disable */
	import Select2 from "@/components/Select2";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import moment from "moment";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "IssueStockDetails",
		components: {
			Select2,
			GeneralDeleteCaution,
			Pagination,
		},

		data() {
			return {
				modalName: "",
				isBusy: false,
				issueStockDetails: [],
				userOptions: [],
				warehouseList: [],
				storageAreaList: [],
				inventoryList: [],
				subLocation: [],
				paymentChannelList: [],
				addReturnsSalesFormToggle: false,
				stockIssueDetailId: "",
				action: "",
				iemID:"",
				issueStockDetailsFileds: {
					customerID: "",
					qtySold: "",
					salesdate: "",
					recordPayment: false,
					inventoryID: "",
					wareHouse: "",
					storageArea: "",
					returnDate: "",
					returnQty: "",
					amount: 0,
					paymentDate: "",
					paymentChannel: "",
				},
				v1: {
					customerID: false,
					qtySold: false,
					salesdate: false,
					recordPayment: false,
					inventoryID: false,
					wareHouse: false,
					storageArea: false,
					returnDate: false,
					returnQty: false,
					amount: false,
					paymentDate: false,
					paymentChannel: false,
				},
				StockIssuedStatus: "",
				StockIssuedDetailStatus: "",
				StockIssuedReportStatus: "",
				maxDate: "",
				detailsProductId: "",
			};
		},

		created() {
			this.getIssueStock();
			this.getUserData();
			this.getLocations();
			this.getSubLocations();
			this.getPaymentChannel();
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},

			backPath: function() {
				return this.$store.state.prevRoute;
			},
		},

		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},

		methods: {
			cancelConfirmModal() {
				$("#mod-confirm-payment").modal("hide");
				this.modalName = "";
			},

			getSpecificSubLocation(val) {
				this.storageAreaList = this.subLocation.filter(x => x.location.id == val);
			},

			clearFormFields() {
				this.issueStockDetailsFileds.customerID = "";
				this.issueStockDetailsFileds.qtySold = "";
				this.issueStockDetailsFileds.salesdate = "";
				(this.issueStockDetailsFileds.recordPayment = false),
					(this.issueStockDetailsFileds.inventoryID = "");
				this.issueStockDetailsFileds.wareHouse = "";
				this.issueStockDetailsFileds.storageArea = "";
				this.issueStockDetailsFileds.returnDate = "";
				this.issueStockDetailsFileds.returnQty = "";
				(this.issueStockDetailsFileds.amount = 0),
					(this.issueStockDetailsFileds.paymentDate = "");
				this.issueStockDetailsFileds.paymentChannel = "";
			},
			prepareDetail(value) {
				const name = value.paymentChannelTypeName;
				let result = "";
				if (name) {
					result += name;

					if (name.toLowerCase() === "bank") {
						result += "/ " + value.bankName + "/ " + value.accountNumber;
					} else if (name.toLowerCase() === "pos") {
						result += "/ " + value.bankName + "/ " + value.terminalId;
					} else if (name.toLowerCase() === "cryptocurrency") {
						result += "/ " + value.bankName + "/ " + value.walletAddress;
					}
				}
				return result;
			},

			getIssueStock() {
				this.isBusy = true;
				var id = this.$route.params.id;
				this.$http
					.get(`/StockIssues/${id}`)
					.then(function(res) {
						this.issueStockDetails = res.body;
						if (this.issueStockDetails.status == 0) {
							this.StockIssuedStatus = "Issued";
						} else if (this.issueStockDetails.status == 1) {
							this.StockIssuedStatus = "Reversed";
						} else {
							this.StockIssuedStatus = "Deleted";
						}
						this.isBusy = false;
					})
					.catch(function() {
						this.isBusy = false;
					});
			},

			addSalesAction(id) {
				this.stockIssueDetailId = id;
				this.addReturnsSalesFormToggle = false;
				$("#modal-add-Returns-Sales-FormToggle").modal("show");
			},
			addReturnAction(id, productID) {
				this.detailsProductId = productID;
				this.stockIssueDetailId = id;
				this.addReturnsSalesFormToggle = true;
				$("#modal-add-Returns-Sales-FormToggle").modal("show");
			},

			deleteReverseModal(id, action)
			{
					this.action = action
					$("#mod-action-res").modal("show");
					this.itemID = id
			},
			closeDeleteReverseModal(){
				$("#mod-action-res").modal("hide");
				this.itemID = ''
				this.action = ""
			},

			async recordSales() {
				this.isBusy = true;
				var id = this.$route.params.id;
				let data = {
					customerId: this.issueStockDetailsFileds.customerID,
					stockIssueDetailId: this.stockIssueDetailId,
					quantitySold: this.issueStockDetailsFileds.qtySold,
					dateSold: this.issueStockDetailsFileds.salesdate,
				};
				await this.$http
					.post(`/StockIssues/${id}/RecordSale`, data)
					.then(function(res) {
						if (res.ok) {
							if (this.issueStockDetailsFileds.recordPayment) {
								this.recordPayment(res.body);
							} else {
								this.isBusy = false;
								this.getIssueStock();
								$("#modal-add-Returns-Sales-FormToggle").modal("hide");
								this.clearFormFields();
							}
						}
					})
					.catch(function() {
						this.isBusy = false;
					});
			},
			async recordPayment(val) {
				var id = this.$route.params.id;
				let data = {
					orderId: val,
					paymentDate: this.issueStockDetailsFileds.paymentDate,
					amountPaid: this.issueStockDetailsFileds.amount,
					paymentChannelId: this.issueStockDetailsFileds.paymentChannel,
				};
				await this.$http
					.post(`/StockIssues/${id}/RecordPayment`, data)
					.then(function(res) {
						if (res.ok) {
							this.issueStockDetailsFileds.recordPayment = false;
							$("#modal-add-Returns-Sales-FormToggle").modal("hide");
							this.isBusy = false;
							this.getIssueStock();
							this.clearFormFields();
						}
					})
					.catch(function() {
						this.isBusy = false;
					});
			},
			async recordReturns() {
				this.isBusy = true;
				var id = this.$route.params.id;
				let data = {
					stockIssueDetailId: this.stockIssueDetailId,
					subLocationId: this.issueStockDetailsFileds.storageArea,
					returnDate: this.issueStockDetailsFileds.returnDate,
					quantityReturned: this.issueStockDetailsFileds.returnQty,
				};
				await this.$http
					.post(`/StockIssues/${id}/RecordReturn`, data)
					.then(function(res) {
						if (res.ok) {
							this.isBusy = false;
							this.getIssueStock();
							this.$toast.success(res.body, {
							icon: false,
							rtl: false,
						});
							$("#modal-add-Returns-Sales-FormToggle").modal("hide");
							this.clearFormFields();
						}
					})
					.catch(function() {
						this.isBusy = false;
					});
			},

			async getUserData() {
				this.isBusy = true;
				await this.$http
					.get(`/Users/listNoPagging?isCustomer`)
					.then(function(res) {
						if (res.ok) {
							this.userOptions = res.body.map(user => ({
								id: user.id,
								value: user.name == null ? "N/A" : user.name,
							}));
							this.isBusy = false;
						}
					})
					.catch(function() {
						this.isBusy = false;
					});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},
			async getPaymentChannel() {
				try {
					const response = await this.$http.get(
						"/PaymentChannels/getListNoPaging?active=true" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					);

					if (response.ok) this.paymentChannelList = response.body;
				} catch (error) {}
			},
			getInventory(val) {
				if (val != "") {
					this.isBusy = true;
					this.$http
						.get(
							`/Inventories/getProductInventories?ProductId=${this.detailsProductId}&subLocationId=${val}`,
						)
						.then(function(res) {
							this.inventoryList = res.body.map(inventory => ({
								id: inventory.inventoryId,
								value: inventory.inventoryName,
							}));
							this.isBusy = false;
							// this.displayForm.product = true;
						})
						.catch(function() {
							this.isBusy = false;
						});
				} else {
				}
			},
			getLocations() {
				var t = "Location";
				var a = true;

				this.$http
					.get(
						"/Locations/getListNoPaging?active=" +
							a +
							"&parentId=" +
							this.user.parentId,
					)
					.then(function(res) {
						this.warehouseList = res.body.map(location => ({
							id: location.id,
							value: location.name == null ? "N/A" : location.name,
						}));
					})
					.catch(function() {});
			},

			getSubLocations() {
				var t = "Location";
				var a = true;

				this.$http
					.get(
						"/SubLocations/getListNoPaging?active=" +
							a +
							"&parentId=" +
							this.user.parentId +
							"&locationId=",
					)
					.then(function(res) {
						this.subLocation = res.body;
					})
					.catch(function() {});
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			formatDate(date) {
				return moment(date).format("ll");
			},
		},
	};
</script>
<style scoped></style>
