<template>
  <a
  :class="'dropdown-item'" style="font-size: 14px;"
    :disabled="withdrawLoading"
    @click.prevent="onWithDrawClick"
  >
    Cancel 
  </a>
</template>

<script>
export default {
  name: "SwitchPurchaseOrder",
  props: ["purchaseOrder","userType"],

  data() {
    return {
      withdrawLoading: false,
    };
  },

  methods: {
    onWithDrawClick() {
      this.$emit("showAction", {
        purchaseNumber: this.purchaseOrder.orderNumber,
        action: "withdraw",
        owner: this.purchaseOrder.id,
      });
    },

    async withdrawPurchase() {
      this.withdrawLoading = true;
      this.isBusy = true;

      const status = "withdrawn";
      try {
        if(this.userType === "salesAgent"){
            const response = await this.$http.put(
              `/PurchaseOrders/salesAgentUpdateStatus/${this.purchaseOrder.id}/${status}`
            );

            if (response.ok && response.status === 200) this.$emit("onComplete");
        }else{
          const response = await this.$http.put(
          `/PurchaseOrders/updateStatus/${this.purchaseOrder.id}/${status}`
          );

          if (response.ok && response.status === 200) this.$emit("onComplete");
        }
       this.withdrawLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.withdrawLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>

<style>
</style>