<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">&nbsp;</div>
              <div class="row match-height">

                          <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-bar-chart-2"></span>   Orders Report</h4>
                                  
                                        <ul class="list-group notification">
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span><router-link to="/daily-sales-by-order">Orders Details </router-link> </li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span><router-link to="/active_orders_customer">Active Orders (Customer)</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/active_orders_orderstatus">Active Orders (Order Status)</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/volume-by-product-category">Volume By Category</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/volume-by-customer">Volume By Customer</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/volume-by-product">Volume By Product</router-link></li>
                                            
                                        </ul>
                                    
                                </div>
                            </div>
                        </div>

                          <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-package"></span> Inventory Reports</h4>
                                
                                        <ul class="list-group notification">
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/stock_purchase_all"> Stock Purchase (All) </router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/stock_purchase_consolidated">Stock Purchase (Consolidated)</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/stock-transfer_rpt">Stock Transfer Report</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/inventory-by-product-category">Inventory By Category</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/inventory-by-product">Inventory By Product</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/inventory-by-batch">Inventory By Product Batch </router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/inventory-by-warehouse">Inventory By Warehouse </router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/reconciliation-by-status">Reconciliation (Product)</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/reconciliation-by-order-number">Reconciliation (Order Number)</router-link></li>
                                            
                                           
                                              
                                        </ul>
                                 
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-12">
                            <!-- <div class="card"> -->
                                <div class="card-body">
                                    <h4> <span class="feather icon-shopping-cart"></span> Sales Reports</h4>
                                
                                        <ul class="list-group notification">
                                             <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/active-credit-sales-report">Active Credit Sales</router-link></li> -->
                                             <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/active_credit_sales_branch">Active Credit Sales (Branch) </router-link></li> -->
                                             <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/active_credit_sales_introducer"> Active Credit Sales (Sales Agent) </router-link></li> -->
                                             <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/daily-sales-by-order-status">Sales By Order Status </router-link></li>
                                             <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/monthly_sales_introducer">Monthly Sales (Sales Agent)</router-link></li>
                                             <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/sales-margins"> Sales Margins</router-link></li> 
                                             <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/stock-issued-sales"> Stock Issued Sales Report</router-link></li> 
                                             <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/total-credit-sales-ytd"> Total Credit Sales - YTD</router-link></li> -->
                                            
                                        </ul>
                                  
                                </div>
                            <!-- </div> -->
                        </div>

                          <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4>     <i class="fa fa-money"></i> Payment Reports</h4>

                                        <ul class="list-group notification">
                                            
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/active_cash_collections_branch">Active Cash Collections (Branch) </router-link></li>
                                            <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/active_cash_collections_introducer"> Active Cash Collections (Sales Agent) </router-link></li> -->
                                            <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/monthly_collections_introducer">Monthly Collections (Sales Agent)</router-link></li> -->
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/total-payment-collection-ytd">Total Payment Collection - YTD</router-link></li>
                                           
                                        </ul>
                                  
                                </div>
                            </div>
                        </div>

                         <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <i class="fa fa-money">&nbsp;</i>Store Credit Reports</h4>
                                        <ul class="list-group notification">
                                            <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/active-cash-credit-branch">Active Cash Credit (Branch)</router-link></li> -->
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/active-cash-credit-customer">Active Cash Credit (Customer)</router-link></li>
                                            <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/30_day_credit_outlets">Total 30 Day Credit (Customer)</router-link></li>
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span>  <router-link to="/60_day_credit_outlets">Total 60 Day Credit (Customer)</router-link></li>
                                             <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/90_day_credit_outlets">Total 90 Day Credit (Customer)</router-link></li>
                                           -->
                                        </ul>
                                  
                                </div>
                            </div>
                        </div>

                       
                     


                         <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4>     <i class="feather icon-percent"></i> Metrics Reports</h4>

                                        <ul class="list-group notification">
                                           <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/total-cash-credit-ratio">Total Cash Credit Ratio </router-link></li> -->
                                            
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/product_performance_by_percentage">Product Performance By Percentage</router-link></li>
                                            
                                        </ul>
                                  
                                </div>
                            </div>
                        </div>

                          <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4>     <i class="feather icon-user"></i> Customer Reports</h4>

                                        <ul class="list-group notification">
                                            <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/my-customers-report">My Customers</router-link></li>
                                          <!-- <li class="list-group-item"><span class="feather icon-star" style="color:lightgray">&nbsp;&nbsp;</span> <router-link to="/delisted-customers-ytd-report">Delisted Customers YTD</router-link></li> -->
                                        </ul>
                                  
                                </div>
                            </div>
                        </div>
                          

                          
                      
                    </div>
         
         
          <h3 class="text-center" style="padding-bottom: 100px"></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reports",
};
</script>