<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<span class="feather icon-filter text-white"></span> Filter
					</button>
					<button
						class="btn btn-primary btn-rounded btn-space float-right"
						@click="viewAdd"
						v-if="hasPermission('Payment_Channels_Create')"
					>
						<span class="icon icon-left mdi mdi-plus text-white"></span> New Payment
						Channel
					</button>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="table table-fw-widget dataTable no-footer text-nowrap"
								>
									<thead>
										<tr>
											<th style="width: 15%">Channel</th>
											<th style="width: 20%">Bank/Provider Name</th>
											<th style="width: 25%">AccountNumber/ID</th>
											<th style="width: 10%">Status</th>
											<th style="width: 20%">Created</th>
											<th style="width: 10%"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="l in PaymentChannels" :key="l.id">
											<td>
												{{ l.paymentChannelTypeName }}
											</td>
											<td>
												{{
													(l.paymentChannelTypeName &&
														l.paymentChannelTypeName.toLowerCase() ===
															"bank") ||
													(l.paymentChannelTypeName &&
														l.paymentChannelTypeName.toLowerCase() ===
															"pos") ||
													(l.paymentChannelTypeName &&
														l.paymentChannelTypeName.toLowerCase() ===
															"cryptocurrency")
														? l.bankName
														: "NA"
												}}
											</td>
											<td>
												{{
													l.paymentChannelTypeName &&
													l.paymentChannelTypeName.toLowerCase() ===
														"bank"
														? l.accountNumber
														: l.paymentChannelTypeName &&
														  l.paymentChannelTypeName.toLowerCase() ===
																"pos"
														? l.terminalId
														: l.paymentChannelTypeName &&
														  l.paymentChannelTypeName.toLowerCase() ===
																"cryptocurrency"
														? l.walletAddress
														: "NA"
												}}
											</td>
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-success': l.active },
														{ 'badge-danger': !l.active },
													]"
												>
													{{ l.active ? "Active" : "Inactive" }}
												</span>
											</td>
											<td>{{ formatDate(l.created) }}</td>
											<td>
												<button
													class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Actions
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<div
														v-if="
															hasPermission('Payment_Channels_Update')
														"
													>
														<a
															class="dropdown-item"
															@click.prevent="viewEdit(l)"
															v-if="l.parentId == user.parentId"
														>
															<i class="feather icon-edit"></i> Edit
														</a>
													</div>
													<div
														v-if="
															hasPermission(
																'Payment_Channels_Update',
															) && l.paymentChannelTypeName == 'BANK'
														"
													>
														<!-- <a
															class="dropdown-item"
															@click.prevent="makeDefault(l)"
															v-if="l.parentId == user.parentId"
														> -->
														<a
															class="dropdown-item"
															@click.prevent="makeDefault(l)"
															v-if="l.isDefault === false"
														>
															Make default bank
														</a>
													</div>
													<a
														class="dropdown-item"
														href="#"
														v-if="
															hasPermission('Payment_Channels_Delete')
														"
														style="color: red"
														@click.prevent="confirmDelete(l.id)"
													>
														Delete Channel
													</a>
												</div>
											</td>
										</tr>
										<tr v-if="!PaymentChannels.length">
											<td colspan="6">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-crosshairs-gps"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any payment channels.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="pageNumber"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} Payment Channel{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Create new payment channel</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Payment Channel</label
							>
							<div class="col-12 col-sm-8 col-lg-7">
								<select
									placeholder="Select an option"
									class="form-control"
									:class="[
										{
											'is-invalid': v1.paymentChannel,
										},
									]"
									v-model="createPaymentChannel.paymentChannel"
								>
									<option
										v-for="(p, index) in channels"
										:key="index"
										:value="p.name"
									>
										{{ p.name }}
									</option>
								</select>
								<span v-if="v1.paymentChannel" class="invalid-feedback">
									Please select a payment channel.
								</span>
							</div>
						</div>
						<div v-if="createPaymentChannel.paymentChannel.toLowerCase() === 'pos'">
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal Issuer</label
								>
								<div class="col-12 col-sm-7">
																			<!-- v-model="createPaymentChannel.pos.bank" -->

									<multiselect
										v-model="posCreateSelect"
										label="name"
										track-by="code"
										:options="bankLists"
										@select="onSelectCreatePosPay"
									></multiselect>

									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v1.pos.bank }]"
										placeholder="Select an option"
										v-model="createPaymentChannel.pos.bank"
									>
										<option v-for="b in bankLists" :key="b.code" :value="b.name">
											{{ b.name }}
										</option>
									</select> -->
									<span v-if="v1.pos.bank" class="invalid-feedback">
										Terminal issuer is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal/Device Id</label
								>
								<div class="col-12 col-sm-8">
									<div class="row">
										<div class="col-10">
										<input
											:class="[
												'form-control',
												{
													'is-invalid': v1.pos.terminalId,
												},
											]"
											v-model.trim="createPaymentChannel.pos.terminalId"
											@blur="validateTerminal('create')"
											placeholder="Enter terminal id"
										/>

										<span v-if="v1.pos.terminalId" class="invalid-feedback">
											Please enter a unique terminal Id.
										</span>
									</div>
									<div class="col-2">
										<span v-if="isTerminalValid" class="mdi mdi-check text-success"></span>
									</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="
								createPaymentChannel.paymentChannel.toLowerCase() ===
									'cryptocurrency'
							"
						>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Provider</label
								>
								<div class="col-12 col-sm-8 col-lg-7">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v1.crypto.provider,
											},
										]"
										type="text"
										v-model.trim="createPaymentChannel.crypto.provider"
										placeholder="Enter provider"
									/>
									<span v-if="v1.crypto.provider" class="invalid-feedback">
										Please enter a provider.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Wallet Address</label
								>
								<div class="col-12 col-sm-8 col-lg-7">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v1.crypto.walletAddress,
											},
										]"
										type="text"
										v-model.trim="createPaymentChannel.crypto.walletAddress"
										placeholder="Enter wallet address"
									/>
									<span v-if="v1.crypto.walletAddress" class="invalid-feedback">
										Please enter a wallet Address.
									</span>
								</div>
							</div>
						</div>
						<div v-if="createPaymentChannel.paymentChannel.toLowerCase() === 'bank'">
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Bank</label
								>
								<div class="col-12 col-sm-7">
									<multiselect
										v-model="bankCreateSelect"
										label="name"
										track-by="code"
										:options="bankLists"
										@select="onSelectCreateBankPay"
									></multiselect>

									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v1.bank.name }]"
										placeholder="Select an option"
										v-model="createPaymentChannel.bank.name"
									>
										<option v-for="b in bankLists" :key="b.code" :value="b.name">
											{{ b.name }}
										</option>
									</select> -->
									<span v-if="v1.bank.name" class="invalid-feedback">
										Bank is required.
									</span>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Account Number</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										:class="[
											{
												'is-invalid':
													v1.bank.accountNumber ||
													v1.bank.accountNumberValid,
											},
										]"
										type="text"
										v-model="createPaymentChannel.bank.accountNumber"
									/>
									<span v-if="v1.bank.accountNumber" class="invalid-feedback">
										Account is required.
									</span>
									<span
										v-if="v1.bank.accountNumberValid"
										class="invalid-feedback"
									>
										Enter a valid account number.
									</span>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Account Name</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										:class="[
											{
												'is-invalid': v1.bank.accountName,
											},
										]"
										type="text"
										disabled
										v-model="createPaymentChannel.bank.accountName"
									/>
									<span v-if="v1.bank.accountName" class="invalid-feedback">
										Account Name is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label py-0 text-sm-right">
									Bank Account
								</label>
								<div class="col-12 col-sm-8 col-lg-7">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="createPaymentChannel.isDefault"
										/>
										<span class="custom-control-label"
											>Make default bank account</span
										>
									</label>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-form-label py-0 text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-7">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createPaymentChannel.active"
									/>
									<span class="custom-control-label">This channel is active</span>
								</label>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 130px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="create"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Add</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Edit Payment Channel</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Payment Channel</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									placeholder="Select an option"
									class="form-control"
									disabled
									:class="[
										{
											'is-invalid': v2.paymentChannel,
										},
									]"
									v-model="updatePaymentChannel.paymentChannel"
								>
									<option
										v-for="(p, index) in channels"
										:key="index"
										:value="p.name"
									>
										{{ p.name }}
									</option>
								</select>
								<span v-if="v2.paymentChannel" class="invalid-feedback">
									Please select a payment channel.
								</span>
							</div>
						</div>
						<div v-if="updatePaymentChannel.paymentChannel.toLowerCase() === 'pos'">
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal Issuer</label
								>
								<div class="col-12 col-sm-7">
									<multiselect
										v-model="posUpdateSelect"
										label="name"
										track-by="code"
										:options="bankLists"
										@select="onSelectUpdatePosPay"
									></multiselect>

									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v2.pos.bank }]"
										placeholder="Select an option"
										v-model="updatePaymentChannel.pos.bank"
									>
										<option v-for="b in bankLists" :key="b.code" :value="b.name">
											{{ b.name }}
										</option>
									</select> -->
									<span v-if="v2.pos.bank" class="invalid-feedback">
										Issuer is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal/Device Id</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v2.pos.terminalId,
											},
										]"
										type="number"
										v-model.trim="updatePaymentChannel.pos.terminalId"
										placeholder="Enter terminal id"
									/>

									<span v-if="v2.pos.terminalId" class="invalid-feedback">
										Please enter a terminal Id.
									</span>
								</div>
							</div>
						</div>
						<div
							v-if="
								updatePaymentChannel.paymentChannel.toLowerCase() ===
									'cryptocurrency'
							"
						>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Provider</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v2.crypto.provider,
											},
										]"
										type="text"
										v-model.trim="updatePaymentChannel.crypto.provider"
										placeholder="Enter provider"
									/>
									<span v-if="v2.crypto.provider" class="invalid-feedback">
										Please enter a provider.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Wallet Address</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v2.crypto.walletAddress,
											},
										]"
										type="text"
										v-model.trim="updatePaymentChannel.crypto.walletAddress"
										placeholder="Enter wallet address"
									/>
									<span v-if="v2.crypto.walletAddress" class="invalid-feedback">
										Please enter a wallet Address.
									</span>
								</div>
							</div>
						</div>

						<div v-if="updatePaymentChannel.paymentChannel.toLowerCase() === 'bank'">
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Bank</label
								>
								<div class="col-12 col-sm-7">
									<multiselect
										v-model="bankUpdateSelect"
										label="name"
										track-by="code"
										:options="bankLists"
										@select="onSelectUpdateBankPay"
									></multiselect>

									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v2.bank.name }]"
										placeholder="Select an option"
										v-model="updatePaymentChannel.bank.name"
									>
										<option v-for="b in bankLists" :key="b.code" :value="b.name">
											{{ b.name }}
										</option>
									</select> -->
									<span v-if="v2.bank.name" class="invalid-feedback">
										Bank is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Account Name</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										:class="[
											{
												'is-invalid': v2.bank.accountName,
											},
										]"
										type="text"
										v-model="updatePaymentChannel.bank.accountName"
									/>
									<span v-if="v2.bank.accountName" class="invalid-feedback">
										Account Name is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Account Number</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										:class="[
											{
												'is-invalid':
													v2.bank.accountNumber ||
													v2.bank.accountNumberValid,
											},
										]"
										type="text"
										v-model="updatePaymentChannel.bank.accountNumber"
									/>
									<span v-if="v2.bank.accountNumber" class="invalid-feedback">
										Account is required.
									</span>
									<span
										v-if="v2.bank.accountNumberValid"
										class="invalid-feedback"
									>
										Enter a valid account number.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label py-0 text-sm-right">
									Bank Account
								</label>
								<div class="col-12 col-sm-8 col-lg-7">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="updatePaymentChannel.isDefault"
										/>
										<span class="custom-control-label"
											>Make default bank account</span
										>
									</label>
								</div>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-4 col-form-label py-0 text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updatePaymentChannel.active"
									/>
									<span class="custom-control-label"
										>This payment channel is active</span
									>
								</label>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="update"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Payment Channels</option>
									<option value="True">Active</option>
									<option value="False">Disabled</option>
								</select>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by name..."
									v-model="filter.qry"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getPaymentChannels"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
		<GeneralDeleteCaution
			:id="DeleteId"
			@proceed="deleteChannel"
			:isBusy="isBusyModal"
			text="payment channel"
		/>
		<GeneralDeleteMessage
			message="You cannot delete this Payment Channel because there are payments or 
      posting transactions associated with it. Consider disabling it instead."
		/>
	</div>
</template>

<script>
	/* eslint-disable */
	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
	import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
	import SystemPermissions from "@/permissions";
	import Pagination from "../components/Pagination.vue";
	import Multiselect from "vue-multiselect";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "settingsPaymentChannels",
		components: {
			Select2,
			GeneralDeleteCaution,
			GeneralDeleteMessage,
			Pagination,
			Multiselect,
		},

		created() {
			this.getBankLists();
			this.getPaymentChannels();
			this.getChannelTypes();
		},

		data() {
			return {
				filter: {
					status: "0",
					qry: "",
					channel: "",
				},

				banks: helper.getBanks(),
				bankCodes: helper.getBankCodes(),

				isBusy: false,
				isBusyModal: false,
				createPaymentChannel: {
					paymentChannel: "",
					active: false,
					isDefault: false,
					bank: {
						name: "",
						accountName: "",
						accountNumber: "",
					},

					crypto: {
						provider: "",
						walletAddress: "",
					},

					pos: {
						bank: "",
						terminalId: "",
					},
				},

				updatePaymentChannel: {
					paymentChannel: "",
					active: false,
					bank: {
						name: "",
						accountName: "",
						accountNumber: "",
					},

					crypto: {
						provider: "",
						walletAddress: "",
					},

					pos: {
						bank: "",
						terminalId: "",
					},
				},

				v1: {
					paymentChannel: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				},

				v2: {
					paymentChannel: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				},
				bankLists:[],
				channels: [],
				PaymentChannels: [],
				DeleteId: "",
				count: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				bankCreateSelect:"",
				bankUpdateSelect:"",
				posCreateSelect:"",
				posUpdateSelect:"",
				isTerminalValid: false,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			bankName() {
				return `${this.createPaymentChannel.bank.name}`;
			},
			bankAccount() {
				return `${this.createPaymentChannel.bank.accountNumber}`;
			},
		},

		watch: {
			bankName(newValue, oldValue) {},
			bankAccount(newValue, oldValue) {
				if (newValue.length == 10) {
					let bankCode = this.bankLists.filter((el) => el.name === this.bankCreateSelect.name)[0].code;
					this.validatebankAccount(newValue, bankCode);
				}
			},
		},

		methods: {
			async validateTerminal(type){
					this.isBusy = true;
					this.v1.pos.terminalId = false;
					this.isTerminalValid = false;
					let terminalId = type === 'create' ? this.createPaymentChannel.pos.terminalId: this.updatePaymentChannel.pos.terminalId;
			
				try {
					const response = await this.$http.get(
						`/PaymentChannels/posTerminalUnique?terminalId=${terminalId}`,
					);
					if (response.ok) {
						// if (response.body) {
						this.isTerminalValid = response.body;
						this.v1.pos.terminalId = !response.body
						// }
						this.isBusy = false;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},
			async validatebankAccount(accountnumber, code) {
				this.isBusy = true;
				// let body = {
				// 	accountNo: accountnumber,
				// 	bankCode: code,
				// };
				try {
					const response = await this.$http.post(
						`/Payments/accountValidation?accountNo=${accountnumber}&bankCode=${code}`,
					);
					if (response.ok) {
						if (response.body.status == "Account Not Found") {
							this.$toast.error(response.body.message, {
								icon: true,
								rtl: false,
							});

							this.isBankAccountValid = true;
							this.createPaymentChannel.bank.accountName =
								response.body.data.account_name;
							return;
						}
						this.$toast.success(response.body.message, {
							icon: true,
							rtl: false,
						});
						// this.accountName = data.data.accounnt_name;
						this.isBusy = false;
						this.createPaymentChannel.bank.accountName =
							response.body.data.account_name;
						this.isBankAccountValid = true;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},
			reset() {
				this.filter = {
					status: "0",
					qry: "",
					channel: 0,
				};
			},

			onSelectCreateBankPay(option) {
				this.createPaymentChannel.bank.name = option.name;
			},

			onSelectCreatePosPay(option) {
				this.createPaymentChannel.pos.bank = option.name;
			},

			onSelectUpdateBankPay(option) {
				this.updatePaymentChannel.bank.name = option.name;
			},

			onSelectUpdatePosPay(option) {
				this.updatePaymentChannel.pos.bank = option.name;
			},

			confirmDelete(id) {
				this.DeleteId = id;
				$("#mod-confirm-delete").modal("show");
			},

			showDeleteMsg() {
				$("#mod-delete-msg").modal("show");
			},

			cancelConfirm() {
				this.DeleteId = "";
				$("#mod-confirm-delete").modal("hide");
			},
			async deleteChannel() {
				this.isBusyModal = true;
				try {
					const res = await this.$http.delete(`/PaymentChannels/${this.DeleteId}`);
					if (res.ok) {
						this.$toast.success("Payment channel deleted successfully", {
							icon: true,
							rtl: false,
						});
						this.getPaymentChannels();
						this.cancelConfirm();
					}
				} catch (error) {
					this.cancelConfirm();
					this.showDeleteMsg();
				}
				this.isBusyModal = false;
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},

			viewAdd() {
				this.createPaymentChannel = {
					paymentChannel: "",
					active: false,
					isDefault: false,
					bank: {
						name: "",
						accountName: "",
						accountNumber: "",
					},

					crypto: {
						provider: "",
						walletAddress: "",
					},

					pos: {
						bank: "",
						terminalId: "",
					},
				};

				this.v1 = {
					paymentChannel: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				};
				$("#mod-add").modal("show");
			},

			viewEdit(c) {
				this.updatePaymentChannel.paymentChannel = c.paymentChannelTypeName;
				this.updatePaymentChannel.id = c.id;
				this.updatePaymentChannel.crypto.provider = c.bankName;
				this.updatePaymentChannel.crypto.walletAddress = c.walletAddress;
				this.updatePaymentChannel.pos.terminalId = c.terminalId;
				this.updatePaymentChannel.pos.bank = c.bankName;
				this.updatePaymentChannel.bank.name = c.bankName;
				this.updatePaymentChannel.bank.accountName = c.accountName;
				this.updatePaymentChannel.bank.accountNumber = c.accountNumber;
				this.updatePaymentChannel.active = c.active;
				this.updatePaymentChannel.isDefault = c.isDefault;
				$("#mod-edit").modal("show");
			},

			async makeDefault(c) {
				this.updatePaymentChannel.paymentChannel = c.paymentChannelTypeName;
				this.updatePaymentChannel.id = c.id;
				this.updatePaymentChannel.crypto.provider = c.bankName;
				this.updatePaymentChannel.crypto.walletAddress = c.walletAddress;
				this.updatePaymentChannel.pos.terminalId = c.terminalId;
				this.updatePaymentChannel.pos.bank = c.bankName;
				this.updatePaymentChannel.bank.name = c.bankName;
				this.updatePaymentChannel.bank.accountName = c.accountName;
				this.updatePaymentChannel.bank.accountNumber = c.accountNumber;
				this.updatePaymentChannel.active = c.active;
				this.updatePaymentChannel.isDefault = true;

				this.update();
			},

			viewFilter() {
				$("#mod-filter").modal("show");
			},

			async getChannelTypes() {
				this.isBusyModal = true;
				this.isBusy = true;
				try {
					const response = await this.$http.get("/PaymentChannelType/list");
					if (response.ok) this.channels = response.body;
				} catch (error) {}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			async getBankLists() {
				this.isBusyModal = true;
				this.isBusy = true;
				try {
					const response = await this.$http.get("/Payments/getbanks");
					if (response.ok) this.bankLists = response.body;
				} catch (error) {}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			async create() {
				if (this.validateCreate()) {
					this.isBusyModal = true;
					this.isBusy = true;
					let bankCode = "";
					let bankName = "";
					// if (this.createPaymentChannel.paymentChannel == "BANK") {
					// 	bankCode = this.bankLists.filter((el) => el.name === this.createPaymentChannel.bank.name)[0].code;
					// }
					if (this.createPaymentChannel.paymentChannel == "BANK") {
						bankCode = this.bankLists.filter((el) => el.name === this.createPaymentChannel.bank.name)[0].code;
					}

					const data = {
						...this.createPaymentChannel,
						bank:
							this.createPaymentChannel.paymentChannel == "BANK"
								? {
										...this.createPaymentChannel.bank,
										bankCode,
								  }
								: this.createPaymentChannel.bank,
						parentId: this.user.parentId,
					};

					await this.$http
						.post("/PaymentChannels/create", data)
						.then(function(res) {
							this.isBusyModal = false;
							this.$toast.success("Payment channel created successfully.", {
								icon: false,
								rtl: false,
							});
							$("#mod-add").modal("hide");
						})
						.catch(function() {});

					this.getPaymentChannels();
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateCreate() {
				this.v1 = {
					paymentChannel: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				};

				let isValid = true;

				if (!this.createPaymentChannel.paymentChannel) {
					this.v1.paymentChannel = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "pos" &&
					!this.createPaymentChannel.pos.terminalId && !this.isTerminalValid
				) {
					this.v1.pos.terminalId = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "pos" &&
					!this.createPaymentChannel.pos.bank
				) {
					this.v1.pos.bank = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "cryptocurrency" &&
					!this.createPaymentChannel.crypto.provider
				) {
					this.v1.crypto.provider = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "cryptocurrency" &&
					!this.createPaymentChannel.crypto.walletAddress
				) {
					this.v1.crypto.walletAddress = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.createPaymentChannel.bank.name
				) {
					this.v1.bank.name = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.createPaymentChannel.bank.accountNumber
				) {
					this.v1.bank.accountNumber = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.createPaymentChannel.bank.accountName
				) {
					this.v1.bank.accountName = true;
					isValid = false;
				}

				if (
					this.createPaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					this.createPaymentChannel.bank.accountNumber &&
					!helper.validateNuban(this.createPaymentChannel.bank.accountNumber)
				) {
					this.v1.bank.accountNumberValid = true;
					isValid = false;
				}

				return isValid;
			},

			async update() {
				this.isBusyModal = true;
				this.isBusy = true;
				let bankCode = "";
				if (this.updatePaymentChannel.paymentChannel == "BANK") {
					bankCode = this.bankLists.filter((el) => el.name === this.updatePaymentChannel.bank.name)[0].code;
				}

				const data = {
					...this.updatePaymentChannel,
					bank:
						this.updatePaymentChannel.paymentChannel == "BANK"
							? {
									...this.updatePaymentChannel.bank,
									bankCode,
							  }
							: this.updatePaymentChannel.bank,
				};

				if (this.validateUpdate()) {
					await this.$http
						.put("/PaymentChannels/update/" + data.id, data)
						.then(function(res) {
							this.isBusyModal = false;
							this.$toast.success("Payment channel updated successfully.", {
								icon: false,
								rtl: false,
							});
						})
						.catch(function() {});
					$("#mod-edit").modal("hide");
					this.getPaymentChannels();
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateUpdate() {
				this.v2 = {
					paymentChannel: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				};

				var isValid = true;

				if (!this.updatePaymentChannel.paymentChannel) {
					this.v2.paymentChannel = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "pos" &&
					!this.updatePaymentChannel.pos.terminalId && !this.isTerminalValid
				) {
					this.v2.pos.terminalId = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "pos" &&
					!this.updatePaymentChannel.pos.bank
				) {
					this.v2.pos.bank = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "cryptocurrency" &&
					!this.updatePaymentChannel.crypto.provider
				) {
					this.v2.crypto.provider = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "cryptocurrency" &&
					!this.updatePaymentChannel.crypto.walletAddress
				) {
					this.v2.crypto.walletAddress = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.updatePaymentChannel.bank.name
				) {
					this.v2.bank.name = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.updatePaymentChannel.bank.accountNumber
				) {
					this.v2.bank.accountNumber = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					!this.updatePaymentChannel.bank.accountName
				) {
					this.v2.bank.accountName = true;
					isValid = false;
				}

				if (
					this.updatePaymentChannel.paymentChannel.toLowerCase() === "bank" &&
					this.updatePaymentChannel.bank.accountNumber &&
					!helper.validateNuban(this.updatePaymentChannel.bank.accountNumber)
				) {
					this.v2.bank.accountNumberValid = true;
					isValid = false;
				}

				return isValid;
			},

			async getPaymentChannels() {
				this.isBusy = true;

				await this.$http
					.get(
						"/PaymentChannels/getList?pageNumber=" +
							this.pageNumber +
							"&PaymentChannel=" +
							this.filter.qry +
							"&pageSize=" +
							this.pageSize +
							(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
							(this.filter.qry != "" ? "&PaymentChannel=" + this.filter.qry : "") +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					)
					.then(function(res) {
						this.PaymentChannels = res.body.data;
						this.pageNumber = res.body.pageNumber;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			formatDate(date) {
				return moment(date).format("lll");
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			prev() {
				this.pageNumber -= 1;
				this.getPaymentChannels();
			},

			next() {
				this.pageNumber += 1;
				this.getPaymentChannels();
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getPaymentChannels();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getPaymentChannels();
			},
		},
	};
</script>
