<template>
	<div class="modal fade" id="mod-confirm-payment" data-backdrop="static" data-keyboard="false" role="dialog">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body p-4">
					<div  v-if="modalName == 'confirm order payment'">
						<p>
							You are paying <span class="font-weight-bold">NGN {{ formatMoney(order.total - order.totalAmountPaid) }}</span> 
							and transaction fee of <span class="font-weight-bold">NGN {{ formatMoney(transactionFee) }}</span> 
							for your order from <span class="font-weight-bold">{{ order.seller.name }}</span>. 							
						</p>
						<p>	Total amount is <span class="font-weight-bold">NGN {{ formatMoney(totalFee) }}</span></p>
						<p class="text-center mt-2">
							<span class="text-primary">Do you want to proceed?</span>
						</p>
					</div>
			
					<p v-if="modalName == 'confirm order'">
						Are you sure you want to place this order? This action can not be reversed.
					</p>
					<div class="mt-3 d-flex justify-content-between align-items-center">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click.prevent="cancelConfirm"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="handleOrder"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Confirm</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helper } from "@/helper";
	export default {
		name: "ConfirmPaymentModal",
		props: ["isBusy", "order", "modalName","transactionFee", "totalFee"],
		// data() {
		// 	return {
		// 		productCount: 0,
		// 	};
		// },
		watch: {
			order(newVal, oldVal) {
				console.log(newVal);
				return newVal;
			},
		},
		methods: {
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			handleOrder() {
				this.$emit("proceed", "placeOrderAndPay");
			},
			cancelConfirm() {
				this.$emit("cancel");
			},
		},
	};
</script>
