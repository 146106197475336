<template>
	<div id="paymentReceipt" style="display: none" class="card-body bg-white text-dark">
		<div class="container my-3">
			<div class="row">
				<div class="col-md-12">
					<div class="invoice-title">
						<div class="row">
							<div class="col-md-4">
								<img
									class="logo-img"
									:src="url"
									alt="logo"
									max-width="138"
									height="37"
								/>
							</div>
							<div class="col-md-8 text-right">
								<address>
									<p style="font-size: 14px; margin: 0 0 8px 0">
										<span style="color: #9c9c9c">Date:</span>
										{{ formatDate(order.created) }}
									</p>
									<p style="font-size: 14px; margin: 0">
										<span style="color: #9c9c9c">RN:</span>
										{{ order.orderNumber }}
									</p>
								</address>
							</div>
						</div>
						<hr />
					</div>
					<div class="row mt-3 mb-4">
						<div class="col-md-6">
							<address style="width: 200px">
								<p
									style="font-size: 18px; line-height: 23px; font-weight: bold; margin-bottom: 8px"
								>
									{{ order.seller ? order.seller.name : "NA" }}
								</p>
								<p style="font-size: 14px; margin: 0 0 8px 0">
									{{ order.seller ? order.seller.phoneNumber : "NA" }}
								</p>
								<p style="font-size: 14px; margin: 0 0 8px 0">
									{{ order.seller ? order.seller.emailAddress : "NA" }}
								</p>
								<p style="font-size: 14px; margin: 0 0 8px 0">
									{{ order.seller ? order.seller.address : "NA" }}
								</p>
							</address>
						</div>
						<div class="col-md-6 text-right">
							<address>
								<p
									style="font-size: 18px; line-height: 23px; font-weight: bold; margin-bottom: 8px"
								>
									To: {{ order.maker ? order.maker.displayName : "N/A" }}
								</p>
								<p style="font-size: 14px; margin: 0 0 8px 0">
									<span style="color: #9c9c9c">Number:</span>
									{{ order.maker ? order.maker.phoneNumber : "N/A" }}
								</p>
								<p style="font-size: 14px; margin: 0">
									<span style="color: #9c9c9c">Address:</span>
									{{ order.maker ? order.maker.deliveryAddress : "N/A" }}
								</p>
								<p
									style="font-size: 18px; line-height: 23px; font-weight: bold; margin-bottom: 8px"
								>
									<span style="color: #9c9c9c">Customer Account Balance:</span>
									&#8358; {{ order.maker ? order.maker.balance : "N 0:00" }}
								</p>
							</address>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="panel panel-default">
						<div
							class="panel-heading pl-2 mb-2"
							style="height: 71px; background: rgba(221, 221, 221, 0.15);display: flex; align-items: center"
						>
							<h3 class="panel-title">
								<strong>Order summary</strong>
							</h3>
						</div>
						<div class="panel-body">
							<div class="table-responsive">
								<table class="table table-condensed table-borderless">
									<thead
										style="height: 75px; background: #ddedaa26; color: #373737"
									>
										<tr>
											<td style="width: 45%">
												Item Description
											</td>
											<td
												style="width: 20%; text-align: center"
												class="text-center"
											>
												Price (NGN)
											</td>
											<td
												style="width: 15%; text-align: center"
												class="text-center"
											>
												Quantity
											</td>
											<td
												style="width: 20%; text-align: right"
												class="text-right"
											>
												Amount (NGN)
											</td>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in order.details" :key="index">
											<td>{{ item.code }} - {{ item.description }}</td>
											<td
												class="text-center font-weight-light"
												style="text-align: center"
											>
												&#8358; {{ formatMoney(item.unitPrice) }}
											</td>
											<td
												class="text-center font-weight-light"
												style="text-align: center"
											>
												{{ item.quantity }}
											</td>
											<td
												class="text-right font-weight-light"
												style="text-align: right"
											>
												&#8358;
												{{ formatMoney(item.quantity * item.unitPrice) }}
											</td>
										</tr>

										<tr>
											<td colspan="3">&nbsp;</td>
										</tr>

										<tr>
											<td class="thick-line"></td>
											<td class="thick-line"></td>
											<td class="thick-line" style="color: #373737">
												Subtotal
											</td>
											<td
												class="thick-line text-right"
												style="text-align: right; color: #373737"
											>
												<strong
													>&#8358;
													{{ formatMoney(order.subtotal) }}</strong
												>
											</td>
										</tr>
										<tr>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line" style="color: #373737">
												Discount
											</td>
											<td
												class="no-line text-right"
												style="text-align: right; color: #373737"
											>
												<strong
													>&#8358;
													{{ formatMoney(order.discount) }}</strong
												>
											</td>
										</tr>
										<tr>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line" style="color: #373737">
												Payment Due
											</td>
											<td
												class="no-line text-right"
												style="text-align: right; color: #373737"
											>
												<strong
													>&#8358; {{ formatMoney(order.total) }}</strong
												>
											</td>
										</tr>
										<tr>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line" style="color: #373737">
												Total Amount Paid
											</td>
											<td
												class="no-line text-right"
												style="text-align: right; color: #373737"
											>
												<strong
													>&#8358; {{ formatMoney(totalPayment) }}</strong
												>
											</td>
										</tr>
										<tr>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line" style="color: #373737">
												{{ isExcess ? "Excess" : "Invoice Balance" }}
											</td>
											<td
												class="no-line text-right"
												style="text-align: right; color: #373737"
											>
												<strong
													>&#8358;
													{{
														formatMoney(
															Math.abs(order.total - totalPayment),
														)
													}}</strong
												>
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<h3 class="mb-2"><strong>Payment Details</strong></h3>
							</div>
							<div class="table-responsive">
								<table
									class="table table-condensed table-borderless"
									style="width: 100%"
								>
									<thead
										style="height: 75px; background: #ddedaa26; color: #373737"
									>
										<tr>
											<td style="width: 20%">
												<strong>Amount Paid (NGN)</strong>
											</td>
											<td style="width: 15%">
												<strong>Type</strong>
											</td>
											<td style="width: 15%">
												<strong>Date Of Payment</strong>
											</td>
											<td style="width: 15%"></td>
											<td style="width: 15%"></td>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in order.paymentHistory"
											:key="index"
										>
											<td>
												&#8358;
												{{
                            item.paidAmount &lt; 0
                              ? formatMoney(item.paidAmount * -1)
                              : formatMoney(item.paidAmount)
												}}
											</td>
											<td>
												{{ item.paidAmount &lt; 0 ? "Refund" : "Payment" }}
											</td>
											<td>
												{{ formatDate(item.created) }}
											</td>
											<td></td>
											<td></td>
										</tr>
										<tr>
											<td colspan="5">&nbsp;</td>
										</tr>
										<tr>
											<td class="no-line">
												<strong>Total</strong>
											</td>
											<td class="no-line">
												&#8358; {{ formatMoney(totalPayment) }}
											</td>
											<td class="no-line"></td>
											<td class="no-line"></td>
											<td class="no-line"></td>
										</tr>
									</tbody>
								</table>
								<br />
							</div>
							<div class="py-2" style="border-top: 1px solid rgba(55, 55, 55, 0.5);">
								<p style="font-size: 14px; margin: 0">
									Note: Customer balance is the total amount of money this
									merchant owes the distributor
								</p>
							</div>
							<hr />
							<div class="row">
								<div class="col-md-6">
									<img
										src="https://app.wekurnect.ng/assets/img/logo.png"
										alt="img"
									/>
								</div>
								<div class="col-md-6 text-right">
									Receipt generated from weKurnect
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import { helper } from "@/helper";
	export default {
		name: "payment-receipt",
		props: ["order", "bank"],
		components: {},

		data() {
			return {
				url: null,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			totalPayment: function() {
				var myTotal = 0;

				if (this.order.paymentHistory) {
					for (var i = 0, len = this.order.paymentHistory.length; i < len; i++) {
						myTotal += this.order.paymentHistory[i].paidAmount;
					}
				}

				return myTotal;
			},
			isExcess: function() {
				const isNegative = Math.sign(this.order.total - this.totalPayment);
				if (isNegative >= 0) return false;
				return true;
			},
		},

		watch: {
			// bank: function(value) {
			// 	if (value) {
			// 		const baseUrl = process.env.VUE_APP_BASE_API_URL.split("/v1")[0];
			// 		this.url = baseUrl + value.logoURL;
			// 	}
			// },
			order: function(value) {
				if (value) {
					this.url = value.seller.logoURL;
				}
			},
		},

		methods: {
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatDate(date) {
				return moment(date).format("ll");
			},
		},
	};
</script>
