<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<i class="feather icon-filter text-white"></i> Filter
					</button>
					<div>
						<button
							v-if="isSelectAll"
							class="btn btn-warning btn-rounded btn-space mr-2"
							@click="deSelectAll"
						>
							Unselect All
						</button>
						<button
							v-else
							class="btn btn-primary btn-rounded btn-space mr-2"
							@click="selectAll"
						>
							Select All
						</button>
						<button
							id="markAsRead"
							@click="markAsRead"
							:disabled="selectedIds < 1"
							class="btn btn-primary btn-rounded btn-space"
						>
							Mark As Read
						</button>
					</div>
				</div>
				<div class="card-body">
					<div
						class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer table-striped table-hover-animation"
					>
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									id="myTable"
									class="table table-fw-widget dataTable no-footer text-sm text-nowrap"
								>
									<thead>
										<tr>
											<th>SN</th>
											<th>Payment Date</th>
											<th>Type</th>
											<th>Customer</th>
											<th>Amount (₦)</th>
											<th>Account Paid</th>
											<th>Payment ID</th>
											<th>Order ID</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody class="text-sm">
										<tr v-for="pN in altPaymentNotification" :key="pN.id">
											<td>
												<input
													:checked="pN.isChecked"
													type="checkbox"
													:value="pN.id"
													@change="selectNotification(pN.id)"
												/>
											</td>
											<td>{{ formatDate(pN.paymentDate) }}</td>
											<td>{{ pN.paymentType }}</td>
											<td>
												<a
													v-if="pN.customerId"
													class="text-primary"
													href="#"
													@click.prevent="
														details(
															pN.customerId,
															'/customers/account/',
														)
													"
												>
													{{ pN.customerName }}
												</a>
												<p v-else>N/A</p>
											</td>
											<td>{{ formatMoney(pN.amount) }}</td>
											<td>
												{{ pN.bankName }}<br />
												{{ pN.accountPaid }}
											</td>
											<td style="cursor: pointer">
												<p
													v-if="pN.paymentId"
													@click="getPaymentDetails(pN.paymentId)"
													class="text-primary"
												>
													{{ pN.paymentId.substring(0, 7) }}
												</p>
												<p v-else>N/A</p>
											</td>
											<td>
												
												<p v-if="!pN.orderId  || pN.orderId == 'NA'">N/A</p>
												<a
													v-else
													class="text-primary"
													href="#"
													@click.prevent="details(pN.orderId, '/orders/')"
												>
													{{ pN.orderId.substring(0, 7) }}
												</a>
											</td>
											<td>
												<button
													v-if="pN.deliveryStatus"
													class="btn btn-primary btn-sm"
												>
													Read
												</button>
												<button
													v-else
													class="
                              btn btn-warning btn-sm
                            "
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Unread
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<div>
														<a
															class="dropdown-item"
															href="#"
															@click.prevent="
																markOneItemAsRead(pN.id)
															"
														>
															Change Status to Read
														</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="pageNumber"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} account notification{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						/>
					</svg>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-paymentDetails"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>Payment Details</strong>
							</h4>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Amount</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="number"
									placeholder="Enter Amount"
									v-model.number="singlePaymentDetails.amount"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Customer</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Customer"
									v-model="singlePaymentDetails.customer"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Account ID</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Account ID"
									v-model="singlePaymentDetails.accountId"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Reference ID</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Amount"
									v-model="singlePaymentDetails.referenceId"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Channel</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Channel"
									v-model="singlePaymentDetails.channel"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right">Fees</label>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Fees"
									v-model="singlePaymentDetails.fees"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Payment Date</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Payment Date"
									v-model="singlePaymentDetails.paymentDate"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Completed Date</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Enter Completed Date"
									v-model="singlePaymentDetails.completedDate"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Created By</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Created By"
									v-model="singlePaymentDetails.createdBy"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Payment Notes</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									disabled
									class="form-control"
									type="text"
									placeholder="Payment Notes"
									v-model="singlePaymentDetails.paymentNote"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							data-dismiss="modal"
							aria-hidden="true"
							style="width: 160px"
							class="btn btn-primary"
							type="button"
						>
							<span>Done</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-filter"
			role="dialog"
			tabindex="-1"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
								<option value="">All</option>
								<option v-for="stat in status" :key="stat.text" :value="stat.value">
									{{ stat.text }}
								</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Payment Type</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.Type"
								>
									<option value="">All</option>
									<option value='Transfer In'>Transfer In</option>
									<option value='Order Payment'>Order Payment	</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateStart"
								/>
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateEnd"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="clearFilter"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getPaymentNotification"
						>
						<span v-if="isBusy">Busy...</span>
              			<span v-else>Search</span>
							
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import moment from "moment";
	import { helper } from "@/helper";
	import SystemPermissions from "@/permissions";
	import Pagination from "../../components/Pagination.vue";
	import AccountPostingCheckbox from "../../components/Posting/AccountPostingCheckbox.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		components: {
			AccountPostingCheckbox,
			Pagination,
		},

		data() {
			return {
				filter: {
					status: null,
					Type: '',
					dateStart: moment().format("1999-01-01"),
					dateEnd: moment().format("YYYY-MM-DD"),
				},
				isBusy: false,
				count: 0,
				page: 0,
				pageNumber: 1,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				paymentNotification: [],
				paymentDetailsList: [],
				paymentDetails: {
					id: null,
					count: 0,
					page: 0,
					pageNumber: 1,
					pageSize: 10,
					pageCount: 0,
					hasNext: false,
					hasPrev: false,
				},
				singlePaymentDetails: {
					amount: 0,
					customer: "",
					accountId: "",
					referenceId: "",
					channel: "",
					fees: "",
					paymentDate: "",
					completedDate: "",
					createdBy: "",
					paymentNote: "",
				},
				isSelectAll: false,
				selectedIds: [],
				altPaymentNotification: [],
				status:[
					{text:'Read', value:true},
					{text:'Unread', value:false},
				],
				maxDate: "",
			};
		},

		created() {
			this.getPaymentNotification();
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},

		methods: {
			clearFilter(){
				this.filter.read = null,
				this.filter.Type = '',
				this.filter.dateStart = moment().format("1999-01-01")
				this.filter.dateEnd = moment().format("YYYY-MM-DD")
			},
			details(id, link) {
				let routeData = this.$router.resolve({
					path: link + id,
				});
				window.open(routeData.href, "_blank");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},

			formatDate(date) {
				return moment(date).format("ll");
			},

			showPaymentDetails() {
				$("#mod-paymentDetails").modal("show");
				console.log("her");
			},

			viewAdd() {
				$("#mod-add-posting").modal("show");
			},

			viewFilter() {
				$("#mod-filter").modal("show");
			},
			async markAsRead() {
				console.log(this.selectedIds);
				this.isBusy = true;
				if (this.selectedIds.length < 1) {
					return this.$toast.warning("You must select a notification.", {
						icon: true,
						rtl: false,
					});
				}
				await this.$http
					.post(`/PaymentNotification/read/${this.selectedIds}`)
					.then(function(res) {
						this.altPaymentNotification = [];
						this.isSelectAll = false;
						this.getPaymentNotification();
					})
					.catch(function() {
						this.isBusy = false;
					});
			},

			async getPaymentNotification() {
				this.altPaymentNotification = [];
				this.selectedIds = [];
				this.isBusy = true;
				console.log(this.filter.status)
				await this.$http
					.get(
						`/paymentNotification?PageNumber=${this.pageNumber}&PageSize=${this.pageSize}&Read=${this.filter.status == '' ? '' :this.filter.status}&paymentType=${this.filter.Type == '' ? '' :this.filter.Type}&startDate=${this.filter.dateStart == '' ? '' :moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")}&endDate=${this.filter.dateEnd == '' ? '' :moment(this.filter.dateEnd).format("YYYY-MM-DD 00:00:00")}`
					)
					.then(function(res) {
						this.paymentNotification = res.data.data;
						this.formatNotificationArray(res.data.data);
						this.pageNumber = res.data.pageNumber;
						this.$store.dispatch("getPaymentNotificationCount");
						this.pageSize = res.data.pageSize;
						this.count = res.data.count;
						this.pageCount = Math.ceil(res.data.count / res.data.pageSize);
						this.hasPrev = res.data.hasPrev;
						this.hasNext = res.data.hasNext;
						this.isBusy = false;
						$("#mod-filter").modal("hide");
						this.clearFilter()
					})
					.catch(function() {
						this.isBusy = false;
						$("#mod-filter").modal("hide");
					});
			},

			async getPaymentDetails(id) {
				this.isBusy = true;
				await this.$http
					.get(`/PaymentNotification/paymentDetails/${id}`)
					.then(function(res) {
						let data = res.body;
						this.pageNumber = res.data.pageNumber;
						this.pageSize = res.data.pageSize;
						this.count = res.data.count;
						this.pageCount = Math.ceil(res.data.count / res.data.pageSize);
						this.hasPrev = res.data.hasPrev;
						this.hasNext = res.data.hasNext;
						this.isBusy = false;
						this.viewPaymentDetails(data);
					})
					.catch(function() {
						this.isBusy = false;
					});
			},
			viewPaymentDetails(c) {
				this.showPaymentDetails();
				this.singlePaymentDetails.amount = c.amount;
				this.singlePaymentDetails.customer = c.customerName;
				this.singlePaymentDetails.accountId = c.accountId;
				this.singlePaymentDetails.referenceId = c.referenceId;
				this.singlePaymentDetails.channel = c.channel;
				this.singlePaymentDetails.fees = c.fees;
				this.singlePaymentDetails.paymentDate =
					c.paymentDate == null
						? "No Date Found"
						: new Date(c.paymentDate).toLocaleDateString("en-US");
				this.singlePaymentDetails.completedDate =
					c.completedDate == null
						? "No Date Found"
						: new Date(c.completedDate).toLocaleDateString("en-US");
				this.singlePaymentDetails.createdBy = c.createdBy;
				this.singlePaymentDetails.paymentNote = c.paymentNote;
				console.log(c.paymentDate);
			},
			selectAll() {
				for (var i = 0; i < this.altPaymentNotification.length; i++) {
					this.altPaymentNotification[i].isChecked = true;
					this.selectedIds.push(this.altPaymentNotification[i].id);
				}
				console.log(this.selectedIds);
				this.isSelectAll = true;
			},

			selectNotification(id) {
				if (this.selectedIds.includes(id)) {
					let filterSelected = this.selectedIds.filter(item => item != id);
					this.selectedIds = filterSelected;
				} else {
					this.selectedIds.push(id);
				}
			},

			async markOneItemAsRead(id) {
				this.isBusy = true;
				await this.$http
					.post(`/PaymentNotification/read/${id}`)
					.then(function(res) {
						this.altPaymentNotification = [];
						this.isSelectAll = false;
						this.getPaymentNotification();
					})
					.catch(function() {
						this.isBusy = false;
					});
			},

			formatNotificationArray(arr) {
				for (var i = 0; i < arr.length; i++) {
					let obj = {
						isChecked: false,
						id: arr[i].id,
						paymentDate: arr[i].paymentDate,
						paymentType: arr[i].paymentType,
						customerId: arr[i].customerId,
						customerName: arr[i].customerName,
						amount: arr[i].amount,
						bankName: arr[i].bankName,
						accountPaid: arr[i].accountPaid,
						paymentId: arr[i].paymentId,
						orderId: arr[i].orderId,
						deliveryStatus: arr[i].deliveryStatus,
					};
					this.altPaymentNotification.push(obj);
				}
			},

			deSelectAll() {
				for (var i = 0; i < this.altPaymentNotification.length; i++) {
					this.altPaymentNotification[i].isChecked = false;
				}
				this.isSelectAll = false;
				this.selectedIds = [];
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},

			goToPayment(id) {
				this.$router.push("/payments/" + id);
			},

			updateFilter(filter) {
				this.$store.commit("updateAccountPosting", filter);
			},

			prev() {
				this.pageNumber -= 1;
				this.getPaymentNotification();
			},

			next() {
				this.pageNumber += 1;
				this.getPaymentNotification();
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getPaymentNotification();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getPaymentNotification();
			},
		},
	};
</script>

<style scoped>
	.text-sm {
		font-size: 12px;
		font-weight: 300;
	}
</style>
