<template>
  <div class="onboarding-body">
    <div class="onboarding-card">
      <img src="/assets/img/logo-xx.png" alt="logo" width="175" />
      <p class="onboarding-header">Reset Password</p>
      <p v-if="mode=='email'" class="onboarding-desc">Please enter your registered email to reset your password.</p>
      <p v-else class="onboarding-desc">
          <span v-if="phoneValStep === 1"> Please enter your phone number to receive a one time verification code (Merchant Only). </span>
          <span v-if="phoneValStep === 2"> Enter 6-digit validation code received. </span>
          <span v-if="phoneValStep === 3"> Please enter your new pasword.</span>
      </p>
      
      <div v-if="mode == 'email'">
        <form @submit.prevent="forgotPassword">
          <div>
            <label class="merchant-login-label mt-2"> EMAIL ADDRESS* </label>
            <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.email }]">
              <input type="text" placeholder="Enter email address" v-model="email" />
            </div>
            <p v-if="credentialValidation.email">
              <img src="/assets/img/error-icon-new.svg" alt="error" />
              <span class="login-error">Email cannot be empty</span>
            </p>
            <p v-if="credentialValidation.emailValid">
              <img src="/assets/img/error-icon-new.svg" alt="error" />
              <span class="login-error">Input a valid email</span>
            </p>
          </div>

            <div class="my-2" style="cursor:pointer" @click.prevent="changeMode('phone')">
                <p class="text-primary">Use Phone Number (Merchant Only)</p>
            </div>

          <button type="submit" class="login-submit-btn" :disabled="isBusy">
            <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
              <i class="sr-only"></i>
            </span>
            <span v-else>
              Recover Password
            </span>
          </button>
        </form>

        <p class="text-center text-black">
            <router-link to="/growtrade-login">Back to Login</router-link>
        </p>
      </div>

       <div v-if="mode == 'phone'">
          <form v-if="phoneValStep === 1">
            <label>Phone Number</label>
            <div class="form-group">
                <div style="border: 1px solid gray" class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-size: 14px; font-weight: bold; border-right:1px soid gray">
                      +234
                    </span>
                  </div>

                  <input
                    :class="[
                      'form-control',
                      'font-medium-1',
                    ]"
                    type="text"
                    placeholder="10 digits"
                    autocomplete="off"
                    v-model="user.phone"
                  />
                </div>
                <p
                    v-if="
                      !phoneValidation.phoneValid
                    "
                    class="parsley-errors-list filled"
                  >
                    <span
                      class="parsley-required text-danger font-small-2"
                      >* 10 digit phone number required.</span
                    >
                  </p>
            </div>

            <div class="my-2" style="cursor:pointer" @click.prevent="changeMode('email')">
                <p class="text-primary">Use Email Address</p>
            </div>

            <div class="form-group">
                <button :disabled="isBusyStep1"
                  class="btn btn-block btn-primary btn-lg p-1"
                  @click.prevent="sendPhoneCode"
                >
                    <i class="feather icon-lock"></i> &nbsp;
                    <span v-if="isBusyStep1">Busy...</span>
                    <span v-else>Send Code</span>
                </button>

            </div>
            <p class="text-center">
                <router-link to="/growtrade-login">Back to Login</router-link>
            </p>
        </form>

          <form v-if="phoneValStep === 2">
            <!-- <div class="form-group"> -->
                  <div class="row">
                    <label class="col-12"
                      ><span class="font-weight-bold">Enter OTP</span></label
                    >
                    <div class="col-12">
                      <otp-input
                        :isValid="verifOTPValid"
                        :digits="6"
                        type="number"
                        @on-complete="onCompleteOTPHandler"
                        @on-changed="onChangedOTPHandler"
                        @on-paste="onPasteOTPHandler">
                          <template #errorMessage> OTP is not valid </template>
                      </otp-input>

                      <div v-if="isBusyOtp" class="my-2">Resending OTP...</div>
                      <div v-else class="my-2">
                        <span v-if="timerValue > 0">Resending in {{prettyTime}}</span>
                        <button v-else @click.prevent="resendPhoneOtp" class="btn btn-text">Didn't get OTP?  Resend </button>
                      </div>
                    </div>
                  </div>
            <!-- </div> -->                                        

            <div class="form-group mt-2">
                <button :disabled="isBusyStep2"
                  class="btn btn-block btn-primary btn-lg p-1"
                  @click.prevent="validatePhoneCode"
                >
                    <i class="feather icon-lock"></i> &nbsp;
                    <span v-if="isBusyStep2">Busy...</span>
                    <span v-else>Send Code</span>
                </button>

            </div>
            <p class="text-center">
                <router-link to="/growtrade-login">Back to Login</router-link>
            </p>
        </form>

          <form v-if="phoneValStep === 3">
            <div class="my-2">
            <label>PLEASE ENTER YOUR NEW PASSWORD</label>
              <div class="input-group">
                <input
                  style="border:1px solid gray"
                  :class="[
                    'form-control',
                    'font-medium-1',
                  ]"
                  :type="[
                    toggledViewPassword ? 'text' : 'password',
                  ]"
                  placeholder="Enter Password"
                  autocomplete="off"
                  v-model.trim="user.password"
                />
                <div class="input-group-prepend cursor-pointer">
                  <span
                    class="input-group-text d-flex justify-content-center"
                    @click="
                      toggledViewPassword = !toggledViewPassword
                    "
                  >
                    <i
                      class="fa"
                      :class="[
                        toggledViewPassword
                          ? 'fa-eye'
                          : 'fa-eye-slash',
                      ]"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>

              <p v-if="!phoneValidation.passwordValid" class="parsley-errors-list filled">
                <span class="parsley-required text-danger font-small-2">* Minimum of 8 characters required.</span>
              </p>
            </div>

            <div class="my-1">
              <label>CONFIRM PASSWORD BY ENTERING IT AGAIN</label>
              <div class="input-group">
                <input
                  style="border:1px solid gray"
                  :class="[
                    'form-control',
                    'font-medium-1',
                  ]"
                  :type="[
                    toggledViewPasswordConfirm ? 'text' : 'password',
                  ]"
                  placeholder="Enter Password"
                  autocomplete="off"
                  v-model.trim="user.confirmPassword"
                />
                <div class="input-group-prepend cursor-pointer">
                  <span
                    class="input-group-text d-flex justify-content-center"
                    @click="
                      toggledViewPasswordConfirm = !toggledViewPasswordConfirm
                    "
                  >
                    <i
                      class="fa"
                      :class="[
                        toggledViewPasswordConfirm
                          ? 'fa-eye'
                          : 'fa-eye-slash',
                      ]"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>
                <p v-if="!phoneValidation.confirmPasswordValid" class="parsley-errors-list filled">
                <span class="parsley-required text-danger font-small-2">* Minimum of 8 characters required.</span>
                </p>
            </div>

            <p v-if="!phoneValidation.passwordMatch" class="parsley-errors-list filled">
                <span class="parsley-required text-danger font-small-2">* Password and Confirm password must match.</span>
            </p>
        
            <div class="form-group mt-2">
                <button :disabled="isBusyStep3"
                  class="btn btn-block btn-primary btn-lg p-1"
                  @click.prevent="confirmNewPassword"
                >
                    <i class="feather icon-lock"></i> &nbsp;
                    <span v-if="isBusyStep3">Busy...</span>
                    <span v-else>Reset Password</span>
                </button>

            </div>
            <p class="text-center">
                <router-link to="/growtrade-login">Back to Login</router-link>
            </p>
        </form>
      </div>

    </div>


    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-growtrade-forgotPassword-modal"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Check your mailbox</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="parent-div success-div">
                <svg width="88" height="87" viewBox="0 0 88 87" fill="none">
                  <path
                    d="M73.9055 40.1262L82.9726 34.5691C82.8912 34.3678 82.7958 34.1725 82.6871 33.9846C82.3304 33.3513 81.8495 32.7964 81.2733 32.3533L73.9055 26.7663L54.7655 12.2346L47.289 6.56596C46.3411 5.85137 45.1863 5.46484 43.9993 5.46484C42.8122 5.46484 41.6575 5.85137 40.7096 6.56596L33.233 12.2346L14.093 26.7663L6.72523 32.3533C6.14906 32.7964 5.66822 33.3513 5.31148 33.9846C5.07851 34.3688 4.90439 34.7858 4.79492 35.2216L43.3114 58.5892L73.9055 40.1262Z"
                    fill="#3C3480"
                  />
                  <path
                    d="M73.9062 26.7661V14.9531C73.9062 14.2321 73.6198 13.5405 73.1099 13.0307C72.6001 12.5208 71.9086 12.2344 71.1875 12.2344H16.8125C16.0914 12.2344 15.3999 12.5208 14.8901 13.0307C14.3802 13.5405 14.0938 14.2321 14.0938 14.9531V40.8615L43.3122 58.5891L73.9062 40.126V26.7661Z"
                    fill="white"
                  />
                  <path d="M43.3125 58.5898L81.4103 81.7019L43.3125 58.5898Z" fill="#3C3480" />
                  <path d="M73.9062 40.1274L82.9733 34.5703L73.9062 40.1274Z" fill="#3C3480" />
                  <path
                    d="M73.9085 40.1274L43.3145 58.5905L81.4123 81.6998C82.0427 81.1937 82.5509 80.552 82.8992 79.8224C83.2475 79.0929 83.427 78.2942 83.4242 77.4858V36.6909C83.4278 35.9601 83.2748 35.2371 82.9756 34.5703L73.9085 40.1274Z"
                    fill="#5234AA"
                  />
                  <path
                    d="M14.0939 40.8641L4.79578 35.2227C4.64761 35.6978 4.57423 36.1931 4.57828 36.6908V77.4856C4.57828 78.9277 5.15116 80.3108 6.17089 81.3305C7.19062 82.3502 8.57367 82.9231 10.0158 82.9231H77.9845C79.233 82.9207 80.4426 82.4887 81.4102 81.6997L14.0939 40.8627V40.8641Z"
                    fill="#675CE8"
                  />
                  <path
                    d="M82.9747 34.5703L73.9077 40.1274V66.6108C73.9083 68.176 73.57 69.7228 72.916 71.1448C72.262 72.5668 71.3079 73.8304 70.1191 74.8486L81.4115 81.6998C82.0419 81.1937 82.5501 80.552 82.8984 79.8224C83.2467 79.0929 83.4261 78.2942 83.4233 77.4858V36.6909C83.4269 35.9601 83.274 35.2371 82.9747 34.5703Z"
                    fill="#3E3881"
                  />
                  <path
                    d="M63.0312 77.4848H4.57812C4.57813 78.927 5.151 80.31 6.17073 81.3297C7.19046 82.3495 8.57351 82.9223 10.0156 82.9223H77.9844C79.2328 82.9199 80.4424 82.4879 81.41 81.6989L70.1177 74.8477C68.1493 76.55 65.6336 77.4862 63.0312 77.4848Z"
                    fill="#675CE8"
                  />
                  <rect x="19.5508" y="25" width="33.4746" height="3" rx="1.5" fill="#E1E1E1" />
                  <rect x="19.5" y="32" width="38.9121" height="3" rx="1.5" fill="#E1E1E1" />
                  <rect x="19.5" y="39" width="43.1602" height="3" rx="1.5" fill="#E1E1E1" />
                </svg>
              </div>
            </div>
            <p class="text-center text-bold">
              We have sent you a reset password link <br />
              to your email address.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="resend-button"
              :disabled="isBusy"
              @click.prevent="resendVerification"
              type="button"
            >
              <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
                <i class="sr-only"></i>
              </span>
              <span v-else>
                Resend email verification
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

      <div
          class="modal fade colored-header colored-header-primary"
          id="mod-phone-password-change"
          role="dialog"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header modal-header-colored">
                <h4 class="modal-title p-1">
                  <strong>Reset Password</strong>
                </h4>
              </div>
             <div class="modal-body">
              <div class="parent-div success-div">
                <div class="first-child">
                  <div class="second-child">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <path
                        d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
                
               <p class="text-center">Your pasword has been reset successfully, proceed to login</p>
                <div class="text-right w-100 mt-2">
                  <button class="btn btn-primary btn-md" @click.prevent="gotoLogin">Procced to Login</button>
                </div>
             </div>
            </div>
          </div>
      </div>
    
  </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";
import OtpInput from "otp-input-vue2";

export default {
  name: "distributor-forgot-password",
  components: {
    OtpInput,
  },

  data() {
    return {
      email: null,
      credentialValidation: {
        email: false,
        emailValid: false,
      },
      isBusy: false,
      user: {
        phone: "",
        password: "",
        confirmPassword: ""
      },
      type: "password",
      toggledViewPassword: false,
      typeConfirm: "password",
      toggledViewPasswordConfirm: false,
      mode:"email",
      phoneValStep: 1,

      phoneValidation: {
        phoneValid: true,
        passwordValid: true,
        confirmPasswordValid: true,
        passwordMatch: true,
      },

      timerSeconds: "00",
      timerValue: 300,
      timer: null,
      timerStarted: false,  

      verifOTP: "",
      verifOTPValid: true,
      isBusyStep1: false,
      isBusyStep2: false,
      isBusyStep3: false,
      isBusyOtp: false
    };
  },

  mounted() {},

  beforeCreate() {},

  created() {},

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
    prettyTime () {
      let time = this.timerValue / 60
      let minutes = parseInt(time);
      let minutesShow = minutes > 1 ? `${minutes} minutes` :  `${minutes} minute`;
      let roundedSeconds = Math.round((time - minutes) * 60);
      let secondes = roundedSeconds > 1 ? `${roundedSeconds} seconds` : `${roundedSeconds} second`;
      return minutesShow+" "+secondes
    }
  },

  methods: {
    changeMode(md) {
      this.mode = md;
    },

    gotoLogin(){
      $("#mod-phone-password-change").modal("hide");
      this.$router.push('/growtrade-login')
    },

    phoneStepOneValid(){
      let isvalid = true;
      if(this.user.phone === "" || this.user.phone.length !== 10){
        isvalid = false;
        this.phoneValidation.phoneValid = false;
      }
      return isvalid;
    },

    phoneStepTwoValid(){
      let isvalid = true;
      if(this.verifOTP === "" || this.verifOTP.length !== 6){
        isvalid = false;
        this.verifOTPValid = false;
      }
      return isvalid;
    },

    phoneStepThreeValid(){
      let isvalid = true;
      if(this.user.password === "" || this.user.password.length < 8){
        isvalid = false;
        this.phoneValidation.passwordValid = false;
      }else if(this.user.confirmPassword === "" || this.user.confirmPassword.length < 8){
        isvalid = false;
        this.phoneValidation.confirmPasswordValid = false;
      }else if(this.user.password !== this.user.confirmPassword){
        isvalid = false;
        this.phoneValidation.passwordMatch = false;
      }else{
        return isvalid;
      }
    },

    sendPhoneCode(){
      if(this.phoneStepOneValid()){
        this.isBusyStep1 = true;
        this.$http.post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
        .then(function(res) {
          if(res.body){
            this.resetTime();
            this.start();
            this.isBusyStep1 = false;
            this.phoneValStep = 2;
          }else{
            this.$toast.error("Error resending OTP", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep1 = false;
        });
      }
    },

    validatePhoneCode(){
      this.isBusyStep2 = true;
      if(this.phoneStepTwoValid()){
        this.isBusyStep2 = false;
        this.phoneValStep = 3;
      }else{
        this.isBusyStep2 = false;
      }
    },

    confirmNewPassword(){
      if(this.phoneStepThreeValid()){
        this.isBusyStep3 = true;

        this.$http.post(`/Users/changePassword?phoneNo=234${this.user.phone}&password=${this.user.password}&confirmPasword=${this.user.confirmPassword}&token=${this.verifOTP}`)
        .then(function(res) {
          if(res.body){
            this.resetTime();
            $("#mod-phone-password-change").modal("show");
            this.isBusyStep3 = false;
            this.verifOTPValid = false;
          }else{
            this.$toast.error("Error validating OTP", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep3 = false;
        });
      }
    },

    start () {
				this.timerStarted = true
				if (!this.timer) {
					this.timer = setInterval( () => {
							if (this.timerValue > 0) {
								this.timerValue--
							} else {
								clearInterval(this.timer)
								// this.resetTime()
							}
					}, 1000 )
				}
			},
			stop () {
				this.timerStarted = false
				clearInterval(this.timer)
				this.timer = null
			},
			resetTime () {
				this.stop()
				this.timerValue = 300
			},

    onCompleteOTPHandler(code){
      this.verifOTP = code;
    },

    onChangedOTPHandler(lastEnteredCode){
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteOTPHandler(code){
      this.verifOTP = code;
    },

    resendPhoneOtp(){
      this.isBusyOtp = true;
        this.$http.post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
						.then(function(res) {
							if(res.body){
								this.resetTime();
                this.isBusyOtp = false;
								this.start();
							}else{
								this.$toast.error("Error resending OTP", {
									icon: true,
									rtl: false,
								});
							}
						})
						.catch(function() {
                this.isBusyOtp = false;
						});
		},

    cancel() {
      $("#mod-growtrade-forgotPassword-modal").modal("hide");
    },
    async forgotPassword() {
      this.isBusy = true;

      if (this.validateUser()) {
        this.isBusy = true;
        let body = {
          email: this.email
        }
        await this.$http
          .put(`/Users/resetPassword`, body)
          .then(function(res) {
            $("#mod-growtrade-forgotPassword-modal").modal("show");

            // this.$router.push("/login");
          })
          .catch(function() {
            this.isBusy = false;
          });

        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },

    async resendVerification() {
      this.isBusy = true;
      let body = {
          email: this.email
      }
      await this.$http
        .put(`/Users/resetPassword`, body)
        .then(function(res) {
          this.isBusy = false;
          // this.$router.push("/login");
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    validateUser() {
      this.credentialValidation = {
        email: false,
        emailValid: false,
      };

      let isValid = true;

      if (!this.email) {
        this.credentialValidation.email = true;
        isValid = false;
      }

      if (this.email && !helper.validateEmail(this.email)) {
        this.credentialValidation.emailValid = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
.modal-footer {
  justify-content: center !important;
}

.resend-button {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #38369a;
  background: transparent;
}

.onboarding-body {
  position: relative;
  background-color: #f6f6f6;
  min-width: 100vw;
  min-height: 100vh;
  padding: 48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card {
  width: 524px;
  background-color: #fff;
  padding: 48px;
}

.onboarding-header {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #373737;
  margin: 32px 0 8px;
}

.onboarding-desc {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(24, 25, 31, 0.5);
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 24px;
}

.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input {
  border: none;
  outline: none;
  width: 100%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 32px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.onboarding-helper {
  font-size: 14px;
  line-height: 142.52%;
  color: rgba(24, 25, 31, 0.7);
  margin-top: 8px;
}

.onboarding-helper span {
  font-weight: 700;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}

.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
