<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-manual"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Create New User</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Username</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.user.username.$error },
                ]"
                type="text"
                placeholder="Username"
                autocomplete="off"
                v-model.trim="$v.user.username.$model"
              />
              <div class="invalid-feedback" v-if="!$v.user.username.required">
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.user.username.alphaNumAndDotValidator"
              >
                Username must not include space or special characters!
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Password</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.user.password.$error },
                ]"
                type="password"
                placeholder="Password"
                autocomplete="off"
                v-model.trim="$v.user.password.$model"
              />
              <div class="invalid-feedback" v-if="!$v.user.password.required">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!$v.user.password.minLength">
                Password must have at least
                {{ $v.user.password.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Email address</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="[
                  'form-control',

                  { 'is-invalid': $v.user.emailAddress.$error },
                ]"
                type="email"
                placeholder="Email address of user"
                autocomplete="off"
                v-model.trim="$v.user.emailAddress.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.user.emailAddress.required"
              >
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!$v.user.emailAddress.email">
                Email is invalid.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Phone Number</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.user.phoneNumber.$error },
                ]"
                type="text"
                placeholder="Phone number of user"
                autocomplete="off"
                v-model.trim="$v.user.phoneNumber.$model"
                @blur="confirmPhoneNumberUnique"
              />
              <div
                class="text-danger"
                v-if="phoneNumberUnique === false"
                style="font-size:10px"
              >
                The phone number provided has been used.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.user.phoneNumber.required"
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.user.phoneNumber.validatePhone"
              >
                Number not valid!
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Name</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="['form-control', { 'is-invalid': $v.user.name.$error }]"
                type="text"
                placeholder="Name of user"
                v-model.trim="$v.user.name.$model"
              />
              <div class="invalid-feedback" v-if="!$v.user.name.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Role</label
            >

            <div class="col-12 col-sm-7 col-lg-7">
              <select
                :class="['form-control', { 'is-invalid': $v.user.role.$error }]"
                placeholder="Select an option"
                v-model.trim="$v.user.role.$model"
              >
                <option v-for="r in roles" :key="r.id" :value="r.id">
                  {{ r.text }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.user.role.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Staff Id</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                type="text"
                placeholder="Staff Id of user"
                v-model.trim="user.staffId"
              />
            </div>
          </div>
          <div class="form-group row" v-if="isSales">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Sales Access Id</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.user.salesAccessId.$error },
                ]"
                type="text"
                placeholder="Sales Access Id of user"
                v-model.trim="$v.user.salesAccessId.$model"
              />

              <div
                class="invalid-feedback"
                v-if="!$v.user.salesAccessId.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row" v-if="currentUser.type != 'Manufacturer'">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Branch</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <select
                :class="[
                  'form-control',
                  { 'is-invalid': $v.user.branch.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.user.branch.$model"
              >
                <option v-for="br in branches" :key="br.id" :value="br.id">
                  {{ br.name }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.user.branch.required">
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            style="width: 100px"
            :disabled="isBusyModal || !phoneNumberUnique"
            class="btn btn-primary"
            type="button"
            @click.prevent="createNewUser"
          >
            <span
              v-if="isCreating"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Create </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import { mapActions, mapGetters } from "vuex";
import { required, minLength, helpers, email } from "vuelidate/lib/validators";

const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);
const validatePhone = (value) => helper.validatePhone(value);

export default {
  name: "CreateUser",

  data() {
    return {
      isBusyModal: false,
      isCreating: false,
      branches: [],
      user: {
        username: "",
        password: "",
        phoneNumber: "",
        emailAddress: "",
        name: "",
        role: "",
        staffId: "",
        salesAccessId: "",
        branch: "",
      },
      phoneNumberUnique: null,
    };
  },

  validations() {
    const user = {
      username: { required, alphaNumAndDotValidator },
      password: { required, minLength: minLength(6) },
      emailAddress: { required, email },
      phoneNumber: { required, validatePhone },
      name: { required },
      role: { required },
    };

    if (this.currentUser.type != "Manufacturer") {
      if (this.isSales) {
        return {
          user: {
            ...user,
            branch: { required },
            salesAccessId: { required },
          },
        };
      } else {
        return {
          user: {
            ...user,
            branch: { required },
          },
        };
      }
    } else {
      if (this.isSales) {
        return {
          user: {
            ...user,
            salesAccessId: { required },
          },
        };
      } else {
        return {
          user: user,
        };
      }
    }
  },

  computed: {
    ...mapGetters(["roles"]),
    currentUser: function () {
      return this.$store.state.currentUser;
    },

    isSales: function () {
      const role = this.roles.find((x) => x.id === this.user.role);
      return role ? role.text === "Sales" : false;
    },
  },

  created() {
    this.getRoles();
    this.getBranches();
  },

  methods: {
    ...mapActions(["getRoles"]),

    async confirmPhoneNumberUnique(){
      if(this.user.phoneNumber)
        await this.$http
          .get("/Users/isPhoneNumberUnique/" + `${this.user.phoneNumber}`)
          .then(function (res) {
            this.phoneNumberUnique = res.body;
          })
          .catch(function () {});
    },
    async createNewUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isCreating = true;
        let data = {
          username: this.user.username,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
          emailAddress: this.user.emailAddress,
          type: this.currentUser.type,
          displayName: this.user.name,
          role: this.user.role,
          parentId: this.currentUser.parentId,
          deliveryAddress: "N/A",
          branchId: this.user.branch,
          staffId: this.user.staffId,
          salesAccessId: this.user.salesAccessId,
        };

        try {
          const response = await this.$http.post("/Users/create", data);
          if (response.ok) {
            this.reset();
            this.$toast.success("User created successfully.", {
              icon: true,
              rtl: false,
            });
            this.$emit("onCreateUserComplete");
          }
        } catch (error) {}

        this.isCreating = false;
        this.isBusyModal = false;
      }
    },

    async getBranches() {
      const response = await this.$http.get(
        "/Branches/getListNoPaging?active=" +
          true +
          "&parentId=" +
          this.currentUser.parentId
      );

      if (response.ok) this.branches = response.body;
    },

    reset() {
      this.user.username =
        this.user.password =
        this.user.phoneNumber =
        this.user.emailAddress =
        this.user.name =
        this.user.role =
        this.user.staffId =
        this.user.salesAccessId =
        this.user.branch =
          "";

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>
