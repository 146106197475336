<template>
	<div
		class="modal fade colored-header colored-header-primary"
		id="mod-post-payment-add"
		role="dialog"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-colored">
					<h4 class="modal-title p-1">
						<strong> {{ update ? "Update" : "Post" }} payment</strong>
					</h4>
					<button
						class="close md-close"
						type="button"
						data-dismiss="modal"
						aria-hidden="true"
						@click="cancel"
					>
						<span class="mdi mdi-close"></span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Customer</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<select
								:class="[
									'form-control',

									{ 'is-invalid': $v.payment.customerId.$error },
								]"
								placeholder="Select a customer"
								v-model.trim="$v.payment.customerId.$model"
								:disabled="update"
							>
								<option v-for="c in customers" :key="c.id" :value="c.id">
									{{ c.text }}
								</option>
							</select>
							<div class="invalid-feedback" v-if="!$v.payment.customerId.required">
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Payment Channel</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<select
								:class="[
									'form-control',

									{ 'is-invalid': $v.payment.paymentChannelId.$error },
								]"
								placeholder="Select a payment channel"
								v-model.trim="$v.payment.paymentChannelId.$model"
							>
								<option v-for="br in paymentChannels" :key="br.id" :value="br.id">
									{{ prepareDetail(br) }}
								</option>
							</select>
							<div
								class="invalid-feedback"
								v-if="!$v.payment.paymentChannelId.required"
							>
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Amount</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<input
								:class="[
									'form-control',

									{ 'is-invalid': $v.payment.amount.$error },
								]"
								type="number"
								placeholder="Enter amount"
								v-model.trim="$v.payment.amount.$model"
							/>
							<div class="invalid-feedback" v-if="!$v.payment.amount.required">
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Notes</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<textarea class="form-control" v-model.trim="payment.notes"></textarea>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
							>Payment Date</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<input
								type="date"
								:max="maxDate"
								:class="[
									'form-control',

									{ 'is-invalid': $v.payment.paymentDate.$error },
								]"
								placeholder="Select a date"
								v-model="$v.payment.paymentDate.$model"
							/>
							<div class="invalid-feedback" v-if="!$v.payment.paymentDate.required">
								Field is required.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button
						class="btn btn-secondary md-close"
						type="button"
						data-dismiss="modal"
						@click="cancel"
					>
						Cancel
					</button>
					<button class="btn btn-primary" @click.prevent="postPayment" type="button">
						<span
							v-if="isPosting"
							class="spinner-border spinner-border-sm"
							role="status"
						>
							<i class="sr-only">Loading...</i>
						</span>
						<span v-else> {{ update ? "Update" : "Post" }} </span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required } from "vuelidate/lib/validators";
	import { helper } from "@/helper";
	export default {
		props: ["value", "update"],

		data() {
			return {
				paymentChannels: [],
				customers: [],
				isPosting: false,
				payment: {
					id: "",
					customerId: this.value.ownerId ? this.value.ownerId : "",
					paymentChannelId: this.value.paymentChannelId
						? this.value.paymentChannelId
						: "",
					amount: this.value.paidAmount ? this.value.paidAmount : 0,
					notes: this.value.desc ? this.value.desc : "",
					paymentDate: this.value.paidOn ? this.value.paidOn : "",
				},

				options: {
					useCurrent: true,
					format: "YYYY-MM-DD",
				},
				maxDate: "",
			};
		},

		validations: {
			payment: {
				customerId: { required },
				paymentChannelId: { required },
				amount: { required },
				paymentDate: { required },
			},
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		watch: {
			value: function(value) {
				this.payment = {
					customerId: value.ownerId ? value.ownerId : "",
					paymentChannelId: value.paymentChannelId ? value.paymentChannelId : "",
					amount: value.paidAmount ? value.paidAmount : 0,
					notes: value.desc ? value.desc : "",
					paymentDate: value.paidOn ? value.paidOn : "",
					id: value.id,
				};
			},
		},

		async mounted() {
			await this.getCustomers();
			await this.getPaymentChannels();
			this.maxDate = helper.preventFutureDateSelection();
		},

		methods: {
			setPaymentDate(date) {
				this.payment.paymentDate = date;
				this.$v.payment.paymentDate.$touch();
			},

			async postPayment() {
				this.$v.$touch();
				if (!this.$v.$invalid) {
					this.isPosting = true;
					const data = {
						...this.payment,
					};

					try {
						if (this.update) {
							const response = await this.$http.put(
								`/Payments/${this.payment.id}`,
								data,
							);

							if (response.ok) {
								this.$toast.success("Payment updated succesfully", {
									icon: true,
									rtl: false,
								});
								this.reset();
								this.$emit("onUpdatePaymentComplete");
							}
						} else {
							const response = await this.$http.post("/Payments", data);
							if (response.ok) {
								this.$toast.success("Payment added succesfully", {
									icon: true,
									rtl: false,
								});
								this.reset();
								this.$emit("onPostPaymentComplete");
							}
						}
					} catch (error) {}
					this.isPosting = false;
				}
			},

			prepareDetail(value) {
				const name = value.paymentChannelTypeName;
				let result = "";
				if (name) {
					result += name;

					if (name.toLowerCase() === "bank") {
						result += "/ " + value.bankName + "/ " + value.accountNumber;
					} else if (name.toLowerCase() === "pos") {
						result += "/ " + value.bankName + "/ " + value.terminalId;
					} else if (name.toLowerCase() === "cryptocurrency") {
						result += "/ " + value.bankName + "/ " + value.walletAddress;
					}
				}
				return result;
			},

			async getCustomers() {
				try {
					const response = await this.$http.get(
						"/Users/getItemListOfCustomers/" + this.user.parentId,
					);

					if (response.ok) {
						this.customers = response.body;
					}
				} catch (error) {}
			},

			async getPaymentChannels() {
				try {
					const response = await this.$http.get(
						"/PaymentChannels/getListNoPaging?active=true" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					);

					if (response.ok) this.paymentChannels = response.body;
				} catch (error) {}
			},

			cancel() {
				this.reset();
				this.$emit("onAddPaymentCancel");
			},

			reset() {
				this.payment = {
					customerId: "",
					paymentChannelId: "",
					amount: 0,
					notes: "",
					paymentDate: "",
				};

				setTimeout(() => {
					this.$v.$reset();
				}, 0);
			},
		},
	};
</script>
