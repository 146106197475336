<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				v-if="whichIsActive == 'purchaseOrder'"
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header d-flex justify-content-between">
					<button class="btn btn-success btn-rounded btn-space " @click="filter">
							<i class="feather icon-filter text-white"></i>
							Filter
						</button>
						<router-link
							class="btn btn-primary btn-rounded btn-space"
							:to="{
								path: `salesorders/new-sales-order`,
							}"
						>
							<i class="icon icon-left mdi mdi-plus text-white"></i>
							New Purchase Order
						</router-link>
				</div>
				<div class="card-body" style="background-color: #fff;">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4">
						<div class="row be-datatable-body max-height">
							<div class="col-sm-12 table-responsive">
								<table
									class="table table-fw-widget dataTable no-footer text-nowrap"
								>
									<thead style="background-color: #f4f4f4">
										<tr>
											<th style="font-weight: bold; font-size: 14px;">Creation Date</th>
											<th style="font-weight: bold; font-size: 14px;">PO Number</th>
											<th style="font-weight: bold; font-size: 14px;">Order Total</th>
											<th style="font-weight: bold; font-size: 14px;">Order Status</th>
											<th style="font-weight: bold; font-size: 14px;">Payment Status</th>
											<th style="font-weight: bold; font-size: 14px;">Last Updated</th>
											<th style="font-weight: bold; font-size: 14px;">Actions</th>
										</tr>
									</thead>
									<tbody >
										<tr v-for="po in purchaseOrders" :key="po.id" >
											<td>
												{{ formatDate(po.created) }}
											</td>
											<td>{{ po.orderNumber }}</td>
											<Amount :details="po.details" />
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{
															'badge-success':
																po.status === 'confirmed',
														},
														{
															'badge-danger':
																po.status === 'withdrawn',
														},
														{
															'badge-secondary':
																po.status === 'draft',
														},
														{
															'badge-warning':
																po.status === 'submitted',
														},
														{
															'badge-dark': po.status === 'recalled',
														},
													]"
													>{{
														po.stage ? po.stage.name : po.status
													}}</span
												>
											</td>
											<td class=text-uppercase>
												{{
													po.paymentStatus == null
														? "Nill"
														: po.paymentStatus
												}}
											</td>
											<td>
												{{ formatDate(po.updated) }}
											</td>
											<td>
												<button
													class="
													btn btn-success btn-sm
													dropdown-toggle
													"
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Actions
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<router-link
													v-if="po.status !== 'confirmed' && po.status !== 'CLOSED'"
														class="dropdown-item"
														style="margin-bottom:10px; font-size: 14px;"
														:to="{
															path: `salesorders/${po.id}/edit-sales-order`,
														}"
														>
														Edit Order
													</router-link>

													<div class="dropdown-divider" v-if="
															po.status === 'submitted' ||
															// po.status === 'confirmed' ||
															po.status === 'recalled' ||
															po.status === 'paid' ||
															po.status === 'withdrawn'
															// po.status === 'CLOSED'
														"></div>
													<a
													v-if="
															po.status === 'submitted' ||
															po.status === 'confirmed' ||
															po.status === 'recalled' ||
															po.status === 'paid' ||
															po.status === 'withdrawn' ||
															po.status === 'CLOSED'
														"
														class="dropdown-item"
														href="#"
														style="margin-bottom:10px; font-size: 14px;"
														@click.prevent="viewOrder(po.id)"
													>
														View Order
													</a>
													<div class="dropdown-divider" v-if="po.status === 'draft'"></div>
													<div>
														<SubmitPurchaseOrder
														v-if="po.status === 'draft'"
														:purchaseOrder="po"
														@onComplete="onActionComplete"
														@showAction="onShowAction"
														:ref="po.id"
														/>
													</div>
													<div class="dropdown-divider" v-if="po.status !== 'draft'"></div>
													<a
													v-if="po.status !== 'draft'"
														class="dropdown-item"
														href="#"
														style="margin-bottom:10px; font-size: 14px;"
														@click.prevent="payForOrder(po.id)"
													>
														Pay for Order
													</a>
													<div class="dropdown-divider" v-if="po.status === 'draft' || po.status === 'withdrawn'"></div>
													<div>
														<DeletePurchaseOrder
														v-if="po.status === 'draft' || po.status === 'withdrawn'"
														:purchaseOrder="po"
														@onComplete="onActionComplete"
														@showAction="onShowAction"
														:ref="po.id"
														/>
													</div>
													<div class="dropdown-divider" v-if="po.status === 'submitted'"></div>
													<div>
														<SwitchPurchaseOrder
														v-if="po.status === 'submitted'"
														:purchaseOrder="po"
														@onComplete="onActionComplete"
														@showAction="onShowAction"
														:ref="po.id"
														/>
													</div>
												</div>
											</td>
											<!-- <td>
                        <router-link
                          class="
                            btn btn-success btn-sm btn-rounded btn-space
                            mr-1
                          "
                          v-if="po.status !== 'confirmed' && po.status !== 'CLOSED'"
                          :to="{
                            path: `salesorders/${po.id}/edit-sales-order`,
                          }"
                        >
                          <i class="icon mdi mdi-circle-edit-outline text-white"></i>
                        </router-link>
                        <router-link
                          v-if="
                            po.status === 'submitted' ||
                              po.status === 'confirmed' ||
                              po.status === 'recalled' ||
                              po.status === 'paid' ||
                              po.status === 'withdrawn' ||
                              po.status === 'CLOSED'
                          "
                          class="btn btn-info btn-sm btn-rounded btn-space mr-1"
                          :to="{
                            path: `salesorders/${po.id}/view-sales-order`,
                          }"
                        >
                          View
                        </router-link>
                        <SubmitPurchaseOrder
                          v-if="po.status === 'draft'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                        />
                        <SwitchPurchaseOrder
                          v-if="po.status === 'submitted'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                        />
                        <DeletePurchaseOrder
                          v-if="po.status === 'draft' || po.status === 'withdrawn'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                        />
                      </td> -->
										</tr>
										<tr v-if="!purchaseOrders.length">
											<td colspan="7">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													No Purchase Found.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="pageNumber"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} Purchase order{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="whichIsActive == 'payForOrder'">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<div>
							<h4>
								<b> PO Number - {{ orderNumber }} </b>
							</h4>
							<p>Purchase Order for {{ user.displayName }}</p>
						</div>
						<div>
							<h4 class="text-uppercase">
								<b>Payment Status - {{ paymentStatus ? paymentStatus : 'Nill' }}</b>
							</h4>
						</div>
					</div>
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-hover table-sm">
								<thead class="thead-light">
									<tr>
										<th scope="col">Item #</th>
										<th scope="col">Description</th>
										<th scope="col">Quantity</th>
										<th class="tc-width" scope="col ">Total</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(oD, index) in orderDetails" :key="oD.id">
										<td>{{ index + 1 }}</td>
										<td>{{ oD.description }}</td>
										<td>{{ oD.quantity }}</td>
										<td>&#8358;{{ formatMoney(oD.quantity * oD.price) }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col mt-2 ">
						<div class="row justify-content-end">
							<div class="w-custom">
								<div class="row">
									<div class="col-6 pd-0 text-right">
										<b class="text-right">Sub-total&nbsp;-&nbsp;</b>
									</div>
									<div class="col-6 pd-0">
										<p class=""> &#8358; {{ formatMoney(total) }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-6 pd-0 text-right">
										<b class="text-right">Amount Paid&nbsp;-&nbsp;</b>
									</div>
									<div class="col-6 pd-0">
										<p class="">&#8358; {{ formatMoney(amountPaid) }}</p>
									</div>
								</div>
								<div class="row">
									<div class="col-6 pd-0 text-right">
										<b class="text-right">Balance Payable&nbsp;-&nbsp;</b>
									</div>
									<div class="col-6 pd-0">
										<p class="">&#8358; {{ formatMoney(total - amountPaid) }}</p>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					</div>
				</div>
				<div class="w-100 mb-5">
					<div class="float-right">
						<div class="d-flex justify-content-between ">
							<button
								class="btn float-right mr-2"
								@click="setWhichisActive('purchaseOrder')"
							>
								Cancel
							</button>
							<button
								class="btn btn-primary btn-rounded btn-space float-right"
								@click="setWhichisActive('makePayment')"
							>
								Proceed to Payments
							</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="whichIsActive == 'makePayment'">
				<button class="btn btn-danger mb-2" @click="setWhichisActive('payForOrder')">
					<span class="feather icon-arrow-left text-white"></span>
					Back to order details
				</button>
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<span>
							<h4>
								<b>PO Number - {{ orderNumber }}</b>
							</h4>
							<p>Purchase Order for {{ user.displayName }}</p>
						</span>
					</div>
					<div class="card-body px-3">
						<p>PURCHASE ORDER AMOUNT</p>
						<h1 class="my-2">&#8358;{{ formatMoney(total) }}</h1>
						<div class="w-50">
							<div class="form-check form-check-inline w-50">
								<input
									class="form-check-input"
									name="full"
									type="radio"
									value="full"
									v-model.trim="paymentPattern"
									:disabled="total - amountPaid ==  0"
								/>
								<label class="form-check-label">Make full payment</label>
							</div>
							<div class="form-check form-check-inline">
									<input
										class="form-check-input"
										name="part"
										type="radio"
										id="inlineCheckbox2"
										value="part"
										v-model="paymentPattern"
										:disabled="total - amountPaid ==  0"
									/>
								<label class="form-check-label" for="inlineCheckbox2">Make part payment</label>
							</div>
						</div>
						<div v-if="paymentPattern == 'part'" class="form-group row my-3">
							<label class="col-12 col-sm-3 col-form-label">Enter Amount</label>
							<div class="col-12 col-sm-8 col-lg-4">
								<input
									class="form-control"
									type="number"
									placeholder="Enter Amount"
									v-model="amountToPaid"
									@input="validateInput"
      								:class="{ 'is-invalid': hasError }"
									:disabled="total - amountPaid ==  0"
								/>
								<p v-if="hasError" class="error-message text-danger">Please enter a valid number</p>
							</div>
						</div>
					</div>
				</div>
				<div
					:class="[
						'mb-2',
						'card',
						'card-border-color',
						'card-border-color-primary',
						'card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<span>
							<h4><b>Payment</b></h4>
						</span>
					</div>
					<div class="card-body">
						<p class="text-primary cursor" @click="showLearnMoreModal">
							<span class="feather icon-alert-circle text-primary"></span>
							Learn more about payments
						</p>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label">Payment Method</label>
							<div class="col-xl-4 col-sm-12 col-md-7 col-lg-6">
								<div class="dropdown">
									<button
										class="w-100 p-1 btn btn-outline-primary btn-custom dropdown-toggle"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										{{ paymentMethod }}
									</button>
									<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<div
											class="row p-05 c-cursor"
											@click="selectPaymentMethod('Use Creditline')"
										>
											<p class="m-0">Use Creditline</p>
										</div>
										<div
											class="row p-05 c-cursor"
											@click="selectPaymentMethod('Submit Payment Receipt')"
										>
											<p class="m-0">Submit Payment Receipt</p>
										</div>
										<div class="row p-05 justify-content-between c-cursor">
											<p class="m-0">Buy now pay later</p>
											<span class="text-primary"
												>COMING SOON
												<img
													class="mx-1"
													src="../../../public/assets/icon/history.svg"
											/></span>
										</div>
										<div class="row p-05 justify-content-between c-cursor">
											<p class="m-0">Pay With Transfer</p>
											<span class="text-primary"
												>COMING SOON
												<img
													class="mx-1"
													src="../../../public/assets/icon/history.svg"
											/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					class="btn btn-primary float-right mb-5"
					:disabled="paymentMethod == 'Select Payment Method' && !amountToPaid"
					@click="triggerPayment"
				>
					Next
				</button>
			</div>
			<div v-if="whichIsActive == 'paymentResult'">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<span>
							<h4>
								<b>Transaction Summary</b>
							</h4>
						</span>
					</div>
					<div class="card-body px-3">
						<h4><b>Your payment was successful</b></h4>
						<br>
						<p><span>You paid &nbsp; &nbsp; &nbsp; &nbsp;</span> <b>{{initiattedPayment.amountPaid ? initiattedPayment.amountPaid : 'Nill'}}</b></p>
						<p><span>PO no. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> <b>{{ initiattedPayment.purchaseOrderNumber ? initiattedPayment.purchaseOrderNumber : 'Nill' }}</b></p>
					</div>
				</div>
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<span>
							<h4><b>Payment</b></h4>
						</span>
					</div>
					<div class="card-body  px-3">
						<h4><b>Payment Method - {{ initiattedPayment.paymentMethod ? initiattedPayment.paymentMethod : 'Nill' }}</b></h4>
						<br>
						<div class="timelinex  block">
							<div class="tl-item">
								<div :class="initiateStagetimelineClass">
								</div>
								<div class="tl-content">
									<span><b>{{ formatDate(initiattedPayment.initiatedDate) ? formatDate(initiattedPayment.initiatedDate) : 'Nill' }}</b></span>
									<div class="tl-date text-muted "><p>You Initiated a payment of <span><b>{{ initiattedPayment.amountPaid ? initiattedPayment.amountPaid : 'Nill' }}</b></span></p></div>
								</div>
							</div>
							<div  class="tl-item"> 
								<!-- v-if="initiattedPayment.recievedDate != null" -->
								<div :class="confirmedStagetimelineClass">
								</div>
								<div class="tl-content">
									<span><b>{{ formatDate(initiattedPayment.recievedDate) ? formatDate(initiattedPayment.recievedDate) : 'Nill' }}</b></span>
									<div class="tl-date text-muted ">
										<p>We recieved your payment <span><b>{{ initiattedPayment.amountPaid ? initiattedPayment.amountPaid : 'Nill' }}</b></span></p>
									</div>
								</div>
							</div>
							<div class="tl-item">
								<!-- v-if="initiattedPayment.recievedDate != null" -->
								<div :class="receivedStagetimelineClass">
								</div>
								<div class="tl-content">
									<span><b>{{ formatDate(initiattedPayment.confirmdDate) ? formatDate(initiattedPayment.confirmdDate) : 'Nill' }}</b></span>
									<div class="tl-date text-muted "><p>Your Payment was confirmed successfully</p></div>
								</div>
							</div>
							</div>
						</div>
					</div>
				<button
					class="btn btn-primary float-right mb-5"
					@click="reloadPage"
				>
					Done
				</button>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary "
			id="submitPaymentreceiptmodal"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Submit Payment Receipt</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Amount</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									disabled
									v-model="paymentReceipt.amount"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Payment Date</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="date"
									placeholder="Enter Date"
									v-model="paymentReceipt.date"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Account number paid to</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter Account Number"
									v-model="paymentReceipt.accountNumber"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Bank</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="paymentReceipt.bank"
								>
									<option v-for="b in banks" :key="b" :value="b">{{ b }}</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Account Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter Account Name"
									v-model="paymentReceipt.accountName"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Depositors Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter Depositors Name"
									v-model="paymentReceipt.depositorsName"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									placeholder="Enter Narration"
									v-model="paymentReceipt.narration"
								></textarea>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusy == true"
							class="btn btn-primary"
							type="button"
							@click.prevent="recordPayment"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Record Payment</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary "
			id="submitCreditLinemodal"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Credit Line</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Amount</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									disabled
									v-model="creditLine.amount"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusy == true"
							class="btn btn-primary"
							type="button"
							@click.prevent="recordPaymentCreditline"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Confirm Payment</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary "
			id="LearnMoreModal"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable xl-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title p-1 text-primary"><strong>Payments</strong></h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<!-- <div class="py-1">
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ut eius ad corrupti labore impedit a ducimus, libero, sunt omnis distinctio excepturi deleniti. Sapiente deserunt minus ullam atque velit ratione doloremque officia illum esse minima natus, officiis, laborum, consectetur veniam magni a at quae fugiat. Quo quod corrupti veritatis architecto quidem, itaque a qui vel obcaecati. Quidem eius architecto neque laudantium, quo repellendus quis et libero eveniet facilis cumque maxime maiores dicta exercitationem ab voluptatem odio alias reprehenderit quibusdam. Iusto repellendus quisquam velit delectus expedita distinctio alias quas necessitatibus omnis iste, rerum qui repellat voluptatibus voluptas neque dolore quasi ducimus!</p>
						</div>
						<div class="dropdown-divider"></div>
						<div>
							<h6 class="py-1"><strong>PAY WITH TRANSFER</strong></h6>
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores, ut eius ad corrupti labore impedit a ducimus, libero, sunt omnis distinctio excepturi deleniti. Sapiente deserunt minus ullam atque velit ratione doloremque officia illum esse minima natus, officiis, laborum, consectetur veniam magni a at quae fugiat. Quo quod corrupti veritatis architecto quidem, itaque a qui vel obcaecati. Quidem eius architecto</p>
						</div>
						<div class="dropdown-divider"></div> -->
						<div>
							<h6 class="py-1"> <strong>SUBMIT PAYMENT RECEIPT</strong></h6>
							<p>Use this option to submit the details of the transfer or deposit receipt of payments made for this order outside of the platform. Just so you know, you must have the receipt details of your payment..</p>
						</div>
						<div class="dropdown-divider"></div>
						<div>
							<h6 class="py-1"> <strong>USE CREDITLINE</strong> </h6>
							<p>Use this option to submit a request to make payments for your order from your "existing" credit facility with your supplier. Please note that your supplier may choose to accept or reject the request based on pre-existing credit/trade agreements between both of you.</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ActionStatusModal
			:action="action"
			:purchaseNumber="purchaseNumber"
			@onDelete="perfomDelete"
			@onWithdraw="performWithdraw"
			@onSubmit="performSubmit"
		/>
		<FilterPurchaseOrder @onFilter="performFiltering" />
	</div>
</template>

<script>
	import { helper } from "@/helper";
	import moment from "moment";

	import Pagination from "../../components/Pagination.vue";
	import ActionStatusModal from "../../components/PurchaseOrder/ActionStatusModal";
	import DeletePurchaseOrder from "../../components/PurchaseOrder/DeletePurchaseOrder";
	import FilterPurchaseOrder from "../../components/PurchaseOrder/FilterPurchaseOrder";
	import SubmitPurchaseOrder from "../../components/PurchaseOrder/SubmitPurchaseOrder";
	import SwitchPurchaseOrder from "../../components/PurchaseOrder/SwitchPurchaseOrder";
	import Amount from "../../components/SettingsPurchaseOrder/Amount";

	export default {
		name: "purchaseOrder",
		components: {
			DeletePurchaseOrder,
			SwitchPurchaseOrder,
			SubmitPurchaseOrder,
			ActionStatusModal,
			FilterPurchaseOrder,
			Amount,
			Pagination,
		},

		created() {
			this.getPurchaseOrders();
			this.getOrderStages();
			this.setWhichisActive("purchaseOrder");
		},

		
		data() {
			return {
				isBusy: false,
				count: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				purchaseOrders: [],
				status: "",
				orderStages: [],
				orderNumber: "",
				purchaseNumber: "",
				action: "",
				actionOwner: "",
				whichIsActive: "",
				orderDetails: [],
				orderID: "",
				paymentStatus: "",
				paymentPattern: "",
				paymentMethod: "Select Payment Method",
				paymentReceipt: {
					amount: null,
					date: "",
					accountNumber: "",
					bank: "",
					accountName: "",
					depositorsName: "",
					narration: "",
				},
				creditLine: {
					amount: null,
				},
				banks: helper.getBanks(),
				amountToPaid: null,
				initiattedPayment:{
					amountPaid:null,
					paymentMethod:'',
					initiatedDate:null,
					confirmdDate:null,
					purchaseOrderNumber:null,
					recievedDate:null,
					transactionStatus:''
				},
				initiateStagetimelineClass:'',
				confirmedStagetimelineClass:'',
				receivedStagetimelineClass:'',
				innerCircle:'tl-dot-inner',
				paymentHistory: [],
				inputValue: '',
      			hasError: false,
				amountPaid:null,
				balance:null,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			total: function() {
				let amount = 0;
				this.orderDetails.forEach(i => (amount += i.quantity * i.price));
				return amount;
			},
		},
		

		methods: {
			checkPaymentStatus(x, y, z){
				if (x === true){
					this.initiateStagetimelineClass = 'tl-dot'
				}
				if (x === false){
					this.initiateStagetimelineClass = 'tl-dot-danger'
				}
				if (x === null){
					this.initiateStagetimelineClass = 'tl-dot-gray'
				}

				if (y === true){
					this.confirmedStagetimelineClass = 'tl-dot'
				}
				if (y === false){
					this.confirmedStagetimelineClass = 'tl-dot-danger'
				}
				if (y === null){
					this.confirmedStagetimelineClass = 'tl-dot-gray'
				}

				if (z === true){
					this.receivedStagetimelineClass = 'tl-dot'
				}
				if (z === false){
					this.receivedStagetimelineClass = 'tl-dot-danger'
				}
				if (z === null){
					this.receivedStagetimelineClass = 'tl-dot-gray'
				}
			},
			validateInput() {
				this.hasError = /[^0-9]/g.test(this.inputValue);
				this.$emit('input', this.hasError ? null : parseFloat(this.inputValue));
			},
			showLearnMoreModal(){
				$("#LearnMoreModal").modal("show");
			},

			formatDate(date) {
				return moment(date).format("ll");
			},

			reloadPage() {
				window.location.reload();
			},

			async getOrderStages() {
				try {
					const response = await this.$http.get(
						"/PurchaseOrders/orderStage/getList?active=true",
					);

					if (response.ok && response.status === 200) {
						this.orderStages = response.body.sort(
							(firstItem, secondItem) => firstItem.stage - secondItem.stage,
						);
					}
				} catch (error) {}
			},

			triggerPayment() {
				if (this.paymentPattern == "full") {
					this.creditLine.amount = this.balance;
					this.paymentReceipt.amount = this.balance;
				} else {
					this.creditLine.amount = this.amountToPaid;
					this.paymentReceipt.amount = this.amountToPaid;
				}
				if (this.paymentMethod == "Use Creditline") {
					$("#submitCreditLinemodal").modal("show");
				}
				if (this.paymentMethod == "Submit Payment Receipt") {
					$("#submitPaymentreceiptmodal").modal("show");
				}
			},

			async recordPayment() {
				let data = {
					orderId: this.orderID,
					paymentMethod: this.paymentMethod,
					amountPaid: this.paymentReceipt.amount,
					totalAmount: this.total,
					paymentDate: this.paymentReceipt.date,
					accountNumber: this.paymentReceipt.accountNumber,
					accountName: this.paymentReceipt.accountName,
					bankName: this.paymentReceipt.bank,
					depositorName: this.paymentReceipt.depositorsName,
					narration: this.paymentReceipt.narration,
				};
				this.isBusy = true;
				try {
					const response = await this.$http.post("/PurchaseOrders/paymentReceipt", data);
					if (response.ok && response.status === 200) {
						this.$toast.success(response.message, {
							icon: false,
							rtl: false,
						});
					}
					$("#submitPaymentreceiptmodal").modal("hide");
					this.isBusy = false;
					this.initiattedPayment.amountPaid = response.data.amountPaid,
					this.initiattedPayment.paymentMethod = response.data.paymentMethod,
					this.initiattedPayment.initiatedDate = response.data.initiateStage.date,
					this.initiattedPayment.confirmedDate = response.data.confirmedStage.date,
					this.initiattedPayment.purchaseOrderNumber = response.data.purchaseOrderNumber,
					this.initiattedPayment.recievedDate = response.data.receivedStage.date,
					this.initiattedPayment.transactionStatus = response.data.transactionStatus
					this.checkPaymentStatus (response.data.initiateStage.status, response.data.confirmedStage.status, response.data.receivedStage.status)
					this.setWhichisActive("paymentResult")
				} catch (error) {
					this.isBusy = false;
					$("#submitPaymentreceiptmodal").modal("hide");
				}
			},

			async recordPaymentCreditline() {
				let data = {
					orderId: this.orderID,
					paymentMethod: this.paymentMethod,
					amountPaid: this.creditLine.amount,
					totalAmount: this.total,
				};
				this.isBusy = true;
				try {
					const response = await this.$http.post("/PurchaseOrders/creditLine", data);
					if (response.ok && response.status === 200) {
						this.$toast.success(response.message, {
							icon: false,
							rtl: false,
						});
						this.initiattedPayment.amountPaid = response.data.amountPaid,
						this.initiattedPayment.paymentMethod = response.data.paymentMethod,
						this.initiattedPayment.initiatedDate = response.data.initiateStage.date,
						this.initiattedPayment.confirmedDate = response.data.confirmedStage.date,
						this.initiattedPayment.purchaseOrderNumber = response.data.purchaseOrderNumber,
						this.initiattedPayment.recievedDate = response.data.receivedStage.date,
						this.initiattedPayment.transactionStatus = response.data.transactionStatus
						this.checkPaymentStatus (response.data.initiateStage.status, response.data.confirmedStage.status, response.data.receivedStage.status)
						this.setWhichisActive("paymentResult")
					}
					$("#submitCreditLinemodal").modal("hide");	
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
					$("#submitCreditLinemodal").modal("hide");
				}
			},

			setWhichisActive(stage) {
				this.whichIsActive = stage;
			},

			selectPaymentMethod(selectedMethod) {
				this.paymentMethod = selectedMethod;
			},

			async payForOrder(orderID) {
				this.orderID = orderID;
				this.isBusy = true;
				try {
					const response = await this.$http.get("/PurchaseOrders/view/" + orderID);
					if (response.ok) {
						this.details = response.body;
						this.orderDetails = response.body.details;
						this.orderNumber = response.body.orderNumber;
						this.paymentStatus = response.body.paymentStatus
						this.paymentHistory = response.body.paymentHistory;
						this.balance = response.body.amountUnpaid;
						this.amountPaid = response.body.amountPaid;
						this.paymentHistory = response.body.paymentHistory
					}
					this.setWhichisActive("payForOrder");
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
			},
			filter() {
				// eslint-disable-next-line no-undef
				this.pageNumber = 0;
				$("#mod-purchase-filter").modal("show");
			},

			onActionComplete() {
				this.getPurchaseOrders();
			},

			onShowAction(actionDetails) {
				this.purchaseNumber = actionDetails.purchaseNumber;
				this.action = actionDetails.action;
				this.actionOwner = actionDetails.owner;

				// eslint-disable-next-line no-undef
				$("#mod-action").modal("show");
			},

			hideAction() {
				// eslint-disable-next-line no-undef
				$("#mod-action").modal("hide");
			},

			editOrder(x) {
				console.log(x);
			},

			viewOrder(id) {
				this.$router.push("/salesorders/" + id + "/view-sales-order");
			},

			processOrder() {
				console.log("Edit");
			},

			cancelOrder() {
				console.log("Edit");
			},

			deleteOrder() {
				console.log("Edit");
			},

			async performFiltering(filteringOptions) {
				this.orderNumber = filteringOptions.orderNumber;
				this.status = filteringOptions.status;

				this.getPurchaseOrders();
				// eslint-disable-next-line no-undef
				$("#mod-purchase-filter").modal("hide");
			},

			async performSubmit() {
				this.hideAction();
				const component = this.$refs[this.actionOwner][0];
				component.switchPurchaseOrder();
				this.resetAction();
			},

			async performWithdraw() {
				this.hideAction();
				const component = this.$refs[this.actionOwner][0];
				component.withdrawPurchase();
				this.resetAction();
			},

			async perfomDelete() {
				this.hideAction();
				const component = this.$refs[this.actionOwner][1];
				component.deletePurchaseOrder();
				this.resetAction();
			},

			resetAction() {
				this.purchaseNumber = "";
				this.action = "";
				this.actionOwner = "";
			},

			async getPurchaseOrders() {
				try {
					this.isBusy = true;

					const response = await this.$http.get(
						"/PurchaseOrders/getList?OrderNumber=" +
							this.orderNumber +
							"&Status=" +
							this.status +
							"&PageNumber=" +
							this.pageNumber +
							"&PageSize=" +
							this.pageSize,
					);

					if (response.ok && response.status === 200) {
						this.purchaseOrders = response.body.data;
						this.pageNumber = response.body.pageNumber;
						this.pageSize = response.body.pageSize;
						this.count = response.body.count;
						this.pageCount = Math.ceil(response.body.count / response.body.pageSize);
						this.hasPrev = response.body.hasPrev;
						this.hasNext = response.body.hasNext;
						this.isBusy = false;
					}
				} catch (error) {
					this.isBusy = false;
				}
			},

			formatDate(date) {
				if (date) return moment(date).format("lll");
				else return "";
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			prev() {
				this.pageNumber -= 1;
				this.getPurchaseOrders();
			},

			next() {
				this.pageNumber += 1;
				this.getPurchaseOrders();
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getPurchaseOrders();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getPurchaseOrders();
			},
		},
	};
</script>
<style scoped>
	.tab button:hover {
		/* background-color: #766cea; */
		color: #766cea !important;
	}

	.lg-modal {
		max-width: 600px !important;
		width: 100%;
	}

	.xl-modal {
		max-width: 700px !important;
		width: 100%;
	}

	/* Create an active/current tablink class */
	.tab button.active {
		border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}
	input[type="radio"] {
		width: 15px;
		height: 15px;
		accent-color: #766cea !important;
	}

	input[type="radio"]:checked:after {
		width: 15px;
		height: 15px;
		accent-color: #766cea;
	}
	.btn-custom {
		text-align: left;
		color: #949494;
		background-color: transparent;
		border-color: #e6e6e6;
	}
	.btn-outline-primary:not(:disabled):not(.disabled):active,
	.btn-outline-primary:not(:disabled):not(.disabled).active,
	.show > .btn-outline-primary.dropdown-toggle {
		text-align: left;
		color: #949494;
		background-color: transparent;
		border-color: #e6e6e6;
	}
	.dropdown .dropdown-menu {
		padding: 20px;
		min-width: 100%;
	}
	.p-05 {
		padding: 0.7rem;
	}

	.c-cursor {
		cursor: pointer;
	}

	.timelinex {
		position: relative;
		padding: 0;
		margin: 0
	}

	.tl-item {
		border-radius: 3px;
		position: relative;
		display: -ms-flexbox;
		display: flex
	}

	.tl-item>* {
		padding: 10px;
		height: 60px
	}

	.tl-item:last-child .tl-dot:after {
		display: none
	}

	.tl-dot {
		position: relative;
		border-color: rgba(160, 175, 185, .15)
	}

	.tl-dot-danger {
		position: relative;
		border-color: rgba(237, 9, 9)
	}

	.tl-dot-warning {
		position: relative;
		border-color: #a9a300;
		
	}

	.tl-dot-success {
		position: relative;
		border-color: #77d040
	}

	.tl-dot:after,
	.tl-dot:before {
		content: '';
		position: absolute;
		border-color: inherit;
		border-width: 2px;
		border-style: solid;
		border-radius: 50%;
		background-color: #77d040;
		border-color: #77d040;
		width: 20px;
		height: 20px;
		top: 14px;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%)
	}

	.tl-dot-danger:after,
	.tl-dot-danger:before {
		content: '';
		position: absolute;
		border-color: inherit;
		border-width: 2px;
		border-style: solid;
		border-radius: 50%;
		background-color: #e70505;
		border-color: #e70505;
		width: 20px;
		height: 20px;
		top: 14px;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%)
	}

	.tl-dot-danger:after {
		width: 0;
		height: 30px;
		top: 40px;
		bottom: -14px;
		border-right-width: 0;
		border-top-width: 0;
		border-bottom-width: 0;
		border-radius: 0
	}

	.tl-dot-gray:after,
	.tl-dot-gray:before {
		content: '';
		position: absolute;
		border-color: inherit;
		border-width: 2px;
		border-style: solid;
		border-radius: 50%;
		background-color:#9d9d9d;
		border-color: #9d9d9d;
		width: 20px;
		height: 20px;
		top: 14px;
		bottom: 40px;
		/* left: 50%; */
		transform: translateX(-50%)
	}

	.tl-dot-gray:after {
		width: 0;
		height: 30px;
		top: 40px;
		bottom: -14px;
		border-right-width: 0;
		border-top-width: 0;
		border-bottom-width: 0;
		border-radius: 0
	}

	.tl-dot-gray:last-child::after {
		display: none;
	}

	.tl-item:last-child .tl-dot:after{
		display: none;
		height: 0;
		width: 0;
		
	}

	.tl-item:last-child .tl-dot-danger:after{
		display: none;
		height: 0;
		width: 0;
	}

	.tl-item:last-child .tl-dot-gray:after{
		display: none;
		height: 0;
		width: 0;
	}

	.tl-dot-danger:last-child .tl-dot-danger:after {
		display: none
	}
	.tl-dot:last-child .tl-dot:after {
		display: none
	}

	.tl-dot:after {
		width: 0;
		height: 30px;
		top: 40px;
		bottom: -14px;
		border-right-width: 0;
		border-top-width: 0;
		border-bottom-width: 0;
		border-radius: 0
	}

	.tl-content p:last-child {
		margin-bottom: 0
	}

	.tl-date {
		font-size: .85em;
	}
	.b-successful {
    	border-color: #77d040!important;
	}
	.b-pending {
    	border-color: #e1cb0a!important;
	}
	.b-failed {
    	border-color: #e70505!important;
		;
	}
	.cursor{
		cursor: pointer;
	}
	.w-custom {
		width: 22% !important;
		font-size: 16px;
		padding: 0;
	}
	.pd-0 {
		padding: 0;
	}
	.tc-width{
		width: 12%;
	}

	@media (min-width: 320px) and (max-width:480px) {
		.w-custom {
    		width: 92% !important;
			font-size: 14px;
			padding: 0;
		}
		.w-50{
			width:100% !important;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 36%;
		}
	}

	@media (min-width: 481px) and (max-width:600px) {
		.w-custom {
    		width: 66% !important;
			font-size: 14px;
			padding: 0;
		}
		.w-50{
			width:100% !important;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 36%;
		}
	}

	@media (min-width: 601px) and (max-width:768px) {
		.w-custom {
    		width: 52% !important;
			font-size: 16px;
			padding: 0;
		}

		.w-50{
			width:100% !important;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 28%;
		}
	}

	@media (min-width: 769px) and (max-width:1024px) {
		.w-custom {
    		width: 40% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 22%;
		}
	}

	@media (min-width: 1025px) and (max-width:1200px) {
		.w-custom {
    		width: 33% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 18%;
		}
	}
	@media (min-width: 1201px) and (max-width:1600px) {
		.w-custom {
    		width: 35% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 19%;
		}
	}
</style>
