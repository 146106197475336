<template>
	<div>
		<div class="text-center">
			<div class="">
				<img src="/assets/img/spinner.gif" alt="logo" width="175" />
			</div>
			<p>Wait a moment while we load your page</p>
		</div>
	</div>
</template>

<script>
	export default {};
</script>
