var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-wrapper",staticStyle:{"height":"100vh","width":"100%","display":"flex","align-items":"center","justify-content":"center","padding":"auto"}},[_c('div',{staticClass:"card-wrapper"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"card card-border-color card-border-color-primary",staticStyle:{"width":"100%"}},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.username.$model),expression:"$v.user.username.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.username.$error } ],attrs:{"id":"username","type":"text","placeholder":"Username","autocomplete":"off"},domProps:{"value":(_vm.$v.user.username.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.username, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.user.username.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e(),(!_vm.$v.user.username.alphaNumAndDotValidator)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username must not include space or special characters! ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.displayName.$model),expression:"$v.user.displayName.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.displayName.$error } ],attrs:{"type":"text","placeholder":"Name"},domProps:{"value":(_vm.$v.user.displayName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.displayName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.displayName.required &&
                      _vm.$v.user.displayName.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.emailAddress.$model),expression:"$v.user.emailAddress.$model",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Email Address"},domProps:{"value":(_vm.$v.user.emailAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.emailAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.emailAddress.required &&
                      _vm.$v.user.emailAddress.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e()]),(_vm.user.deliveryAddress != 'N/A')?_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.deliveryAddress.$model),expression:"$v.user.deliveryAddress.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.deliveryAddress.$error } ],attrs:{"placeholder":"Delivery Address"},domProps:{"value":(_vm.$v.user.deliveryAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.deliveryAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.deliveryAddress.required &&
                      _vm.$v.user.deliveryAddress.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.password.$model),expression:"$v.user.password.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.password.$error } ],attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.$v.user.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.password.required && _vm.$v.user.password.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e(),(!_vm.$v.user.password.minLength)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Enter a password greater than or equal to 6 characters. ")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.repeatPassword.$model),expression:"$v.user.repeatPassword.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.repeatPassword.$error } ],attrs:{"type":"password","placeholder":"Confirm Password"},domProps:{"value":(_vm.$v.user.repeatPassword.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.repeatPassword, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.repeatPassword.required &&
                      _vm.$v.user.repeatPassword.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e(),(!_vm.$v.user.repeatPassword.sameAsPassword)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Passwords must be identical. ")])]):_vm._e()]),_c('div',{staticClass:"form-group login-submit"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isBusy},on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[(_vm.isBusy)?_c('span',[_vm._v("Busy...")]):_c('span',[_vm._v("Create account")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"text-align":"right"}},[_c('span',[_vm._v("Already have an account? ")]),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Login")])],1)])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticStyle:{"width":"100%","display":"flex","flex-wrap":"wrap","flex-direction":"row","justify-content":"space-between"}},[_c('div',[_c('img',{staticClass:"logo-img",attrs:{"src":"/assets/img/logo-xx.png","alt":"logo","width":"200","height":"60"}})])]),_c('div',{staticClass:"mt-2"},[_c('h4',[_vm._v("Register Your Account")]),_c('span',{staticClass:"splash-description"},[_vm._v("Please enter your user information to create your account. ")])])])}]

export { render, staticRenderFns }