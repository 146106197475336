<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card card-border-color card-border-color-primary">
                    <div class="card-header">&nbsp;</div>
                    <div class="row match-height">

                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-shopping-cart"></span> Sales</h4>

                                    <ul class="list-group notification">
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-sales-by-product">Sales By SKUs</router-link> </li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-sales-by-distributor-by-sku">Sales By Distributor By
                                                SKUs</router-link> </li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-purchase-process-lead-time">Avg Process Lead Time (
                                                Order Stages)</router-link> </li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-purchase-process-lead-time-sales-manager">Avg
                                                Process Lead Time (
                                                Sales Mgr)</router-link> </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-shopping-cart"></span> Orders </h4>

                                    <ul class="list-group notification">
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-open-purchases">Open Purchases</router-link></li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-open-purchases-summary">Open Purchases
                                                Summary</router-link></li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-total-orders-by-distributor">Total Orders By
                                                Distributor</router-link></li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                                to="/manufacturer-total-orders-by-state">Total Orders By
                                                State</router-link></li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                                to="/manufacturer-total-orders-by-status">Total Orders By
                                                Status</router-link></li>

                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-list"></span>Expense</h4>

                                    <ul class="list-group notification">
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/manufacturer-expenses-by-distributor">Expense By
                                                Distributor</router-link> </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-users"></span> Retailer</h4>

                                    <ul class="list-group notification">
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                                to="/manufacturer-retailer-report"> Retailers Report </router-link></li>
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                                to="/manufacturer-retailers-by-aging-credit">Retailers By Aging
                                                Credit</router-link></li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-12">
                            <!-- <div class="card"> -->
                            <div class="card-body">
                                <h4> <span class="feather icon-box"></span> Product</h4>

                                <ul class="list-group notification">
                                    <li class="list-group-item"><span class="feather icon-star"
                                            style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                            to="/manufacturer-product-performance">Product Performance</router-link>
                                    </li>
                                    <li class="list-group-item"><span class="feather icon-star"
                                            style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                            to="/manufacturer-product-performance-distributor">Product Performance
                                            (Distributor)</router-link></li>
                                    <li class="list-group-item"><span class="feather icon-star"
                                            style="color:lightgray">&nbsp;&nbsp;</span> <router-link
                                            to="/manufacturer-product-performance-state"> Product Performance By
                                            State</router-link></li>

                                </ul>

                            </div>
                            <!-- </div> -->
                        </div>

                        <div v-if="hasPermission('Report_View_GCL')" class="col-md-4 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4> <span class="feather icon-download"></span>Download</h4>

                                    <ul class="list-group notification">
                                        <li class="list-group-item"><span class="feather icon-star"
                                                style="color:lightgray">&nbsp;&nbsp;</span><router-link
                                                to="/enterprise-s4hana-po-export">S4Hana Export
                                                (Purchases)</router-link> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>


                    <h3 class="text-center" style="padding-bottom: 100px"></h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "reports",
};
</script>