<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{'be-loading-active': isBusy},
				]"
			>
				<div class="card-header">
					<div class="col-lg-10">
						<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
							<span class="feather icon-filter text-white"></span> Filter
						</button>
					</div>
					<div class="col-lg-2">
					<!--	<button
							class="btn btn-primary btn-rounded btn-space"
							@click="csvExport(csvData)"
						>
							<span class="feather icon-download text-white"></span> Export View
						</button>-->
						<span>&nbsp;</span>
					<!--	<button
							class="btn btn-primary btn-rounded btn-space"
							@click="csvExport(csvDataAll)"
						>
							<span class="feather icon-download text-white"></span> Export All
						</button>-->
						
						<button
							class="btn btn-primary btn-rounded btn-space"
							@click="excelData(exportItemsAll)"
						>
							<span class="feather icon-download text-white"></span> Export All (Excel)
						</button>
					</div>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table class="table table-fw-widget dataTable no-footer">
									<thead>
										<tr>
											<th style="width:5%;">Line Count</th>
											<th style="width:5%;">Sales DocumentType</th>
											<th style="width:5%;">Sales Organization</th>
											<th style="width:5%;">Distribution Channel</th>
											<th style="width:5%;">Division</th>
											<th style="width:5%;">Order Reason</th>
											<th style="width:5%;">customer Reference</th>
											<th style="width:5%;">Material</th>
											<th style="width:5%;">Order Quantity</th>
											<th style="width:5%;">Plant</th>
											<th style="width:5%;">Batch</th>
											<th style="width:5%;">Sold to Party</th>
											<th style="width:5%;">Bill to Party</th>
											<th style="width:5%;">Payer</th>
											<th style="width:5%;">Ship to Party</th>
											<th style="width:5%;">Sales Agent 1</th>
											<th style="width:5%;">Sales Agent 2</th>
											<th style="width:5%;">Sales Agent 3</th>
											<th style="width:5%;">Sales Agent 4</th>
											<th style="width:5%;">Sales Agent 5</th>
											<th style="width:5%;">Sales Employee</th>
											<th style="width:10%;"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in ReportItems" :key="item.rowNum">
											<td>{{ item.lineCount }}</td>
											<td>{{ item.salesDocumentType }}</td>
											<td>{{ item.salesOrganization }}</td>
											<td>{{ item.distributionChannel }}</td>
											<td>{{ item.division }}</td>
											<td>{{ item.orderReason }}</td>
											<td>{{ item.customerReference }}</td>
											<td>{{ item.material }}</td>
											<td>{{ item.orderQuantity }}</td>
											<td>{{ item.plant }}</td>
											<td>{{ item.batch}}</td>
											<td>{{ item.soldToParty}}</td>
											<td>{{ item.billToParty}}</td>
											<td>{{ item.payer}}</td>
											<td>{{ item.shipToParty}}</td>
											<td>{{ item.salesAgent1}}</td>
											<td>{{ item.salesAgent2}}</td>
											<td>{{ item.salesAgent3}}</td>
											<td>{{ item.salesAgent4}}</td>
											<td>{{ item.salesAgent5}}</td>
											<td>{{ item.salesEmployee}}</td>
											<td></td>
										</tr>
										<tr v-if="!ReportItems.length">
											<td colspan="6">
												<div class="text-center">No matching records</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} item{{ count != 1 ? "s" : "" }}
								</div>
							</div>
							<div class="col-sm-7">
								<div class="dataTables_paginate paging_simple_numbers">
									<ul class="pagination">
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{disabled: !hasPrev},
											]"
										>
											<a href="#" class="page-link" @click.prevent="prev"
												>Previous</a
											>
										</li>
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{disabled: !hasNext},
											]"
										>
											<a href="#" class="page-link" @click.prevent="next"
												>Next</a
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateStart"
								/>
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateEnd"
								/>
							</div>
						</div>
				
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px;"
							class="btn btn-primary md-close"
							type="button"
							@click="getEnterpriseS4hanaExportReport"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import {helper} from "@/helper";
	import Select2 from "@/components/Select2";
	import {exportExcelData } from "@/exceldata";

	export default {
		name: "getEnterpriseS4hanaExportReport",
		components: {
			Select2,
		},
		created() {
			this.getEnterpriseS4hanaExportReport();
			this.getEnterpriseS4hanaExportReportExportAllItems();
		},
		data() {
			return {
				filter: {
					qry: "",
					status: "0",
					dateStart: moment()
						.subtract(1, "years")
						.format("YYYY-MM-DD"),
					dateEnd: moment().format("YYYY-MM-DD"),
				},
				isBusy: false,
				isBusyModal: false,
				ReportItems: [],
				ExportReportItemsAll: [],
				count: 0,
				page: 1,
				pageSize: 10,
				pageSizeExport: 99999999,
				hasNext: false,
				hasPrev: false,
				options: {
					format: "YYYY-MM-DD",
					icons: {
						time: "mdi mdi-time",
						date: "mdi mdi-calendar",
						up: "mdi mdi-chevron-up",
						down: "mdi mdi-chevron-down",
						previous: "mdi mdi-chevron-left",
						next: "mdi mdi-chevron-right",
						today: "mdi mdi-screenshot",
						clear: "mdi mdi-trash",
						close: "mdi mdi-remove",
					},
				},
				local: {
					dow: 0, // Sunday is the first day of the week
					hourTip: "Select Hour", // tip of select hour
					minuteTip: "Select Minute", // tip of select minute
					secondTip: "Select Second", // tip of select second
					yearSuffix: "", // suffix of head year
					monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
						"_",
					), // months of head
					months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
					weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
					cancelTip: "cancel",
					submitTip: "confirm",
				},
				maxDate: "",
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			csvData() {
				return this.exportItems.map(item => ({
					...item,
				}));
			},
			exportItems() {
				return this.ReportItems.map(function(item) {
					return {
						
						"Line Count": item.lineCount ,
						"Sales DocumentType": item.salesDocumentType,
						"Sales Organization": item.salesOrganization,
						"Distribution Channel": item.distributionChannel,
						"Division": item.division,
						"Order Reason": item.orderReason,
						"Customer Reference": item.customerReference,
						"Material": item.material,
                        "Order Quantity": item.orderQuantity,
                        "Plant": item.plant,
                        "Batch": item.batch,
                        "Sold to Party": item.soldToParty,
						"Bill to Party": item.billToParty,
                        "Bill to Payer": item.payer,
						"Ship to Party": item.shipToParty,
                        "Sales Agent 1": item.salesAgent1,
						"Sales Agent 2": item.salesAgent2,
						"Sales Agent 3": item.salesAgent3,
						"Sales Agent 4": item.salesAgent4,
						"Sales Agent 5": item.salesAgent5,
						"Sales Employee": item.salesEmployee,

					};
				});
			},
			csvDataAll() {
				return this.exportItemsAll.map(item => ({
					...item,
				}));
			},
			exportItemsAll() {
				this.getEnterpriseS4hanaExportReportExportAllItems();
				return this.ExportReportItemsAll.map(function(item) {
					return {

						"Line Count": item.lineCount,
						"Sales DocumentType": item.salesDocumentType,
						"Sales Organization": item.salesOrganization,
						"Distribution Channel": item.distributionChannel,
						"Division": item.division,
						"Order Reason": item.orderReason,
						"Customer Reference": item.customerReference,
						"Material": item.material,
                        "Order Quantity": item.orderQuantity,
                        "Plant": item.plant,
                        "Batch": item.batch,
                        "Sold to Party": item.soldToParty,
						"Bill to Party": item.billToParty,
                        "Bill to Payer": item.payer,
						"Ship to Party": item.shipToParty,
                        "Sales Agent 1": item.salesAgent1,
						"Sales Agent 2": item.salesAgent2,
						"Sales Agent 3": item.salesAgent3,
						"Sales Agent 4": item.salesAgent4,
						"Sales Agent 5": item.salesAgent5,
						"Sales Employee": item.salesEmployee,
					};
				});
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},
		methods: {
			reset() {
				this.filter = {
					qry: "",
					status: "0",
				};
			},
			viewExport() {},
			viewFilter() {
				$("#mod-filter").modal("show");
			},
			getEnterpriseS4hanaExportReportExportAllItems() {
				this.isBusy = true;

				this.$http
					.get(
						"/Reports/getEnterpriseS4hanaExportReport?page=" +
							this.page +
							"&pageSize=" +
							this.pageSizeExport +
							(this.filter.dateStart != ""
								? "&purchaseStartDate=" +
								  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.dateEnd != ""
								? "&purchaseEndDate=" +
								  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
								: "") +
							(this.user.parentId ? "&enterpriseId=" + this.user.parentId : ""),
					)
					.then(function(res) {
						this.ExportReportItemsAll = res.body.data;

						$("#mod-filter").modal("hide");
						this.isBusy = false;
					});
			},
			getEnterpriseS4hanaExportReport() {
				this.isBusy = true;

				this.$http
					.get(
						"/Reports/getEnterpriseS4hanaExportReport?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.dateStart != ""
								? "&purchaseStartDate=" +
								  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.dateEnd != ""
								? "&purchaseEndDate=" +
								  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
								: "") +
							(this.user.parentId ? "&enterpriseId=" + this.user.parentId : ""),
					)
					.then(function(res) {
						this.ReportItems = res.body.data;
						this.page = res.body.page;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;

						$("#mod-filter").modal("hide");
						this.isBusy = false;
					})
					.catch(function() {
						$("#mod-filter").modal("hide");
						this.isBusy = false;
					});
			},
			formatDate(date) {
				return moment(date).format("lll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatNumber(val) {
				return helper.formatNumber(val);
			},
			prev() {
				this.page -= 1;
				this.getEnterpriseS4hanaExportReport();
			},
			next() {
				this.page += 1;
				this.getEnterpriseS4hanaExportReport();
			},
			csvExport(arrData) {
				let csvContent = "data:text/csv;charset=utf-8,";
				csvContent += [
					Object.keys(arrData[0]).join(","),
					...arrData.map(item => Object.values(item).join(",")),
				]
					.join("\n")
					.replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(csvContent);
				const link = document.createElement("a");
				link.setAttribute("href", data);
				link.setAttribute("download", "Enterprise_S4hana_Export_Purchase_Order.csv");
				link.click();
			},
			excelData(exportData) {
				const exportAble = exportData;
				const options = {
					headers: Object.keys(exportAble[0])
				};
				const numericHeaders = [
					"Value Of Orders"
				];
				var titleName = "S4hana Purchase Order Export_";
				var titleFullname =
					this.filter.dateStart === "" || this.filter.dateStart == null
					? titleName + "As At_" + this.filter.dateEnd
					: titleName + this.filter.dateStart + "_to_" + this.filter.dateEnd;
				const excelData = {
					title: titleFullname,
					headers: options.headers,
					data: exportAble,
				};
				const businessName = this.$store.getters.getCurrentUser.parent.name;
				const businessAddress = this.$store.getters.getCurrentUser.parent.address;
				exportExcelData(excelData);
			
				}
		},
	};
</script>
