import { render, staticRenderFns } from "./GrowTradeForgotPassword.vue?vue&type=template&id=b964d834&scoped=true&"
import script from "./GrowTradeForgotPassword.vue?vue&type=script&lang=js&"
export * from "./GrowTradeForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./GrowTradeForgotPassword.vue?vue&type=style&index=0&id=b964d834&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b964d834",
  null
  
)

export default component.exports