<template>
  <a
    :class="'dropdown-item'"
    :disabled="submitLoading"
    @click.prevent="onSubmitClick"
  >
  Process Order 
  </a>
</template>

<script>
export default {
  name: "SwitchPurchaseOrder",
  props: ["purchaseOrder", "userType"],

  data() {
    return {
      submitLoading: false,
    };
  },

  methods: {
    onSubmitClick() {
      this.$emit("showAction", {
        purchaseNumber: this.purchaseOrder.orderNumber,
        action: "submit",
        owner: this.purchaseOrder.id,
      });
    },

    async switchPurchaseOrder() {
      this.submitLoading = true;
      this.isBusy = true;
      try {
        if(this.userType === "salesAgent"){
          const response = await this.$http.put(
          `/PurchaseOrders/salesAgentUpdateStatus/${this.purchaseOrder.id}/submitted`
          );
          if (response.ok && response.status === 200) this.$emit("onComplete");
          
        }else{
           const response = await this.$http.put(
          `/PurchaseOrders/updateStatus/${this.purchaseOrder.id}/submitted`
          );
          if (response.ok && response.status === 200) this.$emit("onComplete");
          
        }
        this.submitLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.submitLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>

<style>
</style>