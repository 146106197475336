<template>
	<div id="printingPosReceipt" style="width: 200mm; display: none">
		<div class="card-body bg-white text-dark p-0" id="wekurnectPos" style=" width: 200mm">
			<div class="container my-2" id="posPrintWekurnect">
				<div class="row" style="width: 200mm;">
					<div class="col pt-3">
						<h3
							style="font-size: 32px; font-weight: 700; color: #373737; line-height: 39px; text-align: center"
						>
							{{ order.seller ? order.seller.name : "NA" }}
						</h3>
						<div class="mt-3 text-center">
							<div>
								<address>
									<p style="font-weight: 700; font-size: 18px; margin: 0 0 8px 0">
										To: {{ order.maker ? order.maker.displayName : "" }}
									</p>
									<p style="font-weight: 700; font-size: 14px; margin: 0 0 8px 0">
										{{
											order.maker
												? order.maker.phoneNumber
													? order.maker.phoneNumber
													: order.maker.emailAddress
												: ""
										}}
									</p>
									<p
										style="font-weight: 700; font-size: 14px; margin: 0 0 24px 0"
									>
										{{ order.maker ? order.maker.deliveryAddress : "" }}
									</p>
								</address>
							</div>
						</div>
						<div style="display: flex; justify-content: space-between">
							<div style="font-size: 14px; line-height: 18px; color: black">
								<address>
									<p style="margin-bottom: 14px">
										<b>Date: </b>{{ formatDate(order.created) }}
									</p>
									<p><b>RN: </b>{{ order.orderNumber }}</p>
								</address>
							</div>
							<div style="font-size: 14px; line-height: 18px; color: black">
								<address>
									<p style="margin-bottom: 14px">
										<b> Order Id: </b>{{ order.orderNumber }}
									</p>
									<p>
										<b>Order Date:</b>
										{{ formatDate(order.created) }}
									</p>
								</address>
							</div>
						</div>
					</div>
				</div>
				<div style=" margin-top: 24px">
					<h4 style="">
						<strong style="font-weight: 900; font-size: 28px; line-height: 34px;">
							Cash Receipt</strong
						>
					</h4>
					<table style="width: 180mm; table-layout: auto; margin-top: 24px">
						<thead>
							<tr style="font-weight: 700; ">
								<th
									style="border-bottom: 1px solid #F2F2F2; width: 50%; padding-bottom: 16px"
								>
									Item
								</th>

								<th
									style="border-bottom: 1px solid #F2F2F2; width: 20%; padding-bottom: 16px; text-align: center"
								>
									Qty
								</th>
								<th
									style="border-bottom: 1px solid #F2F2F2; width: 30%; padding-bottom: 16px; text-align: center"
								>
									U/Cost(&#8358;)
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								style="font-size: 13px"
								v-for="(item, index) in order.details"
								:key="index"
							>
								<td
									style="text-align: start; width: 50%; padding-top: 25px; color: #18191FB2; font-size:13px"
								>
									{{ item.description }}
								</td>

								<td
									style="text-align: center; width: 20%; padding-top: 25px; color: #373737; font-size:13px"
								>
									{{ item.quantity }}
								</td>
								<td
									style="text-align: center; width: 30%; padding-top: 25px; color: #373737; font-size:13px"
								>
									{{ formatMoney(item.unitPrice) }}
								</td>
							</tr>
							<tr style="font-size: 14px">
								<td
									style="text-align: start; width: 50%; padding-top: 25px; color: #18191FB2"
								>
									Discount
								</td>
								<td></td>
								<td
									style="text-align: end; width: 50%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									&#8358; {{ formatMoney(customDiscount) }}
								</td>
							</tr>
							<tr style="font-size: 14px">
								<td
									style="text-align: start; width: 30%; padding-top: 25px; color: #18191FB2"
								>
									Total
								</td>
								<td style="text-align: start; width: 2%;"></td>
								<td
									style="text-align: end; width: 30%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									&#8358; {{ formatMoney(order.total) }}
								</td>
							</tr>
						</tbody>
					</table>
					<table style="width: 180mm; table-layout: auto; margin: 25px 0 24px">
						<thead>
							<tr style="font-weight: 700; font-size: 24px; line-height: 30px;">
								<th
									style="border-bottom: 1px solid #F2F2F2; width: %70; padding-bottom: 16px"
								>
									<!-- Item -->
								</th>
								<th
									style="border-bottom: 1px solid #F2F2F2; width: %30; padding-bottom: 16px; text-align: right"
								>
									<!-- U/Cost -->
								</th>
							</tr>
						</thead>
						<tbody>
							<tr style="font-size: 14px">
								<td
									style="text-align: start; width: %70; padding-top: 25px; color: #18191FB2"
								>
									Total Amount paid
								</td>
								<td
									style="text-align: end; width: %30; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									&#8358;
									{{ order.payment && formatMoney(order.payment.paidAmount) }}
								</td>
							</tr>
							<tr style="font-size: 14px">
								<td
									style="text-align: start; width: %70; padding-top: 25px; color: #18191FB2"
								>
									{{ isExcess ? "Excess" : "Outstanding" }}
								</td>
								<td
									style="text-align: end; width: %30; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									&#8358; {{ formatMoney(Math.abs(order.total - totalPayment)) }}
								</td>
							</tr>
						</tbody>
					</table>
					<hr style="width: 180mm; margin: 0 0 10px 0" />
					<div class="row">
						<div class="col" style="font-size: 14px">
							<p>Receipt generated from weKurnect</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import { helper } from "@/helper";
	export default {
		name: "payment-invoice",
		props: ["order"],
		components: {},

		data() {
			return {};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			totalPayment: function() {
				var myTotal = 0;

				if (this.order.paymentHistory) {
					for (var i = 0, len = this.order.paymentHistory.length; i < len; i++) {
						myTotal += this.order.paymentHistory[i].paidAmount;
					}
				}

				return myTotal;
			},

			customDiscount: function() {
				let customDiscount = 0;

				const totalDiscountOnProducts = this.order.details.reduce(
					(acc, item) => acc + item.discount,
					0,
				);
				customDiscount = this.order.discount - totalDiscountOnProducts;

				return customDiscount.toFixed(2);
			},

			isExcess: function() {
				const isNegative = Math.sign(this.order.total - this.totalPayment);
				if (isNegative >= 0) return false;
				return true;
			},
		},

		mounted() {},

		methods: {
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatDate(date) {
				return moment(date).format("ll");
			},
		},
	};
</script>
