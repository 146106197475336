<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            ' card-table',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header">
            <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
              <i class="feather icon-filter text-white"></i> Filters
            </button>
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                class="btn btn-primary btn-rounded btn-space"
                @click="generateReport"
                v-if="transactions.data.length"
              >
                <i class="feather icon-download text-white"></i>

                <span
                  v-if="transactionDownload.isLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <i class="sr-only">Loading...</i>
                </span>
                <span v-else> Download Statement</span>
              </button>
              <button class="btn btn-success btn-rounded btn-space" @click="goBack">
                <i class="mdi icon mdi-backburger text-white"></i>
                Back
              </button>
            </div>
          </div>
          <div class="card-body">
            <div v-if="customer !== {}" class="container">
              <div class="row justify-content-between">
                <div class="col-sm-4 col-md-3">
                  <div class="d-flex justify-content-between">
                    <h5>Customer Name:</h5>
                    <p>{{ customer.displayName }}</p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5>Address:</h5>
                    <p>{{ customer.deliveryAddress }}</p>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <div class="d-flex justify-content-between">
                    <h5>Account:</h5>
                    <p>
                      {{ customer.code ? customer.code : "N/A" }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5>Start Date:</h5>
                    <p>{{ formatDate(transactionFilter.startDate) }}</p>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3">
                  <div class="d-flex justify-content-between">
                    <h5>Account Balance:</h5>
                    <p>
                      &#8358;
                      <span
                        :class="[
                          { 'text-success': customer.accountBalance > 0 },
                          { 'text-danger': customer.accountBalance < 0 },
                        ]"
                        >{{ formatMoney(customer.accountBalance) }}</span
                      >
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h5>End Date:</h5>
                    <p>{{ formatDate(transactionFilter.endDate) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                dataTables_wrapper
                container-fluid
                dt-bootstrap4
                no-footer
                mt-3
              "
            >
              <div class="row be-datatable-body">
                <div class="col-sm-12 table-responsive">
                  <table
                    class="
                      table table-fw-widget
                      dataTable
                      no-footer
                      table-striped table-hover-animation
                    "
                  >
                    <thead>
                      <tr>
                        <th scope="col">Transaction Date</th>
                        <th scope="col">Value Date</th>
                        <th scope="col">Transaction Type</th>
                        <th scope="col">Reference ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Balance Impact</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="t in transactions.data" :key="t.id">
                        <td>{{ formatDate(t.createdAt) }}</td>
                        <td>{{ formatDate(t.valueDate) }}</td>
                        <td>{{ getTransactionType(t.transactionType) }}</td>
                        <td>{{ t.referenceId }}</td>
                        <td>{{ getStatus(t.status) }}</td>
                        <td>{{ getBalanceImpact(t.balanceImpact) }}</td>
                        <td>&#8358; {{ formatMoney(t.amount) }}</td>
                        <td>&#8358; {{ formatMoney(t.parentBalance) }}</td>
                      </tr>
                      <tr v-if="!transactions.data.length">
                        <td colspan="8">
                          <div class="text-center" style="padding-top: 50px">
                            <span
                              style="font-size: 4.615rem"
                              class="mdi mdi-account-card-details"
                            ></span>
                          </div>
                          <h3 class="text-center" style="padding-bottom: 100px">
                            User doesn't have any transaction.
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row be-datatable-footer">
                <div class="col-sm-5">
                  <div class="dataTables_info">
                    {{ transactions.count }} transaction{{ transactions.count != 1 ? "s" : "" }}
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination">
                      <li
                        :class="[
                          'paginate_button',
                          'page-item',
                          'previous',
                          { disabled: !transactions.hasPrev },
                        ]"
                      >
                        <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                      </li>
                      <li
                        :class="[
                          'paginate_button',
                          'page-item',
                          'previous',
                          { disabled: !transactions.hasNext },
                        ]"
                      >
                        <a href="#" class="page-link" @click.prevent="next">Next</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <filter-transaction :transactionTypes="transactionTypes" />

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="account-statement"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="card-body bg-white text-dark">
        <div class="container p-0">
          <div class="col-md-4 p-0" style="margin-top: 5px">
            <img
              class="logo-img"
              src="/assets/img/logo-xx.png"
              alt="logo"
              width="138"
              height="37"
            />
          </div>
          <div class="row justify-content-between align-items-center" style="margin-top: 50px">
            <div class="col-sm-12 col-md-6">
              <p style="font-size: 18px; font-weight: 600; margin-bottom: 8px; color: #18191F">
                {{ customer.displayName }}
              </p>
              <p style="color: #373737; margin-bottom: 0">{{ customer.deliveryAddress }}</p>
            </div>
            <div class="col-sm-12 col-md-6 text-right" style="color: #373737">
              <p style=" margin-bottom: 8px">
                <span style="color: #9C9C9C">Account Number:</span>
                {{ customer.code ? customer.code : "N/A" }}
              </p>
              <p style=" margin-bottom: 0">
                <span style="color: #9C9C9C">Statement Period:</span>
                {{ formatDate(transactionFilter.startDate) }} -
                {{ formatDate(transactionFilter.endDate) }}
              </p>
            </div>
          </div>
          <div
            class="pl-2"
            style="margin-top: 65px; height: 133px; background: rgba(221, 221, 221, 0.15); 
          display: flex; flex-direction: column; justify-content: center"
          >
            <p class="" style="color: #18191F; font-size: 18px; font-weight: 700">
              Account Summary
            </p>
            <div class="row align-items-center" style="width: 100%">
              <div class="col-sm-6 col-md-3">
                <p class="mb-1" style="color: #9C9C9C; font-weight: 500">Opening Balance</p>
                <p class="mb-0">
                  &#8358;{{ formatMoney(transactionDownload.summary.openingBalance) || 0 }}
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <p class="mb-1" style="color: #9C9C9C; font-weight: 500">Closing Balance</p>
                <p class="mb-0">
                  &#8358;{{ formatMoney(transactionDownload.summary.closingBalance) || 0 }}
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <p class="mb-1" style="color: #9C9C9C; font-weight: 500">Total Credit</p>
                <p class="mb-0">
                  &#8358;{{ formatMoney(transactionDownload.summary.totalCredit) || 0 }}
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <p class="mb-1" style="color: #9C9C9C; font-weight: 500">Total Debit</p>
                <p class="mb-0">
                  &#8358;{{ formatMoney(transactionDownload.summary.totalDebit) || 0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-striped table-sm statement" style="margin-top: 32px">
          <thead style="background: rgba(221, 237, 170, 0.15); height: 75px">
            <tr>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Transaction Date
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Value Date
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Transaction Type
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Reference ID
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Status
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Balance Impact
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Amount
              </th>
              <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="t in transactionDownload.data" :key="t.id">
              <td class="light-td">{{ formatDate(t.createdAt) }}</td>
              <td class="light-td">{{ formatDate(t.valueDate) }}</td>
              <td class="light-td">{{ getTransactionType(t.transactionType) }}</td>
              <td class="light-td">{{ t.referenceId }}</td>
              <td class="light-td">{{ getStatus(t.status) }}</td>
              <td class="light-td">{{ getBalanceImpact(t.balanceImpact) }}</td>
              <td class="light-td">&#8358; {{ formatMoney(t.amount) }}</td>
              <td class="light-td">&#8358; {{ formatMoney(t.balance) }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { mapActions, mapGetters } from "vuex";
import { helper } from "@/helper";
import FilterTransaction from "../components/Transaction/FilterTransaction.vue";

export default {
  name: "CustomerTransaction",
  components: { FilterTransaction, VueHtml2pdf },

  computed: {
    ...mapGetters(["transactions", "transactionFilter", "transactionDownload"]),
    currentBalance: function() {
      let balance = 0;
      if (this.customer && this.transactions.data && this.transactions.data.length > 0) {
        balance = -this.customer.accountBalance;
        this.transactions.data.forEach((element) => {
          balance =
            element.balanceImpact === 0 ? balance + element.amount : balance - element.amount;
        });
      }
      return balance;
    },

    user: function() {
      return this.$store.state.currentUser;
    },
  },

  data() {
    return {
      isBusy: false,
      isDownloading: false,
      customer: {},
      transactionTypes: [
        { name: "All Transactions", value:'' },
        { name: "Payment", value: 0 },
        { name: "Product Purchase", value: 1 },
        { name: "Adjustment Credit Note", value: 2 },
        { name: "Adjustment Debit Note", value: 3 },
        { name: "Reversal", value: 4 },
        { name: "Invoice Payment", value: 5 },
        { name: "Funds Transfer", value: 6 },
        { name: "Product Returns", value: 7 },
        { name: "Product Returns Reversal", value: 8 },
      ],

      balanceImpacts: [
        { name: "Credit", value: 0 },
        { name: "Debit", value: 1 },
      ],

      status: [
        { name: "Completed", value: 0 },
        { name: "Reversed", value: 1 },
      ],
    };
  },

  created() {
    this.getSalesRep();
  },

  mounted() {
    this.getTransactions();
    this.getTransactionSummary();
  },

  updated() {
    if (
      this.isDownloading &&
      !this.transactionDownload.isLoading &&
      this.transactionDownload.data.length > 0
    ) {
      this.$refs.html2Pdf.generatePdf();
      this.isDownloading = false;
    }
  },

  watch: {
    "transactions.isLoading": function(value) {
      if (value === false) $("#mod-transaction-filter").modal("hide");
    },
  },

  methods: {
    ...mapActions(["getTransactions", "downloadTransaction", "getTransactionSummary"]),
    getTransactions() {
      const id = this.$route.params.id;
      this.$store.dispatch("getTransactions", id);
    },

    getTransactionSummary() {
      const id = this.$route.params.id;
      this.$store.dispatch("getTransactionSummary", id);
    },

    generateReport() {
      const id = this.$route.params.id;
      this.$store.dispatch("downloadTransaction", id);
      this.isDownloading = true;
    },

    viewFilter() {
      // eslint-disable-next-line no-undef
      $("#mod-transaction-filter").modal("show");
    },

    async getSalesRep() {
      const id = this.$route.params.id;
      try {
        const response = await this.$http.get("/Users/getById/" + id);
        if (response.ok) this.customer = response.body;
      } catch (error) {}
    },

    getTransactionType(transactionType) {
      const result = this.transactionTypes.find((element) => element.value === transactionType);
      if (result) return result.name;
    },

    getBalanceImpact(balanceImpact) {
      return this.balanceImpacts.find((element) => element.value === balanceImpact).name;
    },

    getStatus(status) {
      return this.status.find((element) => element.value === status).name;
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    prev() {
      this.transactions.pageNumber -= 1;
      this.getTransactions();
    },

    next() {
      this.transactions.pageNumber += 1;
      this.getTransactions();
    },
  },
};
</script>
<style scoped>
.table-striped.statement > tbody > tr:nth-child(even) > td {
  background-color: rgba(221, 237, 170, 0.15);
}

.table-striped.statement > tbody > tr:nth-child(odd) > td {
  background-color: #fff;
}
.light-td {
  font-weight: 300 !important;
}
</style>
