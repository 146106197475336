<template>
  <!-- <div class="be-splash-screen">
    <div class="be-wrapper be-login">
      <div class="be-content">
        <div class="main-content container-fluid">
          <div class="splash-container">
            <div class="card card-border-color card-border-color-primary"> -->
  <div
    class="register-wrapper"
    style="height: 100vh; width: 100%; display: flex; align-items: center; justify-content: center; padding: auto;"
  >
    <div class="card-wrapper">
      <div>
        <div>
          <div>
            <div
              class="card card-border-color card-border-color-primary"
              style="width:100%"
            >
              <div class="card-header">
                <div
                  style="width: 100%; display: flex; flex-wrap: wrap; flex-direction: row; justify-content: space-between"
                >
                  <div style="width: 200px; height: 60px">
                    <img
                      class="logo-img"
                      src="/assets/img/logo-xx.png"
                      alt="logo"
                      width="100%"
                      height="auto"
                    />
                  </div>
                </div>
                <div class="mt-2">
                  <h4>Register Your Account</h4>
                  <span class="splash-description"
                    >Please enter your user information to create your account.
                  </span>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div v-if="user.usertype === 'merchant'" class="form-group">
                    <label>Mobile Number</label>
                    <div class="row">
                      <div class="col-3">
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          placeholder="+234"
                        />
                      </div>
                      <div class="col-9">
                        <input
                          :class="[
                            'form-control',
                            { 'is-invalid': $v.user.phoneNumber.$error },
                          ]"
                          type="text"
                          v-model.trim="$v.user.phoneNumber.$model"
                          placeholder="Phone Number"
                          @blur="confirmPhoneNumberUnique"
                        />
                      </div>
                    </div>
                    <ul
                      v-if="phoneNumberUnique === false"
                      class="parsley-errors-list filled"
                    >
                      <li
                        class="parsley-required text-danger"
                        style="font-size: 10px"
                      >
                        The phone number provided has been used.
                      </li>
                    </ul>
                    <ul
                      v-if="!$v.user.phoneNumber.numeric"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a valid phone number.
                      </li>
                    </ul>
                    <ul
                      v-if="
                        !$v.user.phoneNumber.minLength ||
                          !$v.user.phoneNumber.maxLength
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Phone number must be 10 digits without leading zero.
                      </li>
                    </ul>
                    <div>
                      <span style="font-size: 10px"
                        ><em
                          >This phone number will be used to log in to the
                          platform.</em
                        ></span
                      >
                    </div>
                  </div>

                  <div v-else class="form-group">
                    <label>User Name</label>
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.username.$error },
                      ]"
                      id="username"
                      type="text"
                      placeholder="Username"
                      autocomplete="off"
                      v-model.trim="$v.user.username.$model"
                    />

                    <!-- <div
                      class="invalid-feedback"
                      v-if="
                        !$v.user.username.required && $v.user.username.$dirty
                      "
                    >
                      Field is required.
                    </div> -->

                    <div
                      class="invalid-feedback"
                      v-if="!$v.user.username.alphaNumAndDotValidator"
                    >
                      Username must not include space or special characters!
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Individual or Business Name</label>
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.displayName.$error },
                      ]"
                      type="text"
                      placeholder="Name"
                      v-model.trim="$v.user.displayName.$model"
                    />
                    <ul
                      v-if="
                        !$v.user.displayName.required &&
                          $v.user.displayName.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>

                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="user.emailAddress"
                      readonly
                      placeholder="Email Address"
                    />
                  </div>

                  <div class="form-group" v-if="user.deliveryAddress != 'N/A'">
                    <label>Shop Address</label>
                    <textarea
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.deliveryAddress.$error },
                      ]"
                      placeholder="Delivery Address"
                      v-model.trim="$v.user.deliveryAddress.$model"
                    ></textarea>
                    <ul
                      v-if="
                        !$v.user.deliveryAddress.required &&
                          $v.user.deliveryAddress.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.password.$error },
                      ]"
                      type="password"
                      v-model.trim="$v.user.password.$model"
                      placeholder="Password"
                    />
                    <ul
                      v-if="
                        !$v.user.password.required && $v.user.password.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.password.minLength"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a password greater than or equal to 6 characters.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group login-submit">
                    <button
                      :disabled="
                        isBusy ||
                          (user.usertype === 'merchant' &&
                            phoneNumberUnique === false)
                      "
                      class="btn btn-primary"
                      @click.prevent="update"
                    >
                      <span v-if="isBusy">Busy...</span>
                      <span v-else>Create account</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import {
  required,
  numeric,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);

export default {
  name: "join",
  created() {
    this.getUser();
  },
  data() {
    return {
      isBusy: false,
      user: {
        id: "",
        username: "",
        displayName: "",
        emailAddress: "",
        phoneNumber: "",
        password: "",
        deliveryAddress: "",
        usertype: "",
      },
      phoneNumberUnique: null,
    };
  },
  validations: {
    user: {
      username: { alphaNumAndDotValidator },
      displayName: { required },
      phoneNumber: {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      password: { required, minLength: minLength(6) },
      deliveryAddress: { required },
    },
  },

  methods: {
    getLastTenDigits(phoneNumber) {
      // Ensure the input is a string
      const numberString = phoneNumber.toString();
      if (numberString.length > 10) {
        return numberString.slice(-10);
      }
      return numberString;
    },

    getUser() {
      try {
        let json = helper.decodeString(this.$route.query.token);
        const parsedJson = JSON.parse(json);
        this.user = {
          ...parsedJson,
          phoneNumber: this.getLastTenDigits(parsedJson.phoneNumber),
        };
      } catch (err) {
        this.$router.push("/login");
      }
    },

    async confirmPhoneNumberUnique() {
      if (this.user.phoneNumber)
        await this.$http
          .get("/Users/isPhoneNumberUnique/" + `234${this.user.phoneNumber}`)
          .then(function(res) {
            this.phoneNumberUnique = res.body;
          })
          .catch(function() {});
    },

    async update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          (this.user.usertype === "merchant" &&
            (!this.user.phoneNumber || this.user.phoneNumber.length !== 10)) ||
          (this.user.usertype != "merchant" && !this.user.username)
        ) {
          let fieldErrorType =
            this.user.usertype === "merchant"
              ? "Phone Number is required"
              : "Username is required";
          return this.$toast.warning(fieldErrorType, {
            icon: false,
            rtl: false,
          });
        } else {
          this.isBusy = true;
          let payload = {
            id: this.user.id,
            username:
              this.user.usertype === "merchant"
                ? `234${this.user.phoneNumber}`
                : this.user.username,
            displayName: this.user.displayName,
            emailAddress: this.user.emailAddress,
            password: this.user.password,
            deliveryAddress: this.user.deliveryAddress,
          };

          await this.$http
            .put("/Users/acceptInvite/" + this.user.id, payload)
            .then(function(res) {
              $.gritter.add({
                title: "Account Created",
                text:
                  "Your user account has been created. You can now login with your username and password.",
                class_name: "color success",
              });

              this.$router.push("/login");
            })
            .catch(function() {});

          this.isBusy = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  width: 90%;
}

@media screen and (min-width: 960px) {
  .card-wrapper {
    width: 30%;
  }
}
</style>
