<template>
	<div class="row h-100">
		<div class="container my-auto">
			<div class="card">
				<div class="card-header justify-content-center py-2">
					<h5 class="card-title text-uppercase">Set Your Preference</h5>
				</div>

				<div class="card-body">
					<form @submit.prevent="onSubmit">
						<div class="form-group row">
							<h6 class="col-md-12">
								Do you want to create first Branch or just use our default branch
								"Head Office"?
							</h6>
							<div class="col-md-12">
								<select
									placeholder="Select an option"
									:class="['form-control', { 'is-invalid': v1.branchOption }]"
									v-model="preference.branchOption"
								>
									<option
										v-for="(p, index) in branchOptions"
										:key="index"
										:value="p.value"
									>
										{{ p.text }}
									</option>
								</select>
								<span v-if="v1.branchOption" class="invalid-feedback">
									Branch option is required!
								</span>
							</div>
						</div>
						<div v-if="preference.branchOption === 2" class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Branch Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="[
										'form-control',
										{
											'is-invalid':
												v1.branchName.required || v1.branchName.length,
										},
									]"
									type="text"
									v-model.trim="preference.branchName"
									placeholder="Enter branch name"
								/>

								<span v-if="v1.branchName.required" class="invalid-feedback">
									Please enter a branch name.
								</span>
								<span v-if="v1.branchName.length" class="invalid-feedback">
									Please branch name must be at least 2 characters.
								</span>
							</div>
						</div>
						<div class="form-group row">
							<h6 class="col-md-12">
								CASH has been chosen as your default way of receiving payments. Keep
								it this way, deactivate it or add your own payment channel.
							</h6>
							<div class="col-md-12">
								<select
									placeholder="Select an option"
									:class="['form-control', { 'is-invalid': v1.paymentOption }]"
									v-model="preference.paymentOption"
									@input="setPaymentOptions($event.target.value)"
								>
									<option
										v-for="(p, index) in paymentOptions"
										:key="index"
										:value="p.value"
									>
										{{ p.text }}
									</option>
								</select>
								<span v-if="v1.paymentOption" class="invalid-feedback">
									Please select a payment channel option.
								</span>
							</div>
						</div>
						<div v-if="preference.paymentOption > 1" class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Payment Channel</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									placeholder="Select an option"
									:class="[
										'form-control',
										{
											'is-invalid': v1.paymentChannelType,
										},
									]"
									v-model="preference.paymentChannelType"
								>
									<option
										v-for="(p, index) in paymentChannels"
										:key="index"
										:value="p.name"
									>
										{{ p.name }}
									</option>
								</select>
								<span v-if="v1.paymentChannelType" class="invalid-feedback">
									Please select a payment channel.
								</span>
							</div>
						</div>
						<div v-if="preference.paymentChannelType.toLowerCase() === 'pos'">
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal Issuer</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<multiselect
										v-model="posSelect"
										label="name"
										track-by="code"
										placeholder="Select an option"
										:options="bankLists"
										@select="onSelectPosPay"
									></multiselect>
									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v1.pos.bank }]"
										placeholder="Select an option"
										v-model="preference.pos.bank"
									>
										<option v-for="b in banks" :key="b" :value="b">
											{{ b }}
										</option>
									</select> -->
									<span v-if="v1.pos.bank" class="invalid-feedback">
										Issuer is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-4 col-form-label text-sm-right"
									>Terminal/Device Id</label
								>
								<div class="col-12 col-sm-8 col-lg-8">
									<div class="row">
										<div class="col-10">
											<input
												:class="[
													'form-control',
													{
														'is-invalid': v1.pos.terminalId,
													},
												]"
												type="text"
												v-model.trim="preference.pos.terminalId"
												placeholder="Enter terminal id"
											/>
											<span v-if="v1.pos.terminalId" class="invalid-feedback">
												Please enter a unique terminal Id.
											</span>
										</div>
										<div class="col-2">
											<span v-if="isTerminalValid" class="mdi mdi-check text-success"></span>
										</div>
									</div>				
								</div>
							</div>
						</div>
						<div
							v-if="preference.paymentChannelType.toLowerCase() === 'cryptocurrency'"
						>
							<div class="form-group row">
								<label class="col-12 col-sm-3 col-form-label text-sm-right"
									>Provider</label
								>
								<div class="col-12 col-sm-8 col-lg-8">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v1.crypto.provider,
											},
										]"
										type="text"
										v-model.trim="preference.crypto.provider"
										placeholder="Enter provider"
									/>
									<span v-if="v1.crypto.provider" class="invalid-feedback">
										Please enter a provider.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-3 col-form-label text-sm-right"
									>Wallet Address</label
								>
								<div class="col-12 col-sm-8 col-lg-8">
									<input
										:class="[
											'form-control',
											{
												'is-invalid': v1.crypto.walletAddress,
											},
										]"
										type="text"
										v-model.trim="preference.crypto.walletAddress"
										placeholder="Enter wallet address"
									/>
									<span v-if="v1.crypto.walletAddress" class="invalid-feedback">
										Please enter a wallet Address.
									</span>
								</div>
							</div>
						</div>
						<div v-if="preference.paymentChannelType.toLowerCase() === 'bank'">
							<div class="form-group row">
								<label class="col-12 col-sm-3 col-form-label text-sm-right"
									>Bank</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<multiselect
										v-model="bankSelect"
										label="name"
										track-by="code"
										placeholder="Select an option"
										:options="bankLists"
										@select="onSelectBankPay"
									></multiselect>
									<!-- <select
										class="form-control"
										:class="[{ 'is-invalid': v1.bank.name }]"
										placeholder="Select an option"
										v-model="preference.bank.name"
									>
										<option v-for="b in banks" :key="b" :value="b">
											{{ b }}
										</option>
									</select> -->
									<span v-if="v1.bank.name" class="invalid-feedback">
										Bank is required.
									</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12 col-sm-3 col-form-label text-sm-right"
									>Account Number</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										:class="[
											{
												'is-invalid':
													v1.bank.accountNumber ||
													v1.bank.accountNumberValid,
											},
										]"
										type="text"
										v-model="preference.bank.accountNumber"
									/>
									<span v-if="v1.bank.accountNumber" class="invalid-feedback">
										Account is required.
									</span>
									<span
										v-if="v1.bank.accountNumberValid"
										class="invalid-feedback"
									>
										Enter a valid account number.
									</span>
								</div>
							</div>
							<div v-if="isBankAccountValid" class="form-group row">
								<label class="col-12 col-sm-3 col-form-label text-sm-right"
									>Account Name</label
								>
								<div class="col-12 col-sm-8 col-lg-6">
									<input
										class="form-control"
										type="text"
										disabled
										v-model="accountName"
									/>
								</div>
							</div>
						</div>

						<div class="form-group row">
							<h6 class="col-md-12">
								Do you want your customers to get automatic invoices by email and
								text messages everytime you generate an order for them?
							</h6>
							<div class="col-md-12">
								<select
									placeholder="Select an option"
									:class="['form-control', { 'is-invalid': v1.invoiceOption }]"
									v-model="preference.invoiceOption"
								>
									<option
										v-for="(p, index) in invoiceOptions"
										:key="index"
										:value="p.value"
									>
										{{ p.text }}
									</option>
								</select>
								<span v-if="v1.invoiceOption" class="invalid-feedback">
									Please select an invoice option.
								</span>
							</div>
						</div>
						<div class="d-flex justify-content-center mt-3">
							<button :disabled="isBusyModal" class="btn btn-primary" type="submit">
								<span v-if="isBusyModal">Busy...</span>
								<span v-else>Continue</span>
							</button>
						</div>
						<div class="justify-content-center d-flex mt-4">
							<span class="text-primary mr-1">Please Note:</span>These preferences can
							be changed under the settings menu at any time.
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import { helper } from "@/helper";
	import Multiselect from "vue-multiselect";
	import Select2 from "@/components/Select2";

	export default {
		name: "SetPreference",
		components: {
			Select2,
			Multiselect,
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			bankName() {
				return `${this.preference.bank.name}`;
			},
			bankAccount() {
				return `${this.preference.bank.accountNumber}`;
			},
		},

		created() {
			this.getChannelTypes();
			this.getBankLists();
		},

		mounted() {
			// if (this.user.parent.hasPreference) {
			// 	this.$router.push("/");
			// }
		},

		data() {
			return {
				isBusy: false,
				isBusyModal: false,
				banks: helper.getBanks(),
				bankCodes: helper.getBankCodes(),
				preference: {
					branchOption: 0,
					branchName: "",
					paymentOption: 0,
					paymentChannelType: "",
					invoiceOption: 0,
					bank: {
						name: "",
						accountName: "",
						accountNumber: "",
					},
					crypto: {
						provider: "",
						walletAddress: "",
					},

					pos: {
						bank: "",
						terminalId: "",
					},
				},

				branchOptions: [
					{ value: 1, text: "Activate default Head Office branch" },
					{ value: 2, text: "Enter preferred branch name" },
				],

				paymentOptions: [
					{ value: 1, text: "Leave it this way" },
					{
						value: 2,
						text: "Ok. But I would still like to add a payment channel",
					},
					{
						value: 3,
						text: "I don’t take cash. Use my payment channel",
					},
				],

				paymentChannels: [],

				invoiceOptions: [
					{ value: 1, text: "Yes, I am fine with that" },
					{
						value: 2,
						text: "No, I would like to control how I send out invoices",
					},
				],

				v1: {
					branchOption: false,
					branchName: { required: false, length: false },
					paymentOption: false,
					paymentChannelType: false,
					invoiceOption: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				},
				accountNumber: "",
				bankCodes: helper.getBankCodes(),
				isBankAccountValid: false,
				accountName: "",
				bankLists:[],
				bankSelect:"",
				posSelect:"",
				isTerminalValid: false,
			};
		},
		watch: {
			bankName(newValue, oldValue) {},
			bankAccount(newValue, oldValue) {
				// if (newValue.length == 10) {
				// 	const bankIndex = this.banks.findIndex(el => el === this.bankName);
				// 	let bankCode = this.bankCodes[bankIndex];
				// 	this.validatebankAccount(newValue, bankCode);
				// }
				if (newValue.length == 10) {
					let bankCode = this.bankLists.filter((el) => el.name === this.bankSelect.name)[0].code;
					this.validatebankAccount(newValue, bankCode);
				}
			},
		},

		methods: {
			async validateTerminal(type){
					this.isBusy = true;
					this.v1.pos.terminalId = false;
					this.isTerminalValid = false;
					let terminalId = type === 'create' ? this.createPaymentChannel.pos.terminalId: this.updatePaymentChannel.pos.terminalId;
			
				try {
					const response = await this.$http.get(
						`/PaymentChannels/posTerminalUnique?terminalId=${terminalId}`,
					);
					if (response.ok) {
						// if (response.body) {
						this.isTerminalValid = response.body;
						this.v1.pos.terminalId = !response.body
						// }
						this.isBusy = false;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},
			async getBankLists() {
				this.isBusyModal = true;
				this.isBusy = true;
				try {
					const response = await this.$http.get("/Payments/getbanks");
					if (response.ok) this.bankLists = response.body;
				} catch (error) {}

				this.isBusy = false;
				this.isBusyModal = false;
			},
			onSelectBankPay(option) {
				this.preference.bank.name = option.name;
			},

			onSelectPosPay(option) {
				this.preference.pos.bank = option.name;
			},
			async validatebankAccount(accountnumber, code) {
				this.isBusy = true;
				// let body = {
				// 	accountNo: accountnumber,
				// 	bankCode: code,
				// };
				try {
					const response = await this.$http.post(
						`/Payments/accountValidation?accountNo=${accountnumber}&bankCode=${code}`,
					);
					if (response.ok) {
						if (response.body.status == "Account Not Found") {
							this.$toast.error(response.body.message, {
								icon: true,
								rtl: false,
							});

							this.isBankAccountValid = true;
							this.accountName = response.body.data.account_name;
							return;
						}
						this.$toast.success(response.body.message, {
							icon: true,
							rtl: false,
						});
						// this.accountName = data.data.accounnt_name;
						this.isBusy = false;
						this.accountName = response.body.data.account_name;
						this.isBankAccountValid = true;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},
			setPaymentOptions(value) {
				if (value == 1) {
					const channel = this.paymentChannels.find(x => x.name == "CASH");
					if (channel) {
						this.preference.paymentChannelType = channel.name;
					}
				}
			},
			validate() {
				this.v1 = {
					branchOption: false,
					branchName: { required: false, length: false },
					paymentOption: false,
					paymentChannelType: false,
					invoiceOption: false,
					bank: {
						name: false,
						accountName: false,
						accountNumber: false,
						accountNumberValid: false,
					},

					crypto: {
						provider: false,
						walletAddress: false,
					},

					pos: {
						bank: false,
						terminalId: false,
					},
				};

				let isValid = true;

				if (!this.preference.branchOption) {
					this.v1.branchOption = true;
					isValid = false;
				}

				if (this.preference.branchOption > 1 && !this.preference.branchName) {
					this.v1.branchName.required = true;
					isValid = false;
				}

				if (
					this.preference.branchOption > 1 &&
					this.preference.branchName &&
					this.preference.branchName.length < 2
				) {
					this.v1.branchName.length = true;
					isValid = false;
				}

				if (!this.preference.paymentOption) {
					this.v1.paymentOption = true;
					isValid = false;
				}

				if (this.preference.paymentOption > 1 && !this.preference.paymentChannelType) {
					this.v1.paymentChannelType = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "pos" &&
					!this.preference.pos.terminalId && !this.isTerminalValid
				) {
					this.v1.terminalId = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "pos" &&
					!this.preference.pos.bank
				) {
					this.v1.pos.bank = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "cryptocurrency" &&
					!this.createPaymentChannel.crypto.provider
				) {
					this.v1.crypto.provider = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "cryptocurrency" &&
					!this.preference.crypto.walletAddress
				) {
					this.v1.crypto.walletAddress = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "bank" &&
					!this.preference.bank.name
				) {
					this.v1.bank.name = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "bank" &&
					!this.preference.bank.accountNumber
				) {
					this.v1.bank.accountNumber = true;
					isValid = false;
				}

				if (
					this.preference.paymentChannelType.toLowerCase() === "bank" &&
					this.preference.bank.accountNumber &&
					!helper.validateNuban(this.preference.bank.accountNumber)
				) {
					this.v1.bank.accountNumberValid = true;
					isValid = false;
				}

				if (!this.preference.invoiceOption) {
					this.v1.invoiceOption = true;
					isValid = false;
				}

				return isValid;
			},

			async getChannelTypes() {
				this.isBusyModal = true;
				this.isBusy = true;
				try {
					const response = await this.$http.get("/PaymentChannelType/list");
					if (response.ok) this.paymentChannels = response.body;
				} catch (error) {}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			async onSubmit() {
				try {
					this.isBusyModal = true;
					this.isBusy = true;
					if (this.validate()) {
						let bankCode = "";
						if (this.preference.paymentChannelType == "BANK") {
							bankCode = this.bankLists.filter((el) => el.name === this.preference.bank.name)[0].code;
						}
						const data = {
							...this.preference,
							bank:
								this.preference.paymentChannelType == "BANK"
									? {
											...this.preference.bank,
											name: this.preference.bank.name,
											bankCode,
											accountName: this.accountName
									  }
									: this.preference.bank,
							parentId: this.user.parentId,
							userId: this.user.id,
						};
						const response = await this.$http.post("/accounts/preference", data);
						this.$toast.success(response.body, {
							icon: false,
							rtl: false,
						});

						this.$router.push("/");
					}
					this.isBusy = false;
					this.isBusyModal = false;
				} catch (error) {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},
		},
	};
</script>

<style>
	.text-sm {
		font-weight: 200;
		font-size: 10px;
	}
</style>
