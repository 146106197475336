<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored">
        <h3 class="modal-title">Filter</h3>
        <button
          class="close md-close"
          type="button"
          data-dismiss="modal"
          aria-hidden="true"
        >
          <span class="mdi mdi-close"></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >View By</label
          >

          <div class="col-12 col-sm-8 col-lg-8">
            <select
              v-model="dateRangeType"
              class="form-control"
              @input="onSelectDateRangeType($event.target.value)"
            >
              <option v-for="item of dateRangeTypes" :key="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="dateRangeType == 'Custom' || dateRangeType == 'As at Date'" class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >{{ dateRangeType == 'As at Date' ? 'Date' : 'Date range' }}</label
          >
          <div class="col-12 col-sm-4 col-lg-4" v-if="dateRangeType != 'As at Date'">
            <input
              type="date"
              :max="maxDate"
              class="form-control"
              placeholder="Select a date"
              v-model="filter.dateStart"
            />
          </div>
          <div class="col-12 col-sm-4 col-lg-4">
            <input
              type="date"
              :max="maxDate"
              class="form-control"
              placeholder="Select a date"
              v-model="filter.dateEnd"
            />
          </div>
        </div>

        <div v-if="extraDataType == 'TEXT'" class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >{{ extraDataLabel }}</label
          >
          <div class="col-12 col-sm-8 col-lg-8">
            <input
              class="form-control"
              type="text"
              :placeholder="extraDataPlaceholder"
              v-model="filter.qry"
              @input="update"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >Order Stages</label
          >
          <div class="col-12 col-sm-8 col-lg-8">
            <v-select
                id="stageSelector"
                class="style-chooser"
                multiple
                :options="stageOptions"
                v-model="filter.stages"
                :reduce="value => value.id"
                @input="isSelectStage($event)"
              />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >Distributor</label
          >
          <div class="col-12 col-sm-8 col-lg-8">
            <input
              class="form-control"
              type="text"
              placeholder="Distributor name"
              v-model="filter.distributor"
              @input="update"
            />
          </div>
        </div>



        <div v-if="extraDataType == 'LIST'" class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right"
            >{{ extraDataLabel }}</label
          >
          <div class="col-12 col-sm-8 col-lg-8">
            <select class="form-control" v-model="filter.status" @change="update">
              <option v-for="(item, index) of extraDataList" :key="item.value" :selected="index === 0" :value="item.value"> {{ item.text }}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button
          style="width: 100px"
          class="btn btn-secondary md-close"
          type="button"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          style="width: 100px"
          class="btn btn-secondary md-close"
          type="button"
          @click="reset"
        >
          Reset
        </button>
        <button
          style="width: 100px"
          class="btn btn-success md-close"
          type="button"
          @click="onSearch();searchFn()"
        >
          Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {helper} from "@/helper";
import vSelect from "vue-select";

export default {
  name: "ReportFilterModal",
  components: { vSelect },
  props: {
    extraDataType: { type: String, default: "NONE" }, // NONE or TEXT or LIST,
    extraDataLabel: { type: String, default: "Search" }, // Put label of extradata here
    extraDataPlaceholder: { type: String, default: "Enter text to search" }, // Put placeholder of extradata here
    extraDataList: { type: Array, default: () => [] }, // if extradattype LIST put an array of {value:any, text:string} here
    searchFn: { type: Function },
  },
  data() {
    return {
      dateFormat: "yyyy-MM-DD",
      dateRangeTypes: ["Year Before Now", "Today", "This Month", "This Year", "As at Date", "Custom"],
      dateRangeType: "Year Before Now",
      filter: {
        dateStart: moment().subtract(1, 'years').format("yyyy-MM-DD"),
        dateEnd: moment().format("yyyy-MM-DD"),
        qry: "",
        status: "",
        stages: [],
        distributor: ""
      },
      maxDate: "",
      stageOptions: []
    };
  },
  mounted() {
    this.getPurchaseOrderStages();
    if (this.$props.extraDataType == 'LIST' && this.extraDataList.length > 0) {
      this.filter.status = this.extraDataList[0]['value']; 
      this.update();
			this.maxDate = helper.preventFutureDateSelection();
    }
  },
  methods: {
    isSelectStage(e) {
        this.filter.stages = e;
        this.update();
    },
    async getPurchaseOrderStages(){
      await this.$http.get(
          "/PurchaseOrders/orderStage/getList?active=true")
        .then(function (res) {
          this.stageOptions = res.body.map((item) => {
            return {id: item.id, label: item.name}
          });
        })
        .catch(function () {
        });
    },
    update() {
      this.$emit("input", this.filter);
    },
    onSearch() {
      this.$emit('onsearch');
    },
    reset() {
      this.filter = {
        dateStart: moment().subtract(1, 'years').format(this.dateFormat),
        dateEnd: moment().format(this.dateFormat),
        qry: "",
        status: "",
        stages: [],
        distributor: ""
      };
      this.update();
    },
    onSelectDateRangeType(value) {
      switch (value) {
        case "Year Before Now":
          this.filter.dateStart = moment().subtract(1, 'years').format(this.dateFormat);
          this.filter.dateEnd = moment().format(this.dateFormat);
          break;
        case "Today":
          this.filter.dateStart = moment().startOf('day').format(this.dateFormat);
          this.filter.dateEnd = moment().endOf('day').format(this.dateFormat);
          break;
        case "This Month":
          this.filter.dateStart = moment()
            .startOf("month")
            .format(this.dateFormat);
          this.filter.dateEnd = moment(this.filter.dateStart)
            .endOf("month")
            .format(this.dateFormat);
          break;
        case "As at Date":
          this.filter.dateStart = null;
          this.filter.dateEnd = moment()
            .endOf("day")
            .format(this.dateFormat);
          break;
        case "This Year":
          this.filter.dateStart = moment()
            .startOf("year")
            .format(this.dateFormat);
          this.filter.dateEnd = moment().endOf("year").format(this.dateFormat);
          break;
        case "Custom":
          break;
        default:
          break;
      }
      this.update();
    },
    inputFilterChange(value) {
      this.filter.qry = value;
      this.update();
    },
    dropDownFilterChange(value) {
      this.filter.status = value;
      this.update();
    }
  },
};
</script>

<style>
</style>