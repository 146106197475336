<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <h4>
            Process Sales Order for {{ purchaseOrder.parent.name }} -
            {{ purchaseOrder.orderNumber }}
          </h4>
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Total Items</th>
                      <th>Total Amount</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Current Stage</th>
                      <th>Progress To</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          purchaseOrder.details
                            ? purchaseOrder.details.length
                            : 0
                        }}
                      </td>
                      <Amount :details="purchaseOrder.details" />
                      <Quantity :details="purchaseOrder.details" />
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success':
                                purchaseOrder.status === 'confirmed',
                            },
                            {
                              'badge-danger':
                                purchaseOrder.status === 'withdrawn',
                            },
                            {
                              'badge-secondary':
                                purchaseOrder.status === 'draft',
                            },
                            {
                              'badge-warning':
                                purchaseOrder.status === 'submitted',
                            },
                            {
                              'badge-dark': purchaseOrder.status === 'recalled',
                            },
                          ]"
                          >{{ purchaseOrder.status }}</span
                        >
                      </td>
                      <td>
                        {{
                          purchaseOrder.stage
                            ? purchaseOrder.stage.name
                            : "No Stage"
                        }}
                      </td>
                      <td>
                        <StageOptions
                          v-if="purchaseOrder.id"
                          :stage="
                            purchaseOrder.stage ? purchaseOrder.stage.id : ''
                          "
                          :orderId="orderId"
                          @showCaution="showStageProgressCaution"
                          :disable="isDispatched"
                          :shouldReset="shouldReset"
                          :isPrepared="isPrepared"
                        />
                      </td>
                      <td>
                        <button
                          v-if="showClose"
                          class="btn btn-success"
                          type="button"
                          @click.prevent="closeOrder"
                          :disabled="closeLoading"
                        >
                          <span
                            v-if="closeLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <i class="sr-only">Loading...</i>
                          </span>
                          <span v-else> Close </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div>
                    <span
                      v-if="isActivityLoading"
                      class="spinner-border text-success spinner-border-sm"
                      role="status"
                    >
                      <i class="sr-only">Loading...</i>
                    </span>
                    <p
                      v-else
                      class="font-weight-bold font-italic"
                      v-for="activity in activities"
                      :key="activity.id"
                    >
                      {{ activity.performedBy }} {{ activity.actionName }} on
                      {{
                        activity.actionName.toLowerCase().includes("stage")
                          ? formatDate(activity.actionDate)
                          : formatDate(activity.date)
                      }}.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StageCaution
      :orderNumber="purchaseOrder.orderNumber"
      :cautionData="stageCautionData"
      @stageChanged="onStageChanged"
      @onCancelClick="onCancelClickHandler"
    />
  </div>
</template>

<script>
import moment from "moment";
import Amount from "../../components/SettingsPurchaseOrder/Amount";
import Quantity from "../../components/SettingsPurchaseOrder/Quantity";
import StageOptions from "../../components/SettingsPurchaseOrder/StageOptions";
import StageCaution from "../../components/SettingsPurchaseOrder/StageCaution";
import { mapActions } from "vuex";
export default {
  components: {
    Amount,
    Quantity,
    StageOptions,
    StageCaution,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      closeLoading: false,
      isActivityLoading: false,
      shouldReset: false,
      activities: [],
      orderId: "",
      purchaseOrder: {
        parent: {
          name: "",
        },
      },
      isPrepared: false,
      stageCautionData: {},
      filter: {
        dateStart: moment().subtract(13, "days").format("YYYY-MM-DD"),
      },
    };
  },

  mounted() {
    this.orderId = this.$route.params.id;
    this.editPurchaseOrder();
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    showClose: function () {
      let isShow = false;
      if (
        this.purchaseOrder.stage &&
        this.purchaseOrder.stage.name === "Dispatched" &&
        this.purchaseOrder.status !== "CLOSED"
      ) {
        isShow = true;
      }
      return isShow;
    },

    isDispatched: function () {
      let isShow = false;
      if (
        this.purchaseOrder.stage &&
        this.purchaseOrder.stage.name === "Dispatched"
      ) {
        isShow = true;
      }
      return isShow;
    },
  },

  created() {
    const startDate = this.$route.query.sd
      ? this.$route.query.sd
      : moment().subtract(13, "days").format("YYYY-MM-DD");
    this.filter = {
      dateStart: moment(String(startDate)).format("YYYY-MM-DD"),
    };
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    getPurchaseOrder() {
      this.$store.dispatch("getPurchaseOrder", "enterprise");
    },

    async getActivies() {
      this.isActivityLoading = true;
      try {
        const response = await this.$http.get(
          `/Activity/${this.purchaseOrder.id}/${1}`
        );

        if (response.ok) {
          this.activities = response.body;
        }
        this.isActivityLoading = false;
      } catch (error) {
        this.isActivityLoading = false;
      }
    },

    async editPurchaseOrder() {
      this.isBusy = true;

      await this.$http
					.get(
						"/PurchaseOrders/edit/" + this.orderId
					)
					.then(function(response) {
						if (response.ok) {
              this.purchaseOrder = response.body;
              if (this.purchaseOrder.id) {
                // this.getPurchaseOrder();
                this.getActivies();
              }
            }
						this.isBusy = false;
					})
					.catch(function() {
            this.isBusy = false;
          });
    },

    showStageProgressCaution(data) {
      this.stageCautionData = data;

      // eslint-disable-next-line no-undef
      $("#mod-stage-caution").modal("show");
    },

    onStageChanged() {
      this.shouldReset = !this.shouldReset;

      // eslint-disable-next-line no-undef
      $("#mod-stage-caution").modal("hide");

      this.$router.go();
    },

    onCancelClickHandler() {
      this.shouldReset = !this.shouldReset;

      // eslint-disable-next-line no-undef
      $("#mod-stage-caution").modal("hide");
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    async closeOrder() {
      this.isBusy = true;
      this.closeLoading = true;

      try {
        const response = await this.$http.put(
          "/PurchaseOrders/close/" + this.orderId + "/" + this.user.id
        );

        if (response.ok) {
          // this.getPurchaseOrder();
          this.editPurchaseOrder();
          this.$toast.success("Sales order closed.", {
            icon: false,
            rtl: false,
          });
        }

        this.isBusy = false;
        this.closeLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.closeLoading = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },
  },
};
</script>
