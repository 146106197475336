<template>
	<div class="modal fade colored-header colored-header-primary" id="mod-item-add" role="dialog">
		<div class="modal-dialog">
			<form @submit.prevent="submit" class="modal-content">
				<div class="modal-header modal-header-colored">
					<h4 class="modal-title p-1">
						<strong>{{ isEdit ? "Update Item" : "Add Item" }}</strong>
					</h4>
					<button
						class="close md-close"
						type="button"
						data-dismiss="modal"
						aria-hidden="true"
						@click="cancel"
					>
						<span class="mdi mdi-close"></span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group row">
						<label class="col-12 col-sm-3 col-form-label text-sm-right"
							>Select product</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<!-- <select
								:disabled="isEdit"
								class="form-control"
								:class="{
									'is-invalid': $v.newItem.productId.$error,
								}"
								placeholder="Select a product"
								@input="setProduct($event.target.value)"
								v-model="newItem.productId"
							>
								<option v-for="p in preparedProducts" :key="p.id" :value="p.id">
									{{ p.name }}
								</option>
							</select> -->
							<select2
								v-model="newItem.productId"
								@input="setProduct($event)"
								placeholder="Select a product"
							>
								<option v-for="p in preparedProducts" :key="p.id" :value="p.id">
									{{ p.name }}
								</option>
							</select2>
							<span class="invalid-feedback" v-if="!$v.newItem.productId.required">
								Please select a product
							</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-3 col-form-label text-sm-right">Quantity</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<input
								class="form-control"
								:class="{
									'is-invalid': $v.newItem.quantity.$error,
								}"
								type="number"
								placeholder="Enter the quantity you needed"
								v-model="newItem.quantity"
								@input="setQuantity($event.target.value)"
							/>
							<span class="invalid-feedback" v-if="!$v.newItem.quantity.required">
								Please enter a quantity
							</span>
							<span class="invalid-feedback" v-if="!$v.newItem.quantity.minValue">
								Quantity must be greater than
								{{ $v.newItem.quantity.$params.minValue.min - 1 }}.
							</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-3 col-form-label text-sm-right">Price</label>
						<div v-if ="!hasPriceOptions" class="col-12 col-sm-8 col-lg-8">
							<input
								class="form-control"
								type="number"
								readonly
								v-model.trim="newItem.price"
							/>
						</div>
						<div v-else class="col-12 col-sm-8 col-lg-8">
							<select2
								v-model="newItem.priceOptionId"
								@input="setPrice($event)"
								placeholder="Select a price option"
							>
								<option v-for="p in priceOptions" :key="p.id" :value="p.id">
									{{ p.name }} - &#8358;{{formatMoney(p.distributorPrice)}}
								</option>
							</select2>
							<span class="invalid-feedback" v-if="hasPriceOptions && !$v.newItem.price.required">
								Please select a price option
							</span>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button
						class="btn btn-secondary"
						@click="cancel"
						type="button"
						data-dismiss="modal"
					>
						Cancel
					</button>
					<button class="btn btn-primary" type="submit">
						<span v-if="priceLoading">Price Loading...</span>
						<span v-else-if="isEdit">Update Item</span>
						<span v-else>Add Item</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { minValue, required } from "vuelidate/lib/validators";
	import Select2 from "@/components/Select2";
	import { helper } from "@/helper";
	export default {
		components: { Select2 },
		name: "AddItem",
		props: ["groupId", "products", "filteredProducts", "edit"],

		data() {
			return {
				priceLoading: false,
				newItem: {
					productId: "",
					quantity: 0,
					price: 0,
					name:"",
					priceOptionId:""
				},
				hasPriceOptions: null,
				priceOptions: [],
			};
		},

		computed: {
			isEdit: function() {
				return this.edit !== null;
			},

			preparedProducts() {
				if (this.isEdit) {
					return this.products;
				} else {
					return this.filteredProducts;
				}
			},
		},

		validations: {
			newItem: {
				productId: {
					required,
				},

				quantity: {
					required,
					minValue: minValue(1),
				},

				price: {
					required,
				},
			},
		},

		watch: {
			edit: function(value) {
				if (value) {
					this.setQuantity(value.quantity);
					this.setProduct(value.id);
				}
			},
		},

		methods: {
			setQuantity(value) {
				this.newItem.quantity = value;
				this.$v.newItem.quantity.$touch();
			},

			setProduct(value) {
				this.newItem.productId = value;
				if (this.newItem.productId) {
					this.getDistributorPrice(this.newItem.productId);
				}
				this.$v.newItem.productId.$touch();
			},

			setPrice(value) {
				this.newItem.priceOptionId = value;
				const thevalue = value;
				this.setPriceOptionDetails(thevalue);
				this.$v.newItem.price.$touch();
				return;
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			setPriceOptionDetails(id){
				this.newItem.price = this.priceOptions.filter(item => item.id === id)[0].distributorPrice;
				this.newItem.name = this.priceOptions.filter(item => item.id === id)[0].name;
				return;
			},

			async getDistributorPrice(id) {
				this.priceLoading = true;
				try {
					if (this.groupId) {
						const response = await this.$http.get(
							`/Groups/price/${this.groupId}/${id}`,
						);

						if (response.status === 200){
							// this.newItem.price = response.body.distributorPrice;
							if(response.body.canViewList){
								this.hasPriceOptions = response.body.canViewList;
								this.priceOptions = response.body.priceOptions;

							}else{
								this.newItem.price = response.body.singlePrice.distributorPrice;
								this.newItem.name = response.body.singlePrice.name;
							}
						}
						

						if (this.newItem.price === 0) this.getProductPrice(id);
					} else {
						this.getProductPrice(id);
					}

					this.priceLoading = false;
				} catch (error) {
					this.priceLoading = false;
				}
			},

			getProductPrice(id) {
				const product = this.products.find(p => p.id === id);
				this.newItem.price = product.distributorPrice;
			},

			async submit() {
				this.$v.$touch();
				if (!this.$v.$invalid) {
					this.isEdit
						? this.$emit("onItemUpdated", this.newItem)
						: this.$emit("onItemAdded", this.newItem);
					this.reset();
				}
			},

			cancel() {
				this.reset();
				this.$emit("onCancel");
			},

			reset() {
				this.newItem.productId = "";
				this.newItem.quantity = 0;
				this.newItem.price = 0;
				this.newItem.name = "";
				this.newItem.priceOptionId ="";
				this.hasPriceOptions = null;
				this.priceOptions= [];
				setTimeout(() => {
					this.$v.$reset();
				}, 0);
			},
		},
	};
</script>

<style></style>
