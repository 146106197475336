<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<router-link
						class="btn btn-space btn-success btn-rounded float-right"
						to="/products"
					>
						<span class="icon icon-left mdi mdi-arrow-left"></span>
						Back
					</router-link>
					<!--<button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="viewAdd">
                        <span class="icon icon-left mdi mdi-plus text-white"></span> Add
                        Product
                    </button>-->
				</div>
				<div class="card-body">
					<div class="form-group row">
						<div class="col-7 col-sm-7 col-lg-7">
							<input ref="fileUpload" type="file" @change="onFileChange" />
						</div>

						<div class="col-5 col-sm-5 col-lg-5">
							<a
								download="product_template.csv"
								href="/assets/templates/product-template.csv"
								class="float-right"
							>
								Download Template
							</a>
						</div>
					</div>

					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table class="table table-fw-widget dataTable no-footer">
									<thead>
										<tr>
											<th style="width: 15%">Manufacturer Product</th>
											<th style="width: 15%">Product Code</th>
											<th style="width: 15%">Name</th>
											<th style="width: 15%">Description</th>
											<th style="width: 10%">Retail Price</th>
											<th style="width: 10%">Minimum Stock Level</th>
											<th style="width: 10%"></th>
											<th style="width: 10%"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(l, index) in productUpload" :key="l.id">
											<td>{{ l.manufacturer_product_name }}</td>
											<td>{{ l.distributor_product_code }}</td>
											<td>{{ l.product_name }}</td>
											<td>{{ l.product_description }}</td>
											<td>{{ formatMoney(l.retail_price) }}</td>
											<td>{{ l.minimum_stock_level }}</td>
											<td class="text-danger">
												<i
													v-if="l.errors"
													class="feather icon-alert-triangle"
												></i
												>&nbsp;{{ l.errors }}
											</td>
											<td>
												<div class="d-flex">
													<button
														class="btn btn-success btn-sm btn-rounded btn-space"
														@click.prevent="viewEdit(l)"
													>
														<i class="feather icon-edit"></i>
														Edit</button
													>&nbsp;
													<button
														class="btn btn-danger btn-sm btn-rounded btn-space"
														@click.prevent="onRemoveClick(index)"
													>
														<i class="feather icon-delete"></i> Delete
													</button>
												</div>
											</td>
										</tr>
										<tr v-if="!productUpload.length">
											<td colspan="8">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-crosshairs-gps"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any products.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ productUpload.length }} product{{
										productUpload.length != 1 ? "s" : ""
									}}
								</div>
							</div>
							<div class="col-sm-7">
								<div class="dataTables_info text-danger">
									{{
										errorCount > 0
											? "Please correct all errors before proceeding"
											: ""
									}}
								</div>
							</div>
						</div>
					</div>

					<div class="form-group row">
						<div class="col-12 col-sm-12 col-lg-12">
							<br />
							<br />&nbsp;
							<button
								style="width: 130px"
								:disabled="
									isBusyModal || productUpload.length <= 0 || errorCount > 0
								"
								class="btn btn-primary"
								type="button"
								@click.prevent="onUploadClick"
							>
								<span v-if="isBusyModal">Busy...</span>
								<span v-else>Post</span>
							</button>

							<button
								v-if="productUpload.length > 0"
								style="width: 130px"
								class="btn btn-danger pull-right"
								type="button"
								@click.prevent="reset"
							>
								<span>Clear All</span>
							</button>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Add Product</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row" v-if="!v1.is_valid">
							<div class="col-12 col-sm-12 col-lg-12">
								<label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{
									v1.text
								}}</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createProduct.manufacturer_product_code"
									@input="getPrice($event.target.value)"
								>
									<option
										v-for="p in manufacturerProducts"
										:key="p.code"
										:value="p.code"
									>
										{{ p.name }}
									</option>
								</select>
								<ul
									v-if="v1.manufacturer_product_code"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control']"
									type="text"
									v-model="manufacturerUnit"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Distributor Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control']"
									type="text"
									v-model="manufacturerPrice"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.retailPrice }]"
									type="number"
									v-model="createProduct.retail_rice"
									:disabled="allowPriceOverride"
								/>
								<ul v-if="v1.retail_price" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.code }]"
									type="text"
									v-model="createProduct.distributor_product_code"
								/>
								<ul
									v-if="v1.distributor_product_code"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.name }]"
									type="text"
									v-model="createProduct.product_name"
								/>
								<ul v-if="v1.product_name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="createProduct.product_description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Minimum Stock Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.msl }]"
									type="number"
									v-model="createProduct.minimum_stock_level"
								/>
								<ul
									v-if="v1.minimum_stock_level"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 130px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="create"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Add</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Edit Product</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row" v-if="!v2.is_valid">
							<div class="col-12 col-sm-12 col-lg-12">
								<label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{
									v2.text
								}}</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="updateProduct.manufacturer_product_code"
									@input="getPrice($event.target.value)"
								>
									<option
										v-for="p in manufacturerProducts"
										:key="p.code"
										:value="p.code"
									>
										{{ p.name }} ({{ p.unit }})
									</option>
								</select>
								<ul
									v-if="v2.manufacturer_product_code"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.code }]"
									type="text"
									v-model="updateProduct.distributor_product_code"
								/>
								<ul
									v-if="v2.distributor_product_code"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.retailPrice }]"
									type="number"
									v-model="updateProduct.retail_price"
								/>
								<ul v-if="v2.retail_price" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.name }]"
									type="text"
									v-model="updateProduct.product_name"
								/>
								<ul v-if="v2.product_name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="updateProduct.product_description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Minimum Stock Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.msl }]"
									type="number"
									v-model="updateProduct.minimum_stock_level"
								/>
								<ul
									v-if="v2.minimum_stock_level"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="update"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<UploadCaution
			:text="modalText"
			:action="action"
			@proceedToRemove="remove"
			@proceedToUpload="uploadProducts"
		/>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import datePicker from "vue-bootstrap-datetimepicker";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import SystemPermissions from "@/permissions";
	import UploadCaution from "../components/UploadCaution";

	export default {
		mixins: [SystemPermissions.Mixin],

		name: "productsUpload",
		components: {
			Select2,
			UploadCaution,
			datePicker,
		},

		created() {
			if (!this.hasPermission("Product_Create")) {
				this.$router.push("/restricted");
			}
			this.getGroup();
			this.getTypes();
			this.getCategories();
			this.getUnits();
			this.originalProducts = this.Products;
		},

		updated() {
			if (this.$route.params.id && this.Products) {
				const product = this.Products.find(prod => prod.id === this.$route.params.id);
				this.viewEdit(product);
			}
		},

		watch: {},

		data() {
			return {
				options: {
					format: "YYYY-MM-DD",
					useCurrent: false,
				},
				isBusy: false,
				isBusyModal: false,
				createProduct: {
					manufacturer_product_code: null,
					distributor_product_code: null,
					product_name: null,
					product_description: null,
					retail_price: null,
					minimum_stock_level: 0,
				},
				updateProduct: {
					manufacturer_product_code: null,
					distributor_product_code: null,
					product_name: null,
					product_description: null,
					retail_price: null,
					minimum_stock_level: 0,
				},
				v1: {
					manufacturer_product_code: false,
					distributor_product_code: false,
					product_name: false,
					retail_price: false,
					minimum_stock_level: false,
					is_valid: true,
				},
				v2: {
					manufacturer_product_code: false,
					distributor_product_code: false,
					product_name: false,
					retail_price: false,
					minimum_stock_level: false,
					is_valid: true,
				},
				Products: [],
				originalProducts: [],
				manufacturerProducts: [],
				types: [],
				categories: [],
				units: [],
				manufacturerPrice: null,
				manufacturerCategory: null,
				manufacturerUnit: null,
				allowPriceOverride: true,
				productUpload: [],
				errorCount: 0,
				modalText: "",
				action: "",
				removeIndex: 0,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		methods: {
			async getGroup() {
				this.isBusy = true;
				try {
					const response = await this.$http.get(`/Groups/getGroup/`);
					if (response.ok) {
						this.groupId = response.body;
						this.getManufacturerProducts();
					}

					this.isBusy = true;
				} catch (error) {
					this.isBusy = false;
				}
			},

			async getPrice(code, isEdit) {
				var id = this.getManufacturerProductIdByCode(code);
				this.isBusy = true;
				try {
					if (this.groupId) {
						const response = await this.$http.get(
							`/Groups/price/${this.groupId}/${id}`,
						);

						if (response.status === 200) {
							this.prepareProduct(id, response.body, isEdit);
						}
					} else {
						this.prepareProduct(id, { distributorPrice: 0, retailPrice: 0 }, isEdit);
					}
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
			},

			prepareProduct(id, price, isEdit) {
				const product = this.manufacturerProducts.find(pr => pr.id === id);

				this.manufacturerCategory = product.category.name;
				this.manufacturerUnit = product.unit;
				this.allowPriceOverride = !product.allowPriceOverride;
				this.manufacturerPrice =
					price.distributorPrice > 0 ? price.distributorPrice : product.distributorPrice;
				if (isEdit) {
					this.updateProduct.retail_price =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				} else {
					this.createProduct.retail_price =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				}
			},

			onFileChange(e) {
				this.errorCount = 0;
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				var file = files[0];

				let self = this;

				this.$papa.parse(file, {
					header: true,
					download: true,
					dynamicTyping: true,
					skipEmptyLines: true,
					complete: function(results) {
						self.productUpload = results.data;

						for (var i = 0; i < self.productUpload.length; i++) {
							var errors = "";
							var product = self.productUpload[i];
							var productName = self.getManufacturerProductNameByCode(
								product.manufacturer_product_code,
							);

							if (productName === "") {
								errors = "Invalid manufacturer product code";
							} else {
								self.productUpload[i].manufacturer_product_name = productName;
							}

							if (!product.distributor_product_code) {
								errors = errors + "|Invalid distributor product code";
							}

							if (!product.product_name) {
								errors = errors + "|Invalid product name";
							}

							if (!product.product_description) {
								errors = errors + "|Invalid product description";
							}

							if (!product.retail_price) {
								errors = errors + "|Invalid retail price";
							}

							if (!product.minimum_stock_level) {
								errors = errors + "|Invalid minimum stock level";
							}

							if (isNaN(product.retail_price)) {
								errors = errors + "|Retail price must be a number greater than 0";
							}

							if (isNaN(product.minimum_stock_level)) {
								errors =
									errors + "|Minimum stock level must be a number greater than 0";
							}

							if (!errors) {
								product.product_name = product.product_name.trim();
								product.distributor_product_code = product.distributor_product_code.trim();
								product.product_description = product.product_description.trim();
							} else self.errorCount++;

							self.productUpload[i].errors = errors;
						}
					},
				});
			},

			reset() {
				this.errorCount = 0;
				this.productUpload.splice(0);
				this.$refs.fileUpload.value = null;
			},
			cancel() {
				const currentProduct = this.Products.find(p => p.id === this.editProduct.id);
				this.allowPriceOverride = true;
				currentProduct.manufacturer_product_code = this.editProduct.manufacturer_product_code;
				currentProduct.distributor_product_code = this.editProduct.distributor_product_code;
				currentProduct.product_name = this.editProduct.product_name;
				currentProduct.product_description = this.editProduct.product_description;
				currentProduct.retail_price = this.editProduct.retail_price;
				currentProduct.minimum_stock_level = this.editProduct.minimum_stock_level;
				$("#mod-edit").modal("hide");
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},

			viewAdd() {
				this.manufacturerPrice = null;
				this.manufacturerCategory = null;
				this.manufacturerUnit = null;
				this.allowPriceOverride = null;
				this.createProduct = {
					manufacturer_product_code: null,
					distributor_product_code: null,
					product_name: null,
					product_description: null,
					retail_price: null,
					minimum_stock_level: 0,
				};
				this.v1 = {
					manufacturer_product_code: false,
					distributor_product_code: false,
					product_name: false,
					product_description: false,
					retail_price: false,
					minimum_stock_level: false,
					is_valid: true,
				};
				$("#mod-add").modal("show");
			},
			viewEdit(c) {
				var manufacturerProductId = this.getManufacturerProductIdByCode(
					c.manufacturer_product_code,
				);
				var editValues = {
					manufacturer_product_code: c.manufacturer_product_code,
					distributor_product_code: c.distributor_product_code,
					product_name: c.product_name,
					product_description: c.product_description,
					retail_price: c.retail_price,
					minimum_stock_level: c.minimum_stock_level ? c.minimum_stock_level : 0,
				};
				this.editProduct = editValues;
				this.updateProduct = c;
				$("#mod-edit").modal("show");
			},
			viewUploadExpenses() {
				this.$router.push("/manage-expenses/upload");
			},

			onRemoveClick(index) {
				this.modalText = "Are you sure you want to remove this item?";
				this.action = "remove";
				this.removeIndex = index;
				$("#mod-expense-caution").modal("show");
			},

			remove() {
				var expense = this.productUpload[this.removeIndex];
				if (expense.errors) {
					this.errorCount--;
				}
				this.productUpload.splice(this.removeIndex, 1);
				this.removeIndex = 0;
				$("#mod-expense-caution").modal("hide");
			},

			onUploadClick() {
				this.modalText = "Are you sure?";
				this.action = "upload";
				$("#mod-expense-caution").modal("show");
			},

			async uploadProducts() {
				$("#mod-expense-caution").modal("hide");
				this.isBusyModal = true;
				this.isBusy = true;

				var data = {
					parentId: this.user.parentId,
					products: this.productUpload,
				};

				await this.$http
					.post("/products/createForDistributorBulk", data)
					.then(function(res) {
						this.$router.push("/products");
					})
					.catch(function() {});

				this.isBusyModal = false;
				this.isBusy = false;
			},

			async create() {
				this.isBusyModal = true;
				this.isBusy = true;

				if (this.validateCreate()) {
					this.productUpload.push(this.createProduct);
					$("#mod-add").modal("hide");
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},
			validateCreate() {
				this.v1 = {
					manufacturer_product_code: false,
					distributor_product_code: false,
					product_name: false,
					retail_price: false,
					minimum_stock_level: false,
				};
				var isValid = true;

				if (!this.createProduct.manufacturer_product_code) {
					this.v1.manufacturer_product_code = true;
					isValid = false;
				}

				if (!this.createProduct.distributor_product_code) {
					this.v1.distributor_product_code = true;
					isValid = false;
				}

				if (!this.createProduct.product_name) {
					this.v1.product_name = true;
					isValid = false;
				}

				if (Number(this.createProduct.retail_price) <= 0) {
					this.v1.retail_price = true;
					isValid = false;
				}

				if (Number(this.createProduct.minimum_stock_level) < 0) {
					this.v1.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async update() {
				this.isBusyModal = true;
				this.isBusy = true;

				if (this.validateUpdate()) {
					var productName = this.getManufacturerProductNameByCode(
						this.updateProduct.manufacturer_product_code,
					);
					this.updateProduct.manufacturer_product_name = productName;

					if (this.updateProduct.errors) {
						this.updateProduct.errors = "";
						this.errorCount--;
					}
					$("#mod-edit").modal("hide");
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateUpdate() {
				this.v2 = {
					manufacturer_product_code: false,
					distributor_product_code: false,
					product_name: false,
					retail_price: false,
					minimum_stock_level: false,
					is_valid: true,
				};
				var isValid = true;

				if (!this.updateProduct.manufacturer_product_code) {
					this.v2.manufacturer_product_code = true;
					isValid = false;
				}

				if (!this.updateProduct.distributor_product_code) {
					this.v2.distributor_product_code = true;
					isValid = false;
				}

				if (!this.updateProduct.product_name) {
					this.v2.product_name = true;
					isValid = false;
				}

				if (Number(this.updateProduct.retail_price) <= 0) {
					this.v2.retail_price = true;
					isValid = false;
				}

				if (Number(this.updateProduct.minimum_stock_level) < 0) {
					this.v2.minimum_stock_level = true;
					isValid = false;
				}

				var manufacturerId = this.getManufacturerProductIdByCode(
					this.updateProduct.manufacturer_product_code,
				);
				if (!manufacturerId || manufacturerId === "") {
					this.v2.manufacturer_product_code = true;
					isValid = false;
				}

				return isValid;
			},

			async getManufacturerProducts() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Products/getListNoPagingForManufacturer?active=true" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : "") +
							(this.groupId ? "&groupId=" + this.groupId : ""),
					)
					.then(function(res) {
						this.manufacturerProducts = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			getTypes() {
				this.types = ["Finite", "Infinite"];
			},

			getCategories() {
				var t = "Product Category";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.categories = res.body;
					})
					.catch(function() {});
			},
			getUnits() {
				var t = "Product Unit";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.units = res.body;
					})
					.catch(function() {});
			},

			getManufacturerProductNameByCode(code) {
				var product = this.manufacturerProducts.find(x => x.code === code);
				if (product == null || product == undefined) return "";
				else return product.name + " (" + code + ")";
			},

			getManufacturerProductNameById(id) {
				var product = this.manufacturerProducts.find(x => x.id === id);
				if (product == null || product == undefined) return "";
				else return product.name + " (" + product.code + ")";
			},

			getManufacturerProductIdByCode(code) {
				var product = this.manufacturerProducts.find(x => x.code === code);
				if (product == null || product == undefined) return "";
				else return product.id;
			},

			formatDate(date) {
				if (date) return moment(date).format("lll");
				else return "";
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},
		},
	};
</script>
