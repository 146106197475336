<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="tabs-section">
				<div class="tab">
					<button
						class="tablinks"
						@click.prevent="openTab($event, 'Pending')"
						id="defaultOpen"
					>
						Pending Reconciliation<span
							class="badge badge-danger"
							style="border-radius:50%; padding:2px"
							>{{ pendingOrder.count }}</span
						>
					</button>
					<button class="tablinks" @click.prevent="openTab($event, 'reconciled')">
						Reconciled Orders
					</button>
				</div>
				<div id="Pending" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
							<button
								class="btn btn-success btn-rounded btn-space"
								@click="viewFilterPR"
							>
								<span class="feather icon-filter text-white"></span> Filter
							</button>
						</div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<div class="row be-datatable-body">
									<div class="col-sm-12 table-responsive">
										<table
											class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation table-bordered
                  "
										>
											<thead>
												<tr>
													<th style="width: 10%">Product</th>
													<th style="width: 15%">Order Number</th>
													<th style="width: 15%">Fulfiled Date</th>
													<th style="width: 15%">Customer Name</th>
													<th style="width: 15%">Quantity Reconciled</th>
													<th style="width: 10%">Order Value (₦)</th>
													<th style="width: 10%">Reconcile Type</th>
													<th style="width: 10%"></th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="prs in pendingOrdersToStocks"
													:key="prs.id"
												>
													<td>
														{{ prs.productName }} ({{
															prs.productCode
														}})
													</td>
													<td>
														{{ prs.orderNumber }}
													</td>
													<td>{{ formatDate(prs.fulfilledDate) }}</td>
													<td>{{ prs.customerName }}</td>
													<td>{{ prs.quantity }}</td>
													<td>{{ formatMoney(prs.sellPrice) }}</td>
													<td>
														{{ prs.reconciliationMode || "MANUAL" }}
													</td>
													<td>
														<div
															class="text-right"
															v-if="
																hasPermission(
																	'Inventory_Reconcile_Stock_Update',
																)
															"
														>
															<button
																v-if="prs.status == 'Fulfilled'"
																class="btn btn-success btn-sm btn-rounded btn-space"
																@click.prevent="viewEdit(prs)"
															>
																Reconcile Stock
															</button>

															<button
																v-else
																class="btn btn-primary btn-sm btn-rounded btn-space"
																disabled
															>
																{{ prs.status }}
															</button>
														</div>
													</td>
												</tr>
												<tr v-if="!pendingOrdersToStocks.length">
													<td colspan="8">
														<div
															class="text-center"
															style="padding-top: 50px"
														>
															<span
																style="font-size: 4.615rem"
																class="mdi mdi-crosshairs-gps"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom: 100px"
														>
															You don't have any orders to reconcile.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="pendingOrder.pageSize"
											:count="pendingOrder.pageCount"
											:page="pendingOrder.page"
											:itemsCount="pendingOrder.count"
											@changePage="gotoPagePending"
											@changeLimit="changeLimitPending"
										/>
									</div>
								</div>
								<div class="row be-datatable-footer">
									<div class="col-sm-5">
										<div class="dataTables_info">
											{{ pendingOrder.count }} Pending Reconciliation Item{{
												pendingOrder.count != 1 ? "s" : ""
											}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								></circle>
							</svg>
						</div>
					</div>
				</div>

				<div id="reconciled" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
							<button
								class="btn btn-success btn-rounded btn-space"
								@click="viewFilter"
							>
								<span class="feather icon-filter text-white"></span> Filter
							</button>
						</div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<div class="row be-datatable-body">
									<div class="col-sm-12 table-responsive">
										<table
											class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation table-bordered
                  "
										>
											<thead>
												<tr>
													<th style="width: 10%">Product</th>
													<th style="width: 15%">Order Number</th>
													<th style="width: 15%">Fulfiled Date</th>
													<th style="width: 15%">Customer Name</th>
													<th style="width: 15%">Quantity</th>
													<th style="width: 10%">Order Value (₦)</th>
													<th style="width: 10%">Reconcile Type</th>
													<th style="width: 10%"></th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="l in ReconcileOrdersToStocks"
													:key="l.id"
												>
													<td>
														{{ l.productName }} ({{ l.productCode }})
													</td>
													<td>
														{{ l.orderNumber }}
													</td>
													<td>{{ formatDate(l.fulfilledDate) }}</td>
													<td>{{ l.customerName }}</td>
													<td>{{ l.quantity }}</td>
													<td>{{ formatMoney(l.sellPrice) }}</td>
													<td>{{ l.reconciliationMode || "MANUAL" }}</td>
													<td>
														<button
															class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
															type="button"
															id="dropdownMenu2"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															Actions
														</button>
														<div
															class="dropdown-menu px-1"
															aria-labelledby="dropdownMenu2"
														>
															<div>
																<a
																	class="dropdown-item"
																	href="#"
																	@click.prevent="
																		getOrderreconcilationDetails(
																			l.orderDetailId,
																		)
																	"
																>
																	<i class="feather icon-eye"></i>
																	&nbsp;View Details
																</a>
															</div>
														</div>
													</td>
												</tr>
												<tr v-if="!ReconcileOrdersToStocks.length">
													<td colspan="8">
														<div
															class="text-center"
															style="padding-top: 50px"
														>
															<span
																style="font-size: 4.615rem"
																class="mdi mdi-crosshairs-gps"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom: 100px"
														>
															You don't have any orders to reconcile.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="reconciledOrder.pageSize"
											:count="reconciledOrder.pageCount"
											:page="reconciledOrder.page"
											:itemsCount="reconciledOrder.count"
											@changePage="gotoPage"
											@changeLimit="changeLimit"
										/>
									</div>
								</div>
								<div class="row be-datatable-footer">
									<div class="col-sm-5">
										<div class="dataTables_info">
											{{ reconciledOrder.count }} Reconciliation Item{{
												reconciledOrder.count != 1 ? "s" : ""
											}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								></circle>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Reconcile Orders To Stock</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="cancel"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row" v-if="!v1.is_valid">
							<div class="col-12 col-sm-12 col-lg-12">
								<label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{
									v1.text
								}}</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-4 col-sm-12 col-form-label text-sm-left"
								><b>Customer:</b>
								{{ updateReconcileOrdersToStock.customerName }}</label
							>
							<label class="col-4 col-sm-6 col-form-label text-sm-left"
								><b>Product:</b> {{ updateReconcileOrdersToStock.productName }} ({{
									updateReconcileOrdersToStock.productUnit
								}})
							</label>
							<label class="col-4 col-sm-6 col-form-label text-sm-left"
								><b>Qty:</b> {{ updateReconcileOrdersToStock.quantity }}</label
							>
						</div>
						<div class="form-group row">
							<div class="col-12 col-sm-12 col-lg-12 table-responsive">
								<table
									class="table table-fw-widget dataTable no-footer text-nowrap"
								>
									<thead>
										<tr>
											<th style="width: 15%">Inventory Id</th>
											<th style="width: 20%">Location</th>
											<th style="width: 15%">Product Batch Id</th>
											<th style="width: 10%">Expiry Date</th>
											<th style="width: 15%">Cost Price</th>
											<th style="width: 10%">Qty</th>
											<th style="width: 15%">Sales</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(l,
											key) in updateReconcileOrdersToStock.productInventories"
											:key="l.id"
										>
											<td>
												{{ l.inventoryId }}
											</td>
											<td>{{ l.warehouseName }}, {{ l.storageAreaName }}</td>
											<td>
												{{ l.productBatchId }}
											</td>
											<td>
												{{ l.expiryDate ? formatDate(l.expiryDate) : "" }}
											</td>
											<td>{{ l.costPrice }}</td>
											<td>{{ l.quantityInStock }}</td>
											<td>
												<input
													:class="[
														'form-control',
														{
															'is-invalid': !v1.quantities[key]
																.quantity.is_valid,
														},
													]"
													type="number"
													v-model="l.quantitySold"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="cancel"
						>
							Cancel
						</button>
						<button
							style="width: 150px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="reconcile"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Reconcile Stock</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Filter</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product name..."
									v-model="filter.pn"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Code</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product code..."
									v-model="filter.pc"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Order Number</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product code..."
									v-model="filter.on"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Customer Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select a customer"
									v-model="filter.cn"
								>
									<option v-for="c in customers" :key="c.id" :value="c.id">
										{{ c.text }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Reconcile Type</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.reconcileType"
								>
									<option key="0" value="MANUAL">Manual</option>
									<option key="1" value="AUTOMATIC">Automatic</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.startDate"
								/>
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.endDate"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getReconcileOrdersToStocks"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-filterPR"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><b>Filter</b></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product name..."
									v-model="filter1.pn"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Code</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product code..."
									v-model="filter1.pc"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Order Number</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Enter product code..."
									v-model="filter1.on"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Customer Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select a customer"
									v-model="filter1.cn"
								>
									<option v-for="c in customers" :key="c.id" :value="c.id">
										{{ c.text }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter1.startDate"
								/>
							</div>

							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter1.endDate"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="resetPR"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getPendingOrder"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-details"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Stock Details</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="p-2 col-12">
							<div class="col-12 d-flex align-items-center py-2">
								<div class="col-6">
									<h6 class="font-weight-bold">
										Customer
										<span class="font-weight-light">{{
											reconciledDetails.name
										}}</span>
									</h6>
								</div>
								<div class="col-6">
									<h6 class="font-weight-bold">
										Code
										<span class="font-weight-light">{{
											reconciledDetails.code
										}}</span>
									</h6>
								</div>
							</div>
							<div class="col-12 d-flex align-items-center">
								<div class="col-6">
									<h6 class="font-weight-bold">
										Product
										<span class="font-weight-light">{{
											reconciledDetails.product
										}}</span>
									</h6>
								</div>
								<div class="col-6">
									<h6 class="font-weight-bold">
										Quantity Reconciled<span class="font-weight-light">
											{{ reconciledDetails.quantity }}</span
										>
									</h6>
								</div>
							</div>
							<div class="col-12 py-3">
								<table
									class="table table-fw-widget dataTable no-footer text-nowrap"
								>
									<thead>
										<tr>
											<th style="width: 15%">Inventory Id</th>
											<th style="width: 20%">Location</th>
											<th style="width: 15%">Product Batch Id</th>
											<th style="width: 10%">Expiry Date</th>
											<th style="width: 15%">Cost Price</th>
											<th style="width: 10%">Qty</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(rd, index) in reconciledDetails.list"
											:key="index"
										>
											<td>
												{{ rd.inventoryId }}
											</td>
											<td>{{ rd.location }}</td>
											<td>
												{{ rd.batchId ? rd.batchId : "Nill" }}
											</td>
											<td>
												{{ rd.expiryDate ? formatDate(rd.expiryDate) : "" }}
											</td>
											<td>{{ rd.costPrice }}</td>
											<td>{{ rd.quantityReconciled }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-warning"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Stock Details</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<p>{{}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import Pagination from "../components/Pagination.vue";
	import SystemPermissions from "@/permissions";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "settingsReconcileOrdersToStocks",
		components: {
			Select2,
			Pagination,
		},

		created() {
			if (!this.hasPermission("Inventory_Reconcile_Stock_View")) {
				this.$router.push("/restricted");
			}
			this.getCustomers();
			this.getPendingOrder();
			this.getReconcileOrdersToStocks();
		},

		data() {
			return {
				filter: {
					pn: "",
					pc: "",
					cn: "",
					on: "",
					isReconciled: "0",
					reconcileType: "",
					startDate: "",
					endDate: "",
				},
				filter1: {
					pn: "",
					pc: "",
					cn: "",
					on: "",
					isReconciled: "0",
					reconcileType: "",
					startDate: "",
					endDate: "",
				},
				customers: [],

				reconciledDetails: {
					name: "",
					code: "",
					product: "",
					quantity: "",
					list: [],
				},

				isBusy: false,
				isBusyModal: false,
				updateReconcileOrdersToStock: {
					productCode: null,
					orderId: null,
					orderName: null,
					orderDetailId: null,
					quantity: null,
					fulfilledDate: null,
					customerName: null,
					sellPrice: null,
					productInventories: [],
					id: null,
				},

				v1: {
					quantities: [],
					is_valid: true,
					text: "",
				},

				ReconcileOrdersToStocks: [],
				pendingOrdersToStocks: [],
				reconciledOrder: {
					count: 0,
					page: 1,
					pageSize: 10,
					pageCount: 0,
					hasNext: false,
					hasPrev: false,
				},
				pendingOrder: {
					count: 0,
					page: 1,
					pageSize: 10,
					pageCount: 0,
					hasNext: false,
					hasPrev: false,
				},
				options: {
					format: "YYYY-MM-DD",
					icons: {
						time: "mdi mdi-time",
						date: "mdi mdi-calendar",
						up: "mdi mdi-chevron-up",
						down: "mdi mdi-chevron-down",
						previous: "mdi mdi-chevron-left",
						next: "mdi mdi-chevron-right",
						today: "mdi mdi-screenshot",
						clear: "mdi mdi-trash",
						close: "mdi mdi-remove",
					},
				},

				local: {
					dow: 0, // Sunday is the first day of the week
					hourTip: "Select Hour", // tip of select hour
					minuteTip: "Select Minute", // tip of select minute
					secondTip: "Select Second", // tip of select second
					yearSuffix: "", // suffix of head year
					monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
						"_",
					), // months of head
					months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
					weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
					cancelTip: "cancel",
					submitTip: "confirm",
				},
				maxDate: "",
				warningMessage:"This is a message from the backend"
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
			this.openDefaultTab();
			this.toggleWarning()
		},

		methods: {
			reset() {
				this.filter = {
					pn: "",
					pc: "",
					cn: "",
					on: "",
					isReconciled: "0",
					reconcileType: "",
					startDate: "",
					endDate: "",
				};
				this.getReconcileOrdersToStocks();
			},
			resetPR() {
				this.filter1 = {
					pn: "",
					pc: "",
					cn: "",
					on: "",
					isReconciled: "0",
					reconcileType: "",
					startDate: "",
					endDate: "",
				};
				this.getPendingOrder();
			},

			toggleWarning(){
				$('mod-warning').modal('show')
			},

			async getCustomers() {
				await this.$http
					.get("/Users/getItemListOfCustomers/" + this.user.parentId)
					.then(function(res) {
						this.customers = res.body;
					})
					.catch(function() {});
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},
			viewDetails(c) {
				$("#mod-details").modal("show");
			},

			viewEdit(c) {
				this.updateReconcileOrdersToStock = c;
				for (
					let i = 0;
					i < this.updateReconcileOrdersToStock.productInventories.length;
					i++
				) {
					this.v1.quantities.push({
						quantity: {
							is_valid: true,
							text: "",
						},
					});
				}

				$("#mod-edit").modal("show");
			},

			cancel() {
				for (let i = 0; i < this.v1.quantities.length; i++) {
					this.v1.quantities[i].quantity.is_valid = true;
					this.v1.quantities[i].quantity.text = "";
				}

				this.v1.text = "";
				this.v1.is_valid = true;
			},

			viewFilter() {
				this.page = 0;
				$("#mod-filter").modal("show");
			},
			viewFilterPR() {
				this.page = 0;
				$("#mod-filterPR").modal("show");
			},

			async reconcile() {
				this.isBusyModal = true;
				this.isBusy = true;

				var data = {
					orderId: this.updateReconcileOrdersToStock.orderId,
					inventoryItems: this.updateReconcileOrdersToStock.productInventories,
				};

				if (this.validateUpdate()) {
					$("#mod-edit").modal("hide");

					await this.$http
						.post("/Orders/reconciliation", data)
						.then(function(res) {
							this.isBusyModal = false;
							this.getCustomers();
							this.getPendingOrder();
							this.getReconcileOrdersToStocks();
						})
						.catch(function() {});
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateUpdate() {
				for (let i = 0; i < this.v1.quantities.length; i++) {
					this.v1.quantities[i].quantity.is_valid = true;
					this.v1.quantities[i].quantity.text = "";
				}

				this.v1.text = "";
				this.v1.is_valid = true;
				var isValid = true;

				let total = 0;
				this.updateReconcileOrdersToStock.productInventories.forEach(item => {
					total += parseFloat(item.quantitySold);
				});
				
				if (total === 0) {
					this.v1.is_valid = false;
					this.v1.text = "Total quantities sold cannot be zero";
					isValid = false;
				}

				if (isValid) {
					for (
						let k = 0;
						k < this.updateReconcileOrdersToStock.productInventories.length;
						k++
					) {
						let qtySold = parseInt(
							this.updateReconcileOrdersToStock.productInventories[k].quantitySold,
						);
						if (!Number.isInteger(qtySold)) {
							/*
	                           this.v1.quantities[k].quantity.text = 'Please enter a valid number'; */
							this.v1.quantities[k].quantity.is_valid = false;
							this.v1.is_valid = false;
							this.v1.text = "Please enter a valid number";
							isValid = false;
						} else {
							if (
								this.updateReconcileOrdersToStock.productInventories[k]
									.quantitySold >
								this.updateReconcileOrdersToStock.productInventories[k]
									.quantityInStock
							) {
								/*
	                               this.v1.quantities[k].quantity.text = 'Quantity sold cannot be more than quantity in stock'; */
								this.v1.quantities[k].quantity.is_valid = false;
								this.v1.is_valid = false;
								this.v1.text =
									"Quantity sold cannot be more than quantity in stock";
								isValid = false;
							}
							if (
								this.updateReconcileOrdersToStock.productInventories[k]
									.quantitySold > this.updateReconcileOrdersToStock.quantity
							) {
								/*
	                               this.v1.quantities[k].quantity.text = 'Quantity sold cannot be more than quantity in this order'; */
								this.v1.quantities[k].quantity.is_valid = false;
								this.v1.is_valid = false;
								this.v1.text =
									"Quantity sold cannot be more than quantity in this order";
								isValid = false;
							}
						}
					}
				}

				if (isValid) {
					let total = 0;
					this.updateReconcileOrdersToStock.productInventories.forEach(item => {
						total += parseInt(item.quantitySold);
					});
					if (total != parseInt(this.updateReconcileOrdersToStock.quantity)) {
						this.v1.is_valid = false;
						this.v1.text = "Total quantities sold must match the order quantity";
						isValid = false;
					}
				}

				return isValid;
			},

			async getReconcileOrdersToStocks() {
				this.isBusy = true;
				await this.$http
					.get(
						"/Orders/getReconciledList?pageNumber=" +
							this.reconciledOrder.page +
							"&pageSize=" +
							this.reconciledOrder.pageSize +
							(this.filter.pn != "" ? "&productName=" + this.filter.pn : "") +
							(this.filter.pc != "" ? "&productCode=" + this.filter.pc : "") +
							(this.filter.cn != "" ? "&customerId=" + this.filter.cn : "") +
							(this.filter.on != "" ? "&orderNumber=" + this.filter.on : "") +
							(this.filter.reconcileType != ""
								? "&ReconicilationType=" + this.filter.reconcileType
								: "") +
							(this.filter.startDate != ""
								? "&startDate=" +
								  moment(this.filter.startDate).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.endDate != ""
								? "&endDate=" +
								  moment(this.filter.endDate).format("YYYY-MM-DD 23:59:59")
								: "") +
							(this.filter.isReconciled != "0"
								? "&isReconciled=" + this.filter.isReconciled
								: ""),
					)
					.then(function(res) {
						this.ReconcileOrdersToStocks = res.body.data;
						this.reconciledOrder.page = res.body.pageNumber;
						this.reconciledOrder.pageSize = res.body.pageSize;
						this.reconciledOrder.count = res.body.count;
						this.reconciledOrder.pageCount = Math.ceil(
							res.body.count / res.body.pageSize,
						);
						this.reconciledOrder.hasPrev = res.body.hasPrev;
						this.reconciledOrder.hasNext = res.body.hasNext;
						this.isBusy = false;
						$("#mod-filter").modal("hide");
					})
					.catch(function() {});
			},
			async getPendingOrder() {
				this.isBusy = true;
				await this.$http
					.get(
						"/Orders/getPendingReconcilationList?pageNumber=" +
							this.pendingOrder.page +
							"&pageSize=" +
							this.pendingOrder.pageSize +
							(this.filter1.pn != "" ? "&productName=" + this.filter1.pn : "") +
							(this.filter1.pc != "" ? "&productCode=" + this.filter1.pc : "") +
							(this.filter1.cn != "" ? "&customerId=" + this.filter1.cn : "") +
							(this.filter1.on != "" ? "&orderNumber=" + this.filter1.on : "") +
							(this.filter1.startDate != ""
								? "&startDate=" +
								  moment(this.filter1.startDate).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter1.endDate != ""
								? "&endDate=" +
								  moment(this.filter1.endDate).format("YYYY-MM-DD 23:59:59")
								: "") +
							"&sellerId=" +
							this.user.parentId +
							(this.filter1.isReconciled != "0"
								? "&isReconciled=" + this.filter1.isReconciled
								: ""),
					)
					.then(function(res) {
						this.pendingOrdersToStocks = res.body.data;
						this.pendingOrder.page = res.body.pageNumber;
						this.pendingOrder.pageSize = res.body.pageSize;
						this.pendingOrder.count = res.body.count;
						this.pendingOrder.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.pendingOrder.hasPrev = res.body.hasPrev;
						this.pendingOrder.hasNext = res.body.hasNext;
						this.isBusy = false;
						$("#mod-filterPR").modal("hide");
					})
					.catch(function() {});
			},
			async getOrderreconcilationDetails(id) {
				this.isBusy = true;
				await this.$http
					.get(`/Orders/getReconciliationDetail/${id}`)
					.then(function(res) {
						this.reconciledDetails.name = res.data.customerName;
						this.reconciledDetails.code = res.data.productCode;
						this.reconciledDetails.product = res.data.productName;
						this.reconciledDetails.quantity = res.data.quantityReconciled;
						this.reconciledDetails.list = res.data.details;
						this.isBusy = false;
						$("#mod-details").modal("show");
					})
					.catch(function() {
						this.isBusy = false;
						$("#mod-details").modal("hide");
					});
			},

			formatDate(date) {
				return moment(date).format("ll");
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			formatNumber(val) {
				return helper.formatNumber(val);
			},

			prevPending() {
				this.pendingOrder.page -= 1;
				this.getPendingOrder();
			},

			nextPending() {
				this.pendingOrder.page += 1;
				this.getPendingOrder();
			},
			gotoPagePending(page) {
				this.pendingOrder.page = page;
				this.getPendingOrder();
			},

			changeLimitPending(limit) {
				this.pendingOrder.pageSize = limit;
				this.getPendingOrder();
			},

			prev() {
				this.reconciledOrder.page -= 1;
				this.getReconcileOrdersToStocks();
			},

			next() {
				this.reconciledOrder.page += 1;
				this.getReconcileOrdersToStocks();
			},

			gotoPage(page) {
				this.reconciledOrder.page = page;
				this.getReconcileOrdersToStocks();
			},

			changeLimit(limit) {
				this.reconciledOrder.pageSize = limit;
				this.getReconcileOrdersToStocks();
			},

			openDefaultTab() {
				document.getElementById("defaultOpen").click();
			},
			openTab(evt, cityName) {
				var i, tabcontent, tablinks;
				tabcontent = document.getElementsByClassName("tabcontent");
				for (i = 0; i < tabcontent.length; i++) {
					tabcontent[i].style.display = "none";
				}
				tablinks = document.getElementsByClassName("tablinks");
				for (i = 0; i < tablinks.length; i++) {
					tablinks[i].className = tablinks[i].className.replace(" active", "");
				}
				document.getElementById(cityName).style.display = "block";
				evt.currentTarget.className += " active";
			},
		},
	};
</script>

<style scoped>
	.overflow-custom {
		overflow-y: scroll !important;
	}
	.vh-50 {
		height: 50vh !important;
	}

	/* Style the tab */
	.tab {
		overflow: hidden;
		display: flex;
		padding: 15px;
		/* border: 1px solid #ccc; */
		/* background-color: #fff; */
	}

	/* Style the buttons inside the tab */
	.tab button {
		width: 50%;
		background-color: inherit;
		text-align: center;
		float: left;
		border: none;
		border-bottom: 2px solid #ccc;
		outline: none;
		cursor: pointer;
		padding: 14px 16px;
		font-size: 14px !important;
		transition: 0.3s;
		font-size: 17px;
	}

	/* Change background color of buttons on hover */
	.tab button:hover {
		/* background-color: #766cea; */
		color: #766cea !important;
	}

	/* Create an active/current tablink class */
	.tab button.active {
		border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}

	/* Style the tab content */
	.tabcontent {
		display: none;
		padding: 6px 12px;
		/* border: 1px solid #ccc; */
		border-top: none;
	}
</style>
