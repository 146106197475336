<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <!-- <div class="card-header"></div> -->
        <div class="card-body">
          <form>
            <h4>Business profile</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Name</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="account.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input :class="['form-control']" type="text" v-model="account.code" readonly />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Description</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.description }]"
                  v-model="account.description"
                ></textarea>
                <ul v-if="v1.description" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Registration</label
              >
              <div class="col-12 col-sm-4 col-lg-3">
                <input
                  class="form-control"
                  type="text"
                  v-model="account.rcNumber"
                  placeholder="RC Number"
                  readonly
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-3">
                <input
                  class="form-control"
                  type="text"
                  v-model="account.tin"
                  placeholder="Tax Identification Number"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Website</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.websiteValid }]"
                  type="text"
                  v-model="account.website"
                />
                <ul v-if="v1.websiteValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid url.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.emailAddress },
                    { 'is-invalid': v1.emailAddressValid },
                  ]"
                  type="email"
                  v-model="account.emailAddress"
                />
                <ul v-if="v1.emailAddress" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v1.emailAddressValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.phoneNumber },
                    { 'is-invalid': v1.phoneNumberValid },
                  ]"
                  type="text"
                  v-model="account.phoneNumber"
                />
                <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v1.phoneNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Address</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select class="form-control" placeholder="State" v-model="account.state">
                  <option v-for="s in states" :key="s" :value="s">{{ s }}</option>
                </select>
                <ul v-if="v1.state" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  placeholder="Local Government Area"
                  v-model="account.lga"
                  :class="['form-control', { 'is-invalid': v1.lga }]"
                >
                  <option v-for="(l, i) in lgas" :key="i" :value="l">{{ l }}</option>
                </select>
                <ul v-if="v1.lga" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.city }]"
                  type="text"
                  v-model="account.city"
                  placeholder="City"
                />
                <ul v-if="v1.city" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.address1 }]"
                  type="text"
                  v-model="account.address1"
                  placeholder="Address Line 1"
                />
                <ul v-if="v1.address1" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  v-model="account.address2"
                  placeholder="Address Line 2"
                />
              </div>
            </div>

            <hr />

            <h4>Business profile</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Contact Person's Name</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input :class="['form-control']" type="text" v-model="contact.name" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Email Address</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v2.emailAddressValid }]"
                  type="email"
                  v-model="contact.emailAddress"
                />
                <ul v-if="v2.emailAddressValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone Number</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v2.phoneNumberValid }]"
                  type="text"
                  v-model="contact.phoneNumber"
                />
                <ul v-if="v2.phoneNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>

            <hr />
<!-- 
            <h4>Business Logo</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Upload Business Logo</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input :class="['form-control']" type="file" @change="previewFiles" />
              </div>
            </div>

              <hr /> -->

            <h4>Business Logo</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Upload Business Logo</label
              >
              <div v-if="updatingLogo" class="col-12 col-sm-2">
                <img alt="business logo" class="logoImage" :src="previewLogo"/>
              </div>
              <div v-else class="col-12 col-sm-2" @click="openPreview">
                <img v-if="businessLogoUrl" alt="business logo"  class="logoImage" :src="`${businessLogoUrl}`"/>
                <img v-else alt="logo placeholder"  class="logoImage" src="/assets/img/logo-placeholder.png"/>
              </div>
              <div class="col-12 col-sm-6 col-lg-6">
                <input type="file" ref="file" accept="image/*" @change="previewFiles" style="display: none">
                <button @click.prevent="$refs.file.click()" class="btn btn-space btn-primary mt-2" >Upload New Logo</button>
              </div>
            </div>

            <!-- <div class="form-group row">
              <crop-image @update:objectUrl="cropImage($event)"/>
            </div> -->

            <!-- <hr />

            <h4>Bank information</h4> -->

            <!-- <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Bank</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select class="form-control" placeholder="Select an option" v-model="bank.name"> -->
            <!-- <select :class="['form-control', { 'is-invalid':v3.name }]" v-model="bank.name"> -->
            <!-- <option v-for="b in banks" :key="b" :value="b">{{ b }}</option>
                </select>
                <ul v-if="v3.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div> -->
            <!-- <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Account Number</label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v3.accountNumber },
                    { 'is-invalid': v3.accountNumberValid },
                  ]"
                  type="text"
                  v-model="bank.accountNumber"
                />
                <ul v-if="v3.accountNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v3.accountNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid account number.</li>
                </ul>
              </div>
            </div> -->
            <div class="form-group row" v-if="hasPermission('BusinessProfile_Update')">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <button
                  style="width: 150px;"
                  class="btn btn-space btn-primary mt-3"
                  @click.prevent="update"
                >
                  <span v-if="isBusyUpdate">Busy...</span>
                  <span v-else>Save changes</span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>

        <div
                class="modal fade colored-header colored-header-primary"
                id="mod-logo-preview"
                role="dialog"
              >
             <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header modal-header-colored">
                    <h4 class="modal-title p-1">
                      <strong>Business Logo</strong>
                    </h4>
                    <div class="mt-1">
                      <i style="color:black; font-size:20px" class="feather icon-x" @click="closePreview"></i>
                    </div>
                  </div>
                  <div class="modal-body">
                       <div v-if="updatingLogo" class="col-12 text-center p-5">
                        <img alt="business logo" width="350px" height="350px" :src="previewLogo"/>
                      </div>
                       <div v-else class="col-12 text-center p-5" >
                          <img v-if="businessLogoUrl" alt="business logo" width="350px" height="350px" :src="`${businessLogoUrl}`"/>
                          <img v-else alt="logo placeholder"  class="logoImage" src="/assets/img/logo-placeholder.png"/>
                      </div>
                  </div>
                  <div class="modal-footer">
                    <button v-if="updatingLogo" class="btn btn-md btn-primary" color="blue" dark @click="updatingImage">Save</button>
<!-- 
                    <button class="btn btn-sm btn-error" @click="closePreview"
                      >Cancel</button> -->
                  </div>
                </div>
              </div>
          </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Select2 from "@/components/Select2";
import CropImage from "@/components/LogoCropper/ImageCrop";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsBusiness",

  components: {
    Select2,
    CropImage
  },
  data() {
    return {
      isBusy: false,
      isBusyUpdate: false,
      account: {},
      contact: {},
      bank: {},
      banks: helper.getBanks(),
      states: helper.getStates(),
      businessLogo: {},
      businessLogoUrl: "",
      v1: {
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        state: false,
      },
      v2: {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      },
      v3: {
        name: false,
        accountNumber: false,
        accountNumberValid: false,
      },
      updatingLogo: false,
      previewLogo: null,
      croppedLogo: null,
      
      // //business logo cropper
      // imgSrc: "",
      // cropImg: null,
      // dialog: false,
      // file: null,
      // filename: null,
      // cropBlob: null,
    };
  },
 
  created() {
    if (!this.hasPermission("BusinessProfile_View")) {
      this.$router.push("/restricted");
    }
    this.getAccount();
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    lgas: function() {
      return helper.getLgas(this.account.state);
    },

    //business logo cropper
    // croppedFile() {
    //   return new File([this.cropImg], this.file.name, { type: this.file.type });
    // },
    // cropSrc() {
    //   return this.cropImg || this.value;
    // },
  },
  methods: {
    cropImage(value) {
        this.croppedLogo = value;
    },
    previewFiles(event) {
      event.preventDefault();
      let file = event.target.files[0];
      this.businessLogo = event.target.files[0];
      this.previewLogo = URL.createObjectURL(file);
      this.updatingLogo = true;
      $("#mod-logo-preview").modal("show");

    },
    openPreview(){
      $("#mod-logo-preview").modal("show");
    },
    closePreview(){
      $("#mod-logo-preview").modal("hide");
    },
    updatingImage(){
      this.updatingLogo = true;
      $("#mod-logo-preview").modal("hide");
    },
    getAccount() {
      this.isBusy = true;

      this.$http
        .get("/Accounts/getCurrent")
        .then(function(res) {
          this.businessLogoUrl = res.body.logoURL;
          this.account = {
            id: res.body.id,
            name: res.body.name,
            description: res.body.description,
            emailAddress: res.body.emailAddress,
            phoneNumber: res.body.phoneNumber,
            website: res.body.website,
            address1: res.body.address1,
            address2: res.body.address2,
            city: res.body.city,
            lga: res.body.lga,
            state: res.body.state,
            rcNumber: res.body.rcNumber,
            tin: res.body.tin,
            code: res.body.code,
          };

          this.contact = {
            id: res.body.id,
            name: res.body.contact.name,
            emailAddress: res.body.contact.emailAddress,
            phoneNumber: res.body.contact.phoneNumber,
          };

          this.bank = {
            id: res.body.id,
            name: res.body.bank.name,
            bankCode: res.body.bank.bankCode,
            accountNumber: res.body.bank.accountNumber,
          };

          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },
    update() {
      this.isBusyUpdate = true;

      if (this.validateUpdate()) {
        // this.bank.bankCode = helper.getBankCode(this.bank.name);
        var data = {
          Id: this.account.id,
          Name: this.account.name,
          Description: this.account.description,
          EmailAddress: this.account.emailAddress,
          PhoneNumber: this.account.phoneNumber,
          Website: this.account.website,
          Address1: this.account.address1,
          Address2: this.account.address2,
          City: this.account.city,
          Lga: this.account.lga,
          State: this.account.state,
          RcNumber: this.account.rcNumber,
          Tin: this.account.tin,
          // bank: this.bank,
          ContactId: this.contact.id,
          ContactName: this.contact.name,
          ContactEmailAddress: this.contact.emailAddress,
          ContactPhoneNumber: this.contact.phoneNumber,

          // LogoFile: this.croppedLogo ? this.croppedLogo : null,
          LogoFile: this.businessLogo.name ? this.businessLogo : null,
        };
        
        const formData = new FormData();

        for ( var key in data ) {
          formData.append(key, data[key]);
        }

        this.$http
          .put("/Accounts/updateAlt/" + data.Id, formData)
          .then(function(res) {
            $.gritter.add({
              title: "Business profile updated",
              text: "Your business profile has been updated.",
              class_name: "color success",
            });
            this.$toast.success("Profile updated successfully.", {
              icon: true,
              rtl: false,
            });
            this.isBusyUpdate = false;
          })
          .catch(function() {
            this.isBusyUpdate = false;
          });
      } else {
        this.isBusyUpdate = false;
      }
    },
    validateUpdate() {
      this.v1 = {
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        state: false,
      };
      var isValid = true;

      if (!this.account.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.account.description) {
        this.v1.description = true;
        isValid = false;
      }

      if (this.account.website && !helper.validateUrl(this.account.website)) {
        this.v1.websiteValid = true;
        isValid = false;
      }

      if (!this.account.emailAddress) {
        this.v1.emailAddress = true;
        isValid = false;
      }

      if (this.account.emailAddress && !helper.validateEmail(this.account.emailAddress)) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (!this.account.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (this.account.phoneNumber && !helper.validatePhone(this.account.phoneNumber)) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      if (!this.account.address1) {
        this.v1.address1 = true;
        isValid = false;
      }

      if (!this.account.city) {
        this.v1.city = true;
        isValid = false;
      }

      if (!this.account.state) {
        this.v1.state = true;
        isValid = false;
      }

      this.v2 = {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      };

      if (!this.contact.name) {
        this.v2.name = false;
        isValid = true;
      }

      if (!this.contact.emailAddress) {
        this.v2.emailAddress = false;
        isValid = true;
      }

      if (this.contact.emailAddress && !helper.validateEmail(this.contact.emailAddress)) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (!this.contact.phoneNumber) {
        this.v2.phoneNumber = false;
        isValid = true;
      }

      if (this.contact.phoneNumber && !helper.validatePhone(this.contact.phoneNumber)) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      // this.v3 = {
      //   name: false,
      //   accountNumber: false,
      // };

      // if (!this.bank.name) {
      //   this.v3.name = true;
      //   isValid = false;
      // }

      // if (!this.bank.accountNumber) {
      //   this.v3.accountNumber = true;
      //   isValid = false;
      // }

      // if (this.bank.accountNumber && !helper.validateNuban(this.bank.accountNumber)) {
      //   this.v3.accountNumberValid = true;
      //   isValid = false;
      // }

      return isValid;
    },

    // //business logo cropper
    // setImage(e) {
    //   // const file = e;
    //   const file = e.target.files[0];
    //   // this.file = file
    //   this.filename = file.name;

    //   if (!file.type.includes("image/")) {
    //     alert("Please select an image file");
    //     return;
    //   }

    //   if (typeof FileReader === "function") {
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       this.imgSrc = event.target.result;
    //       // rebuild cropperjs with the updated source
    //       this.$refs.cropper.replace(event.target.result);
    //       this.$emit("update:dataUrl", this.imgSrc);
    //     };

    //     reader.readAsDataURL(file);
    //     // this.dialog = true;
    //     $("#mod-logo").modal("show");

    //   } else {
    //     alert("Sorry, FileReader API not supported");
    //   }
    // },
    // cropImage() {
    //   debugger
    //   // get image data for post processing, e.g. upload or setting image src
    //   // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    //   this.$refs.cropper
    //     .getCroppedCanvas({
    //       maxWidth: this.maxWidth,
    //       maxHeight: this.maxHeight,
    //     })
    //     .toBlob(
    //       (blob) => {
    //         this.cropImg = URL.createObjectURL(blob);
    //         this.croppedBlob = blob;
    //         this.$emit("update:objectUrl", this.cropImg);
    //       },
    //       "image/jpeg",
    //       0.95
    //     );
    //   // this.dialog = false;
    //   $("#mod-logo").modal("hide");
    // },
    // rotate(dir) {
    //   if (dir === "r") {
    //     this.$refs.cropper.rotate(90);
    //   } else {
    //     this.$refs.cropper.rotate(-90);
    //   }
    // },
    // flip(vert) {
    //   let { scaleX, scaleY, rotate } = this.$refs.cropper.getData();
    //   // when image is rotated, direction must be flipped
    //   if (rotate === 90 || rotate === 270) {
    //     vert = !vert;
    //   }
    //   if (vert) {
    //     this.$refs.cropper.scale(scaleX, -1 * scaleY);
    //   } else {
    //     this.$refs.cropper.scale(-1 * scaleX, scaleY);
    //   }
    // },
  
  },
};
</script>

<style scoped>
 .logoImage {
   border-radius: 50%;
   width: 80px;
   height: 80px;
 }
/* .v-icon.v-icon.v-icon--link {
  padding: 0 10px;
} */
</style>
