<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">&nbsp;</div>
          <div class="card-body">
            <form>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Name</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid': v1.name }]"
                    type="text"
                    v-model="campaign.name"
                  />
                  <ul v-if="v1.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Type</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <select
                    class="form-control"
                    placeholder="Select an option"
                    v-model="campaign.type"
                  >
                    <!-- <select :class="['form-control', { 'is-invalid':v1.type }]" v-model="campaign.type"> -->
                    <option v-for="t in types" :key="t" :value="t">
                      {{ t }}
                    </option>
                  </select>
                  <ul v-if="v1.type" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Frequency</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <select
                    class="form-control"
                    placeholder="Select an option"
                    v-model="campaign.frequency"
                  >
                    <!-- <select :class="['form-control', { 'is-invalid':v1.frequency }]" v-model="campaign.frequency"> -->
                    <option v-for="f in frequencies" :key="f" :value="f">
                      {{ f }}
                    </option>
                  </select>
                  <ul v-if="v1.frequency" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Message</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <textarea
                    rows="5"
                    :class="['form-control', { 'is-invalid': v1.message }]"
                    v-model="campaign.message"
                  ></textarea>
                  <ul v-if="v1.message" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Starts</label
                >
                <div class="col-12 col-sm-4 col-lg-3">
                  <input
                    :class="['form-control', { 'is-invalid': v1.starts }]"
                    type="date"
                    :max="maxDate"
                    placeholder="Select a date"
                    v-model="campaign.starts"
                  />

                  <ul v-if="v1.starts" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Ends</label
                >
                <div class="col-12 col-sm-4 col-lg-3">
                  <input
                    :class="['form-control', { 'is-invalid': v1.ends }]"
                    type="date"
                    :max="maxDate"
                    placeholder="Select a date"
                    v-model="campaign.ends"
                  />

                  <ul v-if="v1.ends" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Active</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <label
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      v-model="campaign.active"
                    />
                    <span class="custom-control-label"
                      >This campaign is active</span
                    >
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-12 col-sm-3 col-form-label text-sm-right"
                ></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    style="width: 160px"
                    class="btn btn-space btn-primary"
                    @click.prevent="create"
                  >
                    <span v-if="isBusy">Busy...</span>
                    <span v-else>Save campaign</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Select2 from "@/components/Select2";
// import DatePicker from "vue-bootstrap-datetimepicker";
import SystemPermissions from "@/permissions";
import {helper} from "@/helper";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "campaignsCreate",
  components: {
    Select2,
    // DatePicker
  },
  data() {
    return {
      isBusy: false,
      campaign: {},
      types: ["Email", "SMS"],
      frequencies: ["Daily", "Weekly", "Monthly"],
      v1: {
        name: false,
        type: false,
        frequency: false,
        starts: false,
        ends: false,
      },
      options: {
        format: "YYYY-MM-DD",
        icons: {
          time: "mdi mdi-time",
          date: "mdi mdi-calendar",
          up: "mdi mdi-chevron-up",
          down: "mdi mdi-chevron-down",
          previous: "mdi mdi-chevron-left",
          next: "mdi mdi-chevron-right",
          today: "mdi mdi-screenshot",
          clear: "mdi mdi-trash",
          close: "mdi mdi-remove",
        },
      },
      local: {
        dow: 0, // Sunday is the first day of the week
        hourTip: "Select Hour", // tip of select hour
        minuteTip: "Select Minute", // tip of select minute
        secondTip: "Select Second", // tip of select second
        yearSuffix: "", // suffix of head year
        monthsHead:
          "January_February_March_April_May_June_July_August_September_October_November_December".split(
            "_"
          ), // months of head
        months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
        weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
        cancelTip: "cancel",
        submitTip: "confirm",
      },
      maxDate: "",
    };
  },
  created() {
    if (!this.hasPermission("Campaign_Create")) {
      this.$router.push("/restricted");
    }
    this.initialize();
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},
  methods: {
    initialize() {
      this.campaign = {
        name: null,
        type: null,
        frequency: null,
        starts: null,
        ends: null,
        active: false,
        message: null,
        parentId: this.user.parentId,
      };
    },
    async create() {
      this.isBusy = true;

      if (this.validateCampaign()) {
        await this.$http
          .post("/Campaigns/create", this.campaign)
          .then(function (res) {
            this.isBusy = false;
            this.$router.push("/campaigns");
          })
          .catch(function () {
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
      }
    },
    validateCampaign() {
      this.v1 = {
        name: false,
        type: false,
        frequency: false,
        starts: false,
        ends: false,
        message: false,
      };
      let isValid = true;

      if (!this.campaign.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.campaign.type) {
        this.v1.type = true;
        isValid = false;
      }

      if (!this.campaign.frequency) {
        this.v1.frequency = true;
        isValid = false;
      }

      if (!this.campaign.starts) {
        this.v1.starts = true;
        isValid = false;
      }

      if (!this.campaign.message) {
        this.v1.message = true;
        isValid = false;
      }

      if (!this.campaign.ends) {
        this.v1.ends = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

