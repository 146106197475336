<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex">
          <button
            class="float-left btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <i class="feather icon-filter text-white"></i> Filter
          </button>
          <span>&nbsp;</span>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              class="btn btn-primary btn-rounded btn-space"
              v-if="hasPermission('Group_Create')"
              @click="addGroup"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              New Group
            </button>
            <button
              class="btn btn-info btn-rounded btn-space"
              @click="filterActive"
            >
              Show/Hide Deactivated
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4">
            <div class="row be-datatable-body max-height">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Group Code</th>
                      <th>Created</th>
                      <th>Last Modified</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="g in groups" :key="g.id">
                      <td>{{ g.name }}</td>
                      <td>{{ g.description.substring(0, 40) }}</td>
                      <td>{{ g.code }}</td>
                      <td>{{ formatDate(g.created) }}</td>
                      <td>{{ formatDate(g.updated) }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success': g.active,
                            },
                            {
                              'badge-danger': !g.active,
                            },
                          ]"
                          >{{ g.active ? 'Active' : 'Inactive' }}</span
                        >
                      </td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <a
                            class="dropdown-item"
                            v-if="hasPermission('Group_Update')"
                            href="#"
                            @click.prevent="editGroup(g)"
                          >
                            Edit
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            v-if="hasPermission('Group_View_Members')"
                            :href="`groups/${g.id}/distributor-association`"
                          >
                            Distributor Association
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            v-if="hasPermission('Price_Option_View')"
                            :href="`groups/${g.id}/price-option`"
                          >
                            Price Option
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            v-if="hasPermission('Group_Delete')"
                            href="#"
                            style="color: red"
                            @click.prevent="onActionComplete"
                          >
                            Delete
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} group{{ count != 1 ? 's' : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Filter</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Start Date:</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control']"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a start date"
                  v-model="filter.startDate"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >End Date:</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control']"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a end date"
                  v-model="filter.endDate"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="select2 form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option
                    v-for="opt in options"
                    :key="opt.text"
                    :value="opt.value"
                  >
                    {{ opt.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by product name..."
                  v-model="filter.name"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="resetFilter"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click.prevent="getGroups(true)"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Group
      :group="group"
      :showUpdate="update"
      @hide="hideModal"
      @onComplete="onActionComplete"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Group from './DistributorGroup.vue';
import DeleteGroup from '../../components/Group/DeleteGroup';
import { helper } from '@/helper';
import moment from 'moment';
import SystemPermissions from '@/permissions';
import Pagination from '../../components/Pagination.vue';

export default {
  mixins: [SystemPermissions.Mixin],
  components: { Group, Pagination, DeleteGroup },

  created() {
    if (!this.hasPermission('Group_View')) {
      this.$router.push('/restricted');
    }
    this.getGroups();
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      count: 0,
      page: 1,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      groups: [],
      group: null,
      showDeactivated: '',
      update: false,
      maxDate: '',
      filter: {
        name: '',
        status: '',
        startDate: '',
        endDate: '',
      },
      options: [
        { text: 'All', value: '' },
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.maxDate = helper.preventFutureDateSelection();
  },

  methods: {
    addGroup() {
      this.group = null;
      $('#mod-group-add').modal('show');
    },
    viewFilter() {
      this.pageNumber = 0;
      $('#mod-filter').modal('show');
    },
    resetFilter() {
      this.filter = {
        name: '',
        status: '',
        startDate: '',
        endDate: '',
      };
      this.getGroups();
    },

    editGroup(group) {
      this.group = group;
      this.update = true;
      $('#mod-group-add').modal('show');
    },

    hideModal() {
      $('#mod-group-add').modal('hide');
      this.update = false;
    },

    onActionComplete() {
      this.hideModal();
      this.getGroups();
    },

    onComplete() {
      this.hideModal();
      this.getGroups();
    },

    filterActive() {
      this.showDeactivated = !this.showDeactivated;
      this.getGroups();
    },

    async getGroups(isSearch) {
      try {
        this.isBusy = true;
        this.page = isSearch ? 1 : this.page;
        const response = await this.$http.get(
          '/Groups/getList?PageNumber=' +
            this.page +
            '&pageSize=' +
            this.pageSize +
            (this.user.parentId ? '&parentId=' + this.user.parentId : '') +
            (this.filter.name !== ' ' ? '&Name=' + this.filter.name : '') +
            (this.filter.startDate !== ' '
              ? '&StartDate=' + this.filter.startDate
              : '') +
            (this.filter.endDate !== ' '
              ? '&EndDate=' + this.filter.endDate
              : '') +
            (this.filter.status !== '' ? '&Active=' + this.filter.status : '')
        );
        if (response.ok && response.status === 200) {
          this.groups = response.body.data;
          this.page = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
          this.pageCount = Math.ceil(
            response.body.count / response.body.pageSize
          );
          this.isBusy = false;
          $('#mod-filter').modal('hide');
          // this.filter = {
          // 	name:'',
          // 	status:"",
          // 	startDate:'',
          // 	endDate:''
          // }
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format('lll');
      else return '';
    },

    prev() {
      this.page -= 1;
      this.getGroups();
    },
    next() {
      this.page += 1;
      this.getGroups();
    },

    gotoPage(page) {
      this.page = page;
      this.getGroups();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getGroups();
    },
  },
};
</script>

<style>
.dropdown-menu {
  width: 14.6rem;
}
</style>
