<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <i class="feather icon-filter text-white"></i> Filters
          </button>
          <button class="btn btn-info btn-rounded float-right" @click="viewAdd">
            <span>
              <i class="feather icon-plus text-white"></i>
              New Posting
            </span>
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer text-nowrap">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        <button
                          :disabled="selectedPayments.length <= 0"
                          class="btn btn-success btn-sm"
                          type="button"
                          @click="authorizeList"
                        >
                          <span
                            v-if="isAuthorizeLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <i class="sr-only">Loading...</i>
                          </span>
                          <span v-else>
                            <i class="icon icon-left mdi mdi-arrow-top-right" />

                            {{ selectedPayments.length > 0 ? selectedPayments.length : "" }}
                          </span>
                        </button>
                      </th>
                      <th scope="col">PaymentID</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">AccountID</th>
                      <th scope="col">Amount (₦)</th>
                      <th scope="col">Payment Type</th>
                      <th scope="col">Payment Channel</th>
                      <th scope="col">Reference</th>
                      <th scope="col">Entered By</th>
                      <th scope="col">Authorized By</th>
                      <th scope="col">Entry Date</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="a in accountPosting.data" :key="a.id">
                      <td>
                        <account-posting-checkbox
                          :payment="a"
                          @onPaymentAdd="onPaymentAddHandler"
                          @onPaymentRemove="onPaymentRemoveHandler"
                        />
                      </td>
                      <td>{{ a.paymentId }}</td>
                      <td>{{ a.owner ? a.owner.displayName : "---" }}</td>
                      <td>
                        {{ a.owner && a.owner.code ? a.owner.code : "--" }}
                      </td>
                      <td>{{ a.paidAmount }}</td>
                      <td>{{ a.paymentType }}</td>
                      <td>
                        {{ a.paymentChannel ? prepareDetail(a.paymentChannel) : a.paymentType }}
                      </td>
                      <td>{{ a.referenceId }}</td>
                      <td>{{ a.maker? a.maker.displayName : "--"}}</td>
                      <td>
                        {{ a.authorizedBy ? a.authorizedBy.displayName : "---" }}
                      </td>
                      <td>{{ formatDate(a.created) }}</td>
                      <td>{{ formatDate(a.paidOn) }}</td>
                      <td>
                        {{ a.paymentStatus }}
                      </td>
                      <td>
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <a class="dropdown-item" href="#" @click="viewPosting(a)">
                            View
                          </a>
                          <a
                            v-if="a.paymentStatus == 'PENDING AUTHORIZATION'"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="authorize(a.id)"
                          >
                            Authorize
                          </a>
                          <a
                            v-if="a.paymentStatus != 'REVERSED'"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="initiatePrintReceipt(a.id)"
                          >
                            Print Receipt
                          </a>
                          <a
                            v-if="
                              a.paymentStatus !== 'REVERSED' &&
                                a.paymentStatus !== 'PENDING AUTHORIZATION'
                            "
                            class="dropdown-item"
                            href="#"
                            @click.prevent="attemptReverse(a.id)"
                          >
                            Reverse
                          </a>
                          <router-link
                            v-if="hasPermission('View_Customer_Account')"
                            class="dropdown-item"
                            :to="{
                              path: `customers/account/${a.owner.id}`,
                            }"
                          >
                            View Customer Account</router-link
                          >
                        </div>
                      </td>
                    </tr>
                    <tr v-if="accountPosting.data.length < 0">
                      <td colspan="12">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You have no account.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="accountPosting.pageSize"
                  :count="accountPosting.pageCount"
                  :page="accountPosting.pageNumber"
                  :itemsCount="accountPosting.count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ accountPosting.count }} account posting{{
                    accountPosting.count != 1 ? "s" : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
      <filter-posting @onFilterClear="onFilterClearHandler" />
      <view-payment :data="posting" />
      <add-account-posting
        @onPostAccountComplete="onPostAccountCompleteHandler"
        @onPostAccountCancel="onPostAccountCancelHandler"
      />
      <reverse-note @onReverseProceed="onReverseProceedHandler" />
      <AccountPostingReceipt :receiptData="singlePosting" :isPostPaymentPage="false" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import ViewPayment from "../../components/Payment/ViewPayment.vue";
import FilterPosting from "../../components/Posting/FilterPosting.vue";
import AddAccountPosting from "../../components/Posting/AddAccountPosting.vue";
import Pagination from "../../components/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import AccountPostingReceipt from "../../components/Posting/AccountPostingReceipt.vue";
import AccountPostingCheckbox from "../../components/Posting/AccountPostingCheckbox.vue";
import ReverseNote from "../../components/Posting/ReverseNote.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    FilterPosting,
    AddAccountPosting,
    ViewPayment,
    AccountPostingCheckbox,
    ReverseNote,
    Pagination,
    AccountPostingReceipt,
  },

  data() {
    return {
      isBusy: false,
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      posting: {
        owner: {
          displayName: "",
        },

        maker: {
          displayName: "",
        },

        paymentChannel: {
          paymentChannelTypeName: "",
        },

        authorizedBy: {
          displayName: "",
        },
      },
      selectedPayments: [],
      isAuthorizeLoading: false,
      reverseId: "",
      singlePosting: {},
    };
  },

  created() {
    this.getAccountPostings();
  },

  computed: {
    ...mapGetters(["accountPosting", "accountPostingFilter"]),

    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    ...mapActions(["getAccountPostings"]),

    printReceipt() {
      const prtHtml = document.getElementById("paymentReceipt").innerHTML;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open("", "_blank", "");

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                    <style>
                        @font-face{
                        font-family:feather;
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991);
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991#iefix) format('embedded-opentype'),
                        url(../assets/fonts/feather/fonts/feather.woff?t=1525787366991) format('woff'),
                        url(../assets/fonts/feather/fonts/feather.ttf?t=1525787366991) format('truetype'),
                        url(../assets/fonts/feather/fonts/feather.svg?t=1525787366991#feather) format('svg')
                        }
                        html *
                        {
                           font-family: Sans-Serif, Arial !important;
                        }
                    </style>
                  </head>
                  <body>
                    ${prtHtml}
                  </body>
                </html>`);

      WinPrint.print();
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    viewAdd() {
      $("#mod-add-posting").modal("show");
    },

    viewFilter() {
      this.accountPosting.pageNumber = 0;
      this.updateFilter(this.accountPosting);
      $("#mod-account-posting-filter").modal("show");
    },

    onFilterClearHandler() {
      $("#mod-account-posting-filter").modal("hide");
      this.getAccountPostings();
    },

    prepareDetail(value) {
      const name = value.paymentChannelTypeName;
      let result = "";
      if (name) {
        result += name;
        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },

    viewPosting(data) {
      this.posting = data;
      $("#mod-payment-view").modal("show");
    },

    async initiatePrintReceipt(id) {
      const singlePosting = this.accountPosting.data.find((el) => el.id === id);
      this.singlePosting = singlePosting;
      setTimeout(this.printReceipt, 1000);
    },

    async authorize(id) {
      this.isBusy = true;
      try {
        const response = await this.$http.put(`/AccountPosting/authorize/${id}`);
        if (response.ok) {
          this.$toast.info(response.body, {
            icon: false,
            rtl: false,
          });

          this.accountPosting.data = [];
          this.getAccountPostings();
        }
      } catch (error) {}
      this.isBusy = false;
    },

    attemptReverse(value) {
      this.reverseId = value;
      $("#mod-reverse-note").modal("show");
    },

    async onReverseProceedHandler(notes) {
      $("#mod-reverse-note").modal("hide");
      await this.reverse(notes);
    },

    async reverse(notes) {
      this.isBusy = true;
      try {
        const response = await this.$http.put(`/AccountPosting/reverse/${this.reverseId}`, {
          notes: notes,
        });
        if (response.ok) {
          this.$toast.info(response.body, {
            icon: false,
            rtl: false,
          });
          this.reverseId = "";
          this.accountPosting.data = [];
          this.getAccountPostings();
        }
      } catch (error) {}
    },

    onPostAccountCompleteHandler() {
      this.accountPosting.data = [];
      this.getAccountPostings();
      $("#mod-add-posting").modal("hide");
    },

    onPostAccountCancelHandler() {},

    onPaymentRemoveHandler(value) {
      this.selectedPayments = this.selectedPayments.filter((x) => x != value);
    },

    onPaymentAddHandler(value) {
      this.selectedPayments.push(value);
    },

    async authorizeList() {
      if (this.selectedPayments.length > 0) {
        this.isAuthorizeLoading = true;
        this.isBusy = true;

        try {
          const response = await this.$http.put("/AccountPosting/authorize", this.selectedPayments);

          if (response.ok) {
            this.$toast.info(response.body, {
              icon: false,
              rtl: false,
            });

            this.getAccountPostings();
            this.selectedPayments = [];
          }
        } catch (error) {}
        this.isAuthorizeLoading = false;
        this.isBusy = false;
      }
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    goToPayment(id) {
      this.$router.push("/payments/" + id);
    },

    updateFilter(filter) {
				this.$store.commit("updateAccountPosting", filter);
		},

    prev() {
      this.accountPosting.pageNumber -= 1;
      this.getAccountPostings();
    },

    next() {
      this.accountPosting.pageNumber += 1;
      this.getAccountPostings();
    },

    gotoPage(page) {
      this.accountPosting.pageNumber = page;
      this.getAccountPostings();
    },

    changeLimit(limit) {
      this.accountPosting.pageSize = limit;
      this.getAccountPostings();
    },
  },
};
</script>
