<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <router-link
            class="btn btn-space btn-success btn-rounded float-right"
            to="/manufacturer-products"
          >
            <span class="icon icon-left mdi mdi-arrow-left"></span>
            Back
          </router-link>
          <!--<button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="viewAdd">
                        <span class="icon icon-left mdi mdi-plus text-white"></span> Add
                        Product
                    </button>-->
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-7 col-sm-7 col-lg-7">
              <input ref="fileUpload" type="file"  @change="onFileExcelChange" />
            </div>

            <div class="col-5 col-sm-5 col-lg-5">
              <a
                download="Price-Options-Upload-Template-Enterprise.xlsx"
                href="/assets/templates/Price-Options-Upload-Template-Enterprise.xlsx"
                class="float-right"
              >
                Download Template
              </a>
            </div>
          </div>

          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 15%">Action</th>
                      <th style="width: 15%">Product Code</th>
                      <th style="width: 15%">Price Option Id</th>
                      <th style="width: 15%">Price Option Name</th>
                      <th style="width: 10%">Price Option Description</th>
                      <th style="width: 10%">Distributor Price</th>
                      <th style="width: 10%">Retail Price</th>
                      <th style="width: 10%">Group Code </th>
                      <th style="width: 10%">Active (Y/N)</th>
                      <th style="width: 10%"></th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(l, index) in priceUpload" :key="l.id">
                      <td>
                        <span v-if="l.action_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.action }} </span>
                      </td>
                      <td>
                        <span v-if="l.productCode_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.productCode }} </span>
                      </td>
                      <td>
                        <span v-if="l.optionId_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.optionId }} </span>
                      </td>
                      <td>
                        <span v-if="l.name_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.name }} </span>
                      </td>
                      <td>
                        <span v-if="l.description_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.description }} </span>
                      </td>
                      <td>
                        <span v-if="l.distributorPrice_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.distributorPrice }} </span>
                      </td>
                      <td>
                        <span v-if="l.retailPrice_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.retailPrice }} </span>
                      </td>
                      <td>
                         <span v-if="l.group_code_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.group_code }} </span>
                      </td>
                      <td>
                        <span v-if="l.active_Error" class="text-danger"> <i class="feather icon-alert-triangle"></i> Invalid</span>
                        <span v-else>{{ l.activeCond }} </span>
                      </td>
                      <td class="text-danger">
                        <i
                          v-if="l.errors"
                          class="feather icon-alert-triangle"
                        ></i
                        >&nbsp;{{ l.errors }}
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          >
                            <i class="feather icon-edit"></i>
                            Edit</button
                          >&nbsp;
                          <button
                            class="btn btn-danger btn-sm btn-rounded btn-space"
                            @click.prevent="onRemoveClick(index)"
                          >
                            <i class="feather icon-delete"></i> Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!priceUpload.length">
                      <td colspan="8">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any price options.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ priceUpload.length }} price{{
                    priceUpload.length != 1 ? "s" : ""
                  }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_info text-danger">
                  {{
                    errorCount > 0
                      ? "Please correct all errors before proceeding"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-12 col-sm-12 col-lg-12">
              <br />
              <br />&nbsp;
              <button
                style="width: 130px"
                :disabled="
                  isBusyModal || priceUpload.length <= 0 || errorCount > 0
                "
                class="btn btn-primary"
                type="button"
                @click.prevent="onUploadClick"
              >
                <span v-if="isBusyModal">Busy...</span>
                <span v-else>Post</span>
              </button>

              <button
                v-if="priceUpload.length > 0"
                style="width: 130px"
                class="btn btn-danger pull-right"
                type="button"
                @click.prevent="reset"
              >
                <span>Clear All</span>
              </button>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>


    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Product</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v2.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v2.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Action</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updatePriceOption.action"
                >
                  <option
                    value="New"
                  >
                    New
                  </option>
                  <option
                    value="Update"
                  >
                    Update
                  </option>
                </select>
                <ul
                  v-if="v2.action"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Product Code</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.productCode }]"
                  type="text"
                  v-model="updatePriceOption.productCode"
                />
                <ul
                  v-if="v2.productCode"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Price Option Id</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.optionId }]"
                  type="text"
                  :disabled="isNewPriceOption"
                  v-model="updatePriceOption.optionId"
                />
                <ul
                  v-if="v2.optionId"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

             <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Price Option Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updatePriceOption.name"
                />
                <ul
                  v-if="v2.name"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Price Option Description</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  class="form-control"
                  v-model="updatePriceOption.description"
                ></textarea>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Distributor Price</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.distributorPrice }]"
                  type="number"
                  v-model="updatePriceOption.distributorPrice"
                />
                <ul v-if="v2.distributorPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Retail Price</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.retailPrice }]"
                  type="number"
                  v-model="updatePriceOption.retailPrice"
                />
                <ul v-if="v2.retailPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Group Code</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.group_code }]"
                  type="text"
                  v-model="updatePriceOption.group_code"
                />
                <ul
                  v-if="v2.group_code"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active (Y/N)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.active }]"
                  type="text"
                  placeholder="Y or N"
                  v-model="updatePriceOption.activeCond"
                />
                <ul
                  v-if="v2.active"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div> 

    <UploadCaution
      :text="modalText"
      :action="action"
      @proceedToRemove="remove"
      @proceedToUpload="uploadPrices"
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import UploadCaution from "../components/UploadCaution";
import XLSX from "xlsx";

export default {
  mixins: [SystemPermissions.Mixin],

  name: "productsUpload",
  components: {
    Select2,
    UploadCaution,
    datePicker,
  },

//   mounted(){
//       window.addEventListener('load', this.onLoad());
//   },

  created() {
    if (!this.hasPermission("Product_Create")) {
      this.$router.push("/restricted");
    }
    this.getGroup();
    this.getTypes();
    this.getCategories();
    this.getUnits();
    this.originalpriceOptions = this.priceOptions;
  },

  updated() {
    if (this.$route.params.id && this.priceOptions) {
      const product = this.priceOptions.find(
        (prod) => prod.id === this.$route.params.id
      );
      this.viewEdit(product);
    }
  },

  watch: {},

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      isBusy: false,
      isBusyModal: false,
      createPriceOption: {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null
      },
      updatePriceOption: {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null,
        activeCond: null,

      },
      v1: {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,

      },
      v2: {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,
      },
      priceOptions: [],
      editPriceOption: {},
      originalpriceOptions: [],
      manufacturerpriceOptions: [],
      types: [],
      categories: [],
      units: [],
      manufacturerPrice: null,
      manufacturerCategory: null,
      manufacturerUnit: null,
      allowPriceOverride: true,
      priceUpload: [],
      errorCount: 0,
      modalText: "",
      action: "",
      removeIndex: 0,
      isProductExist: false,
      isNewPriceOption: false
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(`/Groups/getGroup/`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerpriceOptions();
        }

        this.isBusy = true;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getPrice(code, isEdit) {
      var id = this.getManufacturerProductIdByCode(code);
      this.isBusy = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200) {
            this.prepareProduct(id, response.body, isEdit);
          }
        } else {
          this.prepareProduct(
            id,
            { distributorPrice: 0, retailPrice: 0 },
            isEdit
          );
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    prepareProduct(id, price, isEdit) {
      const product = this.manufacturerpriceOptions.find((pr) => pr.id === id);

      this.manufacturerCategory = product.category.name;
      this.manufacturerUnit = product.unit;
      this.allowPriceOverride = !product.allowPriceOverride;
      this.manufacturerPrice =
        price.distributorPrice > 0
          ? price.distributorPrice
          : product.distributorPrice;
      if (isEdit) {
        this.updatePriceOption.retailPrice =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      } else {
        this.createPriceOption.retailPrice =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      }
    },

    onFileExcelChange(e){
        this.errorCount = 0;
        const file = e.target.files[0];

        var reader = new FileReader(), self = this;
        reader.onload = (event) => {
            let arrayData = [];
            //parse data
            var bstr = event.target.result;
            const workBook = XLSX.read(bstr,{type: "binary"});
            //get first sheet
            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            //convert array
            const fileData = XLSX.utils.sheet_to_json(workSheet, {header:1});
            const headers = fileData[0];
            fileData.splice(0,1);

            for (var i = 0; i < fileData.length; i++) {
              if(fileData[i].length > 0){
                let objectItem = {
                    action: fileData[i][0],
                    action_error : false,
                    productCode: fileData[i][1],
                    productCode_error : false,
                    optionId: fileData[i][2] ? fileData[i][2] : "",
                    optionId_error : false,
                    name: fileData[i][3],
                    name_error : false,
                    description: fileData[i][4],
                    description_error : false,
                    distributorPrice: fileData[i][5],
                    distributorPrice_error : false,
                    retailPrice: fileData[i][6],
                    retailPrice_error : false,
                    group_code: fileData[i][7],
                    group_code_error : false,
                    active: fileData[i][8],
                    active_error : false,
                }

                arrayData.push(objectItem);
              }
                
            }

            self.priceUpload = arrayData;

            for (var i = 0; i < self.priceUpload.length; i++) {
                var errors = "";
                var priceOpt = self.priceUpload[i];

                if (!priceOpt.action  && (priceOpt.action.toLowerCase() !== "new" || priceOpt.action.toLowerCase() !== "update")) {
                errors = errors + "|Invalid price action";
                self.v2.action = true;
                priceOpt.action_error = true;
                }

                if(self.validateProductCode(priceOpt.productCode) === true){
                  errors = errors + "|Product code already exist";
                  self.v2.productCode = true;
                  priceOpt.productCode_error = true;
                }
            
                if (!priceOpt.productCode) {
                errors = errors + "|Invalid product code";
                self.v2.productCode = true;
                priceOpt.productCode_error = true;
                }
                if (!priceOpt.optionId && priceOpt.action.toLowerCase() === "update") {
                errors = errors + "|Invalid price option id";
                self.v2.optionId = true;
                priceOpt.optionId_error = true;
                }

                if (!priceOpt.name  || priceOpt.name.length < 2) {
                errors = errors + "|Invalid price option name";
                self.v2.name = true;
                priceOpt.name_error = true;
                }

                if (!priceOpt.description) {
                errors = errors + "|Invalid price option description";
                self.v2.description = true;
                priceOpt.description_error = true;
                }
               
                if (!priceOpt.distributorPrice) {
                errors = errors + "|Invalid distributor price";
                self.v2.distributorPrice = true;
                priceOpt.distributorPrice_error = true;
                }

                if (!priceOpt.retailPrice) {
                errors = errors + "|Invalid retail price";
                self.v2.retailPrice = true;
                priceOpt.retailPrice_error = true;
                }

                if (!priceOpt.group_code) {
                errors = errors + "|Invalid group code";
                priceOpt.group_code_error = true;
                }
                
                if (!priceOpt.active && (priceOpt.active !== "Y" || priceOpt.active !== "N")) {
                errors = errors + "|Invalid price option active status, input Y for yes and N for No";
                priceOpt.active_error = true;
                }else if(priceOpt.active === "Y"){
                self.priceUpload[i].active = true;
                self.priceUpload[i].activeCond = "Y";
                }else{
                self.priceUpload[i].active = false;
                self.priceUpload[i].activeCond = "N";
                }
              

                if (isNaN(priceOpt.distributorPrice) && Number(priceOpt.distributorPrice) <= 0) {
                errors = errors + "| distributor price must be a number greater than 0";
                self.v2.distributorPrice = true;
                priceOpt.distributorPrice_error = true;
                }

                if (isNaN(priceOpt.retailPrice) && Number(priceOpt.retailPrice)) {
                errors = errors + "| retail price must be a number greater than 0";
                self.v2.retailPrice = true;
                priceOpt.retailPrice_error = true;
                }

                if (!errors) {
                priceOpt.name = priceOpt.name.trim();
                // priceOpt.productCode = priceOpt.productCode.trim();
                priceOpt.description = priceOpt.description.trim();
                } else self.errorCount++;

                self.priceUpload[i].errors = errors;
            }
        }

        reader.readAsBinaryString(file);
    },

    reset() {
      this.errorCount = 0;
      this.priceUpload.splice(0);
      this.$refs.fileUpload.value = null;
    },
    cancel() {
      const currentPriceOption = this.priceOptions.find(
        (p) => p.id === this.editPriceOption.id
      );
      this.allowPriceOverride = true;
      currentPriceOption.manufacturer_productId =
        this.editPriceOption.manufacturer_productId;
      currentPriceOption.distributor_productId =
        this.editPriceOption.distributor_productId;
      currentPriceOption.product_Name = this.editPriceOption.product_Name;
      currentPriceOption.product_Description = this.editPriceOption.product_Description;
      currentPriceOption.retailPrice = this.editPriceOption.retailPrice;
      currentPriceOption.minimum_stock_level = this.editPriceOption.minimum_stock_level;
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.manufacturerPrice = null;
      this.manufacturerCategory = null;
      this.manufacturerUnit = null;
      this.allowPriceOverride = null;
      this.createPriceOption = {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null
      };
      this.v1 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      // var manufacturerProductId = this.getManufacturerProductIdByCode(
      //   c.manufacturer_productId
      // );
      var editValues = {
        action: c.action,
        productCode: c.productCode,
        optionId: c.optionId,
        name: c.name,
        description: c.description,
        distributorPrice: c.distributorPrice ? c.distributorPrice : 0,
        retailPrice: c.retailPrice ? c.retailPrice : 0,
        group_code: c.group_code,
        active: c.active,
      };
      this.editPriceOption = editValues;
      this.updatePriceOption = c;
      if(c.action.toLowerCase() === "new"){
        this.isNewPriceOption = true;
      }else{
        this.isNewPriceOption = false;
      }

      $("#mod-edit").modal("show");
    },
    viewUploadExpenses() {
      this.$router.push("/manage-expenses/upload");
    },

    onRemoveClick(index) {
      this.modalText = "Are you sure you want to remove this item?";
      this.action = "remove";
      this.removeIndex = index;
      $("#mod-expense-caution").modal("show");
    },

    remove() {
      var expense = this.priceUpload[this.removeIndex];
      if (expense.errors) {
        this.errorCount--;
      }
      this.priceUpload.splice(this.removeIndex, 1);
      this.removeIndex = 0;
      $("#mod-expense-caution").modal("hide");
    },

    onUploadClick() {
      this.modalText = "Are you sure?";
      this.action = "upload";
      $("#mod-expense-caution").modal("show");
    },

    async uploadPrices() {
      $("#mod-expense-caution").modal("hide");
      this.isBusyModal = true;
      this.isBusy = true;

      let priceUploadRequest = this.priceUpload;

      if(priceUploadRequest.length > 0){
        priceUploadRequest.forEach((item)=>{
          delete item.activeCond;
          delete item.group_code;
          delete item.errors;
          delete item.action;
          delete item.action_error;
          delete item.active_error;
          delete item.description_error;
          delete item.distributorPrice_error;
          delete item.group_code_error;
          delete item.name_error;
          delete item.optionId_error;
          delete item.productCode_error;
          delete item.retailPrice_error;
        })
      }

      var data = {
        priceOptions: priceUploadRequest,
      };

      await this.$http
        .post("/PriceOptions/CreatePriceOptionBulk", data)
        .then(function (res) {
          this.$router.push("/manufacturer-products");
        })
        .catch(function () {
            this.isBusyModal = false;
            this.isBusy = false;
        });

  
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        this.priceUpload.push(this.createPriceOption);
        $("#mod-add").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
      };
      var isValid = true;

      if (!this.createPriceOption.action) {
        this.v1.action = true;
        isValid = false;
      }

      if (!this.createPriceOption.productCode && !this.createPriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }

      if (!this.createPriceOption.optionId && !this.createPriceOption.action.toLowerCase() == "update") {
        this.v1.product_Name = true;
        isValid = false;
      }

      if (!this.createPriceOption.name && !this.createPriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }

      if (!this.createPriceOption.description && !this.createPriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }

      if (Number(this.createPriceOption.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      }

      if (Number(this.createPriceOption.distributorPrice) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }

       if (!this.createPriceOption.active) {
        this.v1.action = true;
        isValid = false;
      }


      if (!this.createPriceOption.activeCond && (this.createPriceOption.activeCond !== "Y" || this.createPriceOption.activeCond !== "N")) {
        this.v2.active = true;
        isValid = false;
      }else if(this.createPriceOption.activeCond === "Y"){
        this.createPriceOption.active = true;
        this.createPriceOption.activeCond = "Y";
      }else{
        this.createPriceOption.active = false;
        this.createPriceOption.activeCond = "N";
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;
      if (this.validateUpdate()) {
        // var productName = this.getManufacturerProductNameByCode(
        //   this.updatePriceOption.manufacturer_productId
        // );
        // this.updatePriceOption.manufacturer_product_Name = productName;
        if (this.updatePriceOption.errors) {
          this.updatePriceOption.errors = "";
          this.errorCount--;
        }
        $("#mod-edit").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,

        is_valid: true,
      };
      var isValid = true;

      if (!this.updatePriceOption.action) {
        this.v1.action = true;
        isValid = false;
      }else{
        this.updatePriceOption.action_error = false;
      }

      if (!this.updatePriceOption.productCode && !this.updatePriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }else{
        this.updatePriceOption.productCode_error = false;
      }

      if (!this.updatePriceOption.optionId && !this.updatePriceOption.action.toLowerCase() == "update") {
        this.v1.product_Name = true;
        isValid = false;
      }else{
        this.updatePriceOption.optionId_error = false;
      }

      if (!this.updatePriceOption.name && !this.updatePriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }else {
        this.updatePriceOption.name_error = false;
      }

      if (!this.updatePriceOption.description && !this.updatePriceOption.action.toLowerCase() == "new") {
        this.v1.productCode = true;
        isValid = false;
      }else {
        this.updatePriceOption.description_error = false;
      }

      if (Number(this.updatePriceOption.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      }else {
        this.updatePriceOption.retailPrice_error = false;
      }

      if (Number(this.updatePriceOption.distributorPrice) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }else {
        this.updatePriceOption.distributorPrice_error;
      }

       if (!this.updatePriceOption.active) {
        this.v1.action = true;
        isValid = false;
      }else{
        this.updatePriceOption.active_error = false;
      }


      if (!this.updatePriceOption.activeCond && (this.updatePriceOption.activeCond !== "Y" || this.updatePriceOption.activeCond !== "N")) {
        this.v2.active = true;
        isValid = false;
      }else if(this.updatePriceOption.activeCond === "Y"){
        this.updatePriceOption.active = true;
        this.updatePriceOption.activeCond = "Y";
        this.updatePriceOption.active_error = false;
      }else{
        this.updatePriceOption.active = false;
        this.updatePriceOption.activeCond = "N";
        this.updatePriceOption.active_error = false;
      }

      return isValid;
    },

    async getManufacturerpriceOptions() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        )
        .then(function (res) {
          this.manufacturerpriceOptions = res.body;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    getTypes() {
      this.types = ["Finite", "Infinite"];
    },

    getCategories() {
      var t = "Product Category";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function (res) {
          this.categories = res.body;
        })
        .catch(function () {});
    },
    getUnits() {
      var t = "Product Unit";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Unit")
        )
        .then(function (res) {
          this.units = res.body;
        })
        .catch(function () {});
    },

    validateProductCode(code){
      this.$http
        .get(
          '/Products/isProductCodeExist/' + code
        )
        .then(function (res) {
          this.isProductExist = res.body;
          this.isBusy = false;
          return res.body;
        })
        .catch(function (res) {
          return res.body;
        });

    },

    getManufacturerProductNameByCode(code) {
      var product = this.manufacturerpriceOptions.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + code + ")";
    },

    getManufacturerProductNameById(id) {
      var product = this.manufacturerpriceOptions.find((x) => x.id === id);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + product.code + ")";
    },

    getManufacturerProductIdByCode(code) {
      var product = this.manufacturerpriceOptions.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.id;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
  },
};
</script>