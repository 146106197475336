<template>
	<button
		v-if="user.role == 'Administrator' && !isTextBtn"
		class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
		data-toggle="tooltip"
		title="Delete"
		:disabled="deleteLoading"
		@click.prevent="onDelete"
	>
		<span v-if="deleteLoading" class="spinner-border spinner-border-sm" role="status">
			<i class="sr-only">Loading...</i>
		</span>
		<span v-else>
			<i class="icon mdi mdi-delete text-white"></i>
		</span>
	</button>
	<a
		v-else
		class="dropdown-item btn btn-text btn-sm"
		:disabled="deleteLoading"
		@click.prevent="onDelete"
	>
		<span class="text-danger">Delete</span>
	</a>
</template>

<script>
	export default {
		name: "DeletePriceOption",
		props: ["id", "isTextBtn"],

		data() {
			return {
				deleteLoading: false,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		methods: {
			onDelete() {
				this.$emit("showCaution", this.id);
			},

			async deleteDistributorPrice() {
				this.deleteLoading = true;
				this.isBusy = true;
				try {
					const response = await this.$http.delete("/PriceOptions/delete/" + this.id);

					if (response.ok && response.status === 200) this.$emit("onComplete");
					this.deleteLoading = false;
					this.isBusy = true;
					this.$toast.success("Deleted successful", {
						icon: true,
						rtl: false,
					});
				} catch (error) {
					this.deleteLoading = false;
					this.isBusy = true;
				}
			},
		},
	};
</script>
